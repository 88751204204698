import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getListRedeemPointService = (model) => {
    var url = `${ApiRouters.REDEEM_POINT}`;
    return api.get(url, { params: model });
};

export const getRedeemPointDetailService = (id) => {
    var url = `${ApiRouters.REDEEM_POINT}/${id}`;
    return api.get(url);
};

export const postGenDiscount = (model) => {
    var url = `${ApiRouters.GENERATE_DISCOUNT_POINT}`;
    return api.post(url, model);
};

export const postRedeemDiscountService = (model, id) => {
    var url = `${ApiRouters.REDEEM_COUPON}/${id}`;
    return api.post(url, model);
};

export const getListRedeemCouponService = (model) => {
    var url = `${ApiRouters.MEMBER_COUPON}`;
    return api.get(url, { params: model });
};

export const getRedeemCouponDetailService = (id) => {
    var url = `${ApiRouters.MEMBER_COUPON}/${id}`;
    return api.get(url);
};
