import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import {
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress,
    Select,
    NativeSelect,
    FormHelperText,
    Button,
    Breadcrumbs,
    Typography,
    Link,
    Divider,
} from "@material-ui/core";
import commonAction from "../../redux/Common/actions";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as campaignService from "../../services/campaign";
import * as commonService from "../../services/common";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import Quantity from "../../components/Quantity";
import { couponType, validityPeriod } from "../../constants/app";
import DaterangePicker from "../../components/DaterangePicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import MomentUtils from "@date-io/moment";

function Add(props) {
    const [loading, setLoading] = useState(false);
    const [pickerStatus, setPickerStatus] = useState(false);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const [data, setData] = useState();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(null);
    const [dataChannel, setDataChannel] = useState([]);
    const [dataTier, setDataTier] = useState([]);
    const [dataTag, setDataTag] = useState([]);
    const [dataGroup, setDataGroup] = useState([]);
    const [dataCoupon, setDataCoupon] = useState([]);
    const [dataVoucher, setDataVoucher] = useState([]);
    const [dataGift, setDataGift] = useState([]);
    const { id, stt } = useParams();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            // applicable_stores: "",
            campaign_type: "1",
            campaign_name: "",
            public_day: "",
            validity_period_type:0,
            coupon_id: "",
            member_ref: "",
            coupon_bonus: "",
            campaign_member_type: 1,
            reward_ref: "",
            reward_type: 0,
        },
    });

    const handleDateChange = (e) => {
        setSelectedDate(e);
        setValue("public_day", e.format("YYYY-MM-DD"));
    };

    useEffect(() => {
        getDataChannel();
        getDataCoupon();
        getDataVoucher();
        getDataGift();
        getDataTiers();
        getDataMemberTag();
        getDataMemberGroup();
        getData();
    }, []);

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                    setValue("channel_id", res.data.data[0].Id);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataTiers = () => {
        commonService
            .getListTier()
            .then((res) => {
                if (res.data.data) {
                    setDataTier([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };
    const getDataMemberTag = () => {
        commonService
            .getListMembertag()
            .then((res) => {
                if (res.data.data) {
                    setDataTag([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataMemberGroup = () => {
        commonService
            .getListMemberGroup()
            .then((res) => {
                if (res.data.data) {
                    setDataGroup([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataCoupon = () => {
        campaignService
            .getCouponCampaign()
            .then((res) => {
                if (res.data.data) {
                    console.log(res);
                    setDataCoupon([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataVoucher = () => {
        campaignService
            .getVoucherCampaign()
            .then((res) => {
                if (res.data.data) {
                    setDataVoucher([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataGift = () => {
        campaignService
            .getGiftCampaign()
            .then((res) => {
                if (res.data.data) {
                    setDataGift([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        campaignService
            .getDetailCampaign(id)
            .then((res) => {
                if (res.data.data) {
                    const resp = res.data.data;
                    setData(res.data.data);
                    setValue("campaign_name", resp?.CampaignName || "");
                    setValue("member_ref", resp?.MemberRef || "");
                    setSelectedDate(moment(resp?.StartDate));
                    setValue("public_day", moment(resp?.StartDate).format("YYYY-MM-DD"));
                    setValue("campaign_member_type", resp?.MemberType || 1);
                    setValue("reward_type", resp?.RewardType || 0);
                    setValue("reward_ref", resp?.RewardRef || "");
                    setValue("remark", resp?.Remark);
                    setValue("redemption_Conditions", resp?.RedemptionConditions || undefined);
                    setValue("validity_period_type", resp?.ValidityPeriodType || 0);
                    if (resp?.ValidityPeriodType == 0) {
                        setValue("start_from", resp?.StartFrom);
                        setValue("end_to", resp?.EndTo);
                    }

                    if (resp?.ValidityPeriodType == 1) {
                        setValue("specific_date_from", resp?.EffectiveDateBegin);
                        setValue("specific_date_to", resp?.EffectiveDateEnd);
                    }

                    setValue("term_and_conditions", resp?.TermAndConditions || "");
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };

    const onSubmit = (data) => {
        setLoading(true);
        console.log("DATA FORM", data);

        if (data.coupon_bonus) {
            data.coupon_bonus = data.coupon_bonus.toString();
        }
        if (stt == "edit") {
            campaignService
                .updateCampaign(id, data)
                .then((res) => {
                    toast.success("Edit campaign successful!");
                    setLoading(false);
                    history.push("/campaign/spot");
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else {
            campaignService
                .addCampaign(data)
                .then((res) => {
                    toast.success("Add campaign successful!");
                    setLoading(false);
                    history.push("/campaign/spot");
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    };

    const directBack = (e) => {
        e.preventDefault();
        history.push("/campaign/spot");
    };

    const handleChangeDaterange = (range) => {
        setValue("specific_date_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("specific_date_to", moment(range.endDate).format("YYYY-MM-DD"));
    };

    useEffect(() => {
        if (watch("validity_period_type") == 1) {
            setValue("start_from", 0);
            setValue("end_to", 0);
        } else {
            setValue("specific_date_from", undefined);
            setValue("specific_date_to", undefined);
        }
    }, [watch("validity_period_type")]);

    return (
        <div className="spot-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            Home
                        </Link>
                        <Link color="inherit" href="/#/campaign/spot">
                            Spot campaign
                        </Link>
                        <Typography color="textPrimary">
                            {stt == "edit" ? "Edit" : "Add"}
                        </Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">Spot campaign</h1>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <div className="form-group my-2 mt-6">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Campaign name:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("campaign_name", { required: true })}
                                        />
                                        {errors.coupon_name && (
                                            <span className="text-error">
                                                This field is required
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ marginTop: 8 }}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Start day:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="custom-date-picker">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    inputProps={{ readOnly: true }}
                                                    margin="normal"
                                                    inputVariant="outlined"
                                                    id="date-picker-dialog"
                                                    format="DD/MM/yyyy"
                                                    value={
                                                        selectedDate ? moment(selectedDate) : null
                                                    }
                                                    onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                                    onClick={() => setPickerStatus(true)}
                                                    onClose={() => setPickerStatus(false)}
                                                    open={pickerStatus}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="title-spot" style={{ marginTop: 16 }}>
                                <h3>WHEN</h3>
                                <p>Select your time period</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex">
                                        <label className="text-semibold label-input mt-2">
                                            Validity period:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="validity_period_type"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="jobTitle_id"
                                                        inputProps={{ "aria-label": "province" }}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {validityPeriod.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                        {watch("validity_period_type") == 0 ? (
                                            <div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            Start from:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="start_from"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after reception
                                                    </label>
                                                </div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            End to:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="end_to"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after effective
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-4">
                                                <DaterangePicker
                                                    onChange={handleChangeDaterange}
                                                    defaultValue={{
                                                        date: {
                                                            specific_date_from:
                                                                data?.EffectiveDateBegin,
                                                            specific_date_to:
                                                                data?.EffectiveDateEnd,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="title-spot">
                                <h3>WHO</h3>
                                <p>Define your member targets</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Campaign member type:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="campaign_member_type"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="campaign_member_type"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {/* All member, Tier, group, tag, segment */}
                                                        <option value="1">All member</option>
                                                        <option value="2">Tier</option>
                                                        <option value="3">Group</option>
                                                        <option value="4">Tag</option>
                                                        <option value="5">Segment</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                {watch("campaign_member_type") != 1 ? (
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} className="flex items-center">
                                            <label
                                                className="text-semibold label-input input-require"
                                                style={{ width: "100%" }}
                                            >
                                                {watch("campaign_member_type") == 2
                                                    ? "Tier"
                                                    : watch("campaign_member_type") == 3
                                                    ? "Group"
                                                    : watch("campaign_member_type") == 4
                                                    ? "Tag"
                                                    : watch("campaign_member_type") == 5
                                                    ? "Segment"
                                                    : ""}
                                            </label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="member_ref"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={value}
                                                            onChange={onChange}
                                                            name="member_ref"
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option value=""></option>

                                                            {watch("campaign_member_type") == 2 &&
                                                                _.map(dataTier, (i) => (
                                                                    <option value={i?.Id}>
                                                                        {i?.TierName}
                                                                    </option>
                                                                ))}
                                                            {watch("campaign_member_type") == 3 &&
                                                                _.map(dataGroup, (i) => (
                                                                    <option value={i?.Id}>
                                                                        {i?.MemberGroupName}
                                                                    </option>
                                                                ))}
                                                            {watch("campaign_member_type") == 4 &&
                                                                _.map(dataTag, (i) => (
                                                                    <option value={i?.Id}>
                                                                        {i?.TagName}
                                                                    </option>
                                                                ))}
                                                        </NativeSelect>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="title-spot">
                                <h3>WHAT</h3>
                                <p>Choose your reward type</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Reward type:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="reward_type"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="reward_type"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value="0">No_Rewards</option>
                                                        <option value="2">Coupon</option>
                                                        <option value="3">Voucher</option>
                                                        <option value="4">GiftCard</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                {watch("reward_type") == 0 && watch("reward_type") == 1 ? (
                                    ""
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} className="flex items-center">
                                            <label
                                                className="text-semibold label-input input-require"
                                                style={{ width: "100%" }}
                                            >
                                                {watch("reward_type") == 2
                                                    ? "Coupon"
                                                    : watch("reward_type") == 3
                                                        ? "Voucher"
                                                        : watch("reward_type") == 4
                                                            ? "GiftCard"
                                                            : ""}
                                            </label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="reward_ref"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={value}
                                                            onChange={onChange}
                                                            name="reward_ref"
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option value=""></option>
                                                            {watch("reward_type") == 2 &&
                                                            _.map(dataCoupon, (i) => (
                                                                <option value={i?.Id}>
                                                                    {i?.CouponName}
                                                                </option>
                                                            ))}
                                                            {watch("reward_type") == 3 &&
                                                            _.map(dataVoucher, (i) => (
                                                                <option value={i?.Id}>
                                                                    {i?.VoucherName}
                                                                </option>
                                                            ))}
                                                            {watch("reward_type") == 4 &&
                                                            _.map(dataGift, (i) => (
                                                                <option value={i?.Id}>
                                                                    {i?.GiftName}
                                                                </option>
                                                            ))}

                                                        </NativeSelect>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                )}


                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="title-spot">
                                <h3>HOW</h3>
                                <p>Select your channel</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input"
                                            style={{ width: "100%" }}
                                        >
                                            Channel:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="channel_id"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="channel_id"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {dataChannel.map((item, index) => (
                                                            <option value={item.Id} key={index}>
                                                                {item.ChannelName}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex mt-2">
                                        <label className="text-semibold label-input">
                                            Terms & conditions:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <textarea
                                            id="w3review"
                                            name="w3review"
                                            rows="4"
                                            cols="50"
                                            {...register("term_and_conditions")}
                                        ></textarea>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex mt-2">
                                        <label className="text-semibold label-input ">
                                            Remark:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <textarea
                                            id="w3review"
                                            name="w3review"
                                            rows="4"
                                            cols="50"
                                            {...register("remark")}
                                        ></textarea>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="pb-4">
                        <Grid item xs={3}>
                            <button
                                // type="submit"
                                className="btn-primary-filled w-full mt-2 text-center"
                                disabled={loading}
                                onClick={directBack}
                            >
                                Cancel
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <input
                                type="submit"
                                className="btn-primary-filled w-full mt-2"
                                disabled={loading}
                                value="Save"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    style={{ position: "relative", top: 16 }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default Add;
