import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs, Fade, Grid, Typography, CircularProgress, Avatar } from "@material-ui/core";
import authActions from "../../redux/Auth/actions";
import authReducer from "../../redux/Auth/reducer";

function UserProfile(props) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        dispatch(authActions.login(data));
        console.log(data);
    };
    const userProfileData = useSelector((state) => state.authReducer.auth.userProfile);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setValue("first_name", userProfileData.FirstName);
        setValue("last_name", userProfileData.LastName);
        setValue("phone_number", userProfileData.PhoneNumber);
        setValue("email", userProfileData.Email);
    }, [userProfileData]);

    const getData = () => {
        dispatch(authActions.getUserProfile(1));
    };

    return (
        <div id="userProfile-page" className="wrapper-userProfile-page">
            <div className="wrapper-paper w-full">
                <div className="p-2 pb-4" style={{ borderBottom: "1px solid #c4c4c433" }}>
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" to="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Profile</Typography>
                    </Breadcrumbs>
                    <h3 className="text-2xl font-semibold uppercase mt-2">User Profile</h3>
                </div>
                <div>
                    <Grid container spacing={3} className="p-2 py-6">
                        <Grid item xs={3}>
                            <div className="w-7/12 h-auto mx-auto">
                                <Avatar
                                    alt="Remy Sharp"
                                    src="https://www.w3schools.com/howto/img_avatar.png"
                                    className="mb-3"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9} className="content">
                            {!userProfileData && (
                                <CircularProgress
                                    size={24}
                                    style={{ position: "relative", top: 16, right: 18 }}
                                />
                            )}
                            <Fade in={userProfileData}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group my-3 mt-4">
                                        <label className="text-semibold label-input">
                                            First name
                                        </label>
                                        <input
                                            className="p-2 w-full"
                                            type="text"
                                            {...register("first_name", { required: true })}
                                        />
                                        {errors.password && <span> This field is required</span>}
                                    </div>
                                    <div className="form-group my-3 mt-4">
                                        <label className="text-semibold label-input">
                                            Last name
                                        </label>
                                        <input
                                            className="p-2 w-full"
                                            type="text"
                                            {...register("last_name", { required: true })}
                                        />
                                        {errors.password && <span> This field is required</span>}
                                    </div>
                                    <div className="form-group my-3 mt-4">
                                        <label className="text-semibold label-input">
                                            Phone number
                                        </label>
                                        <input
                                            className="p-2 w-full"
                                            type="text"
                                            {...register("phone_number", { required: true })}
                                        />
                                        {errors.password && <span> This field is required</span>}
                                    </div>
                                    <div className="form-group my-2 mt-4">
                                        <label className="text-semibold label-input">Email</label>
                                        <input
                                            className="p-2 w-full"
                                            {...register("email", {
                                                required: true,
                                                pattern:
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            })}
                                        />
                                        {errors.email && <span>This field is required</span>}
                                    </div>

                                    <div className="flex justify-end">
                                        <input
                                            type="submit"
                                            className="btn-primary-filled mt-4 w-3/12"
                                            value="Save"
                                        />
                                    </div>
                                </form>
                            </Fade>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default UserProfile;
