import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import action from "../../redux/Common/actions";
import actionAuth from "../../redux/Auth/actions";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { Fade, FormControl, NativeSelect, Backdrop, Modal, Slide, Button } from "@material-ui/core";

const User = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { i18n, t } = useTranslation();
    const [open, setOpen] = useState(false);
    const userProfileData = useSelector((state) => state.authReducer.auth.userProfile);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = (e) => {
        e.stopPropagation();
        dispatch(actionAuth.logout());
        handleClose();
    };

    const directTo = (e, url) => {
        e.stopPropagation();
        history.push(url);
        handleClose();
    };

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
        localStorage.setItem("Lang", e.target.value);
        setOpen(false);
        handleClose();
        window.location.reload(false);
    };

    const handleOpenModal = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const inputEl = useRef(null);
    return (
        <div className="user flex items-center">
            <p className="text-base mr-2">{userProfileData?.UserName}</p>
            <div className="relative">
                <button className="avatar" onClick={handleClick} ref={inputEl}>
                    <img src="https://www.w3schools.com/howto/img_avatar.png" alt="avatar" />
                </button>
                <Menu
                    id="menu-user"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <MenuItem onClick={(e) => directTo(e, "/user/profile")}>My account</MenuItem>
                    <MenuItem onClick={handleOpenModal}>Change language</MenuItem>

                    <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
            </div>
            <button onClick={handleOpenModal} className="btn-changelanguage">
                {i18n.language == "en" ? `🇬🇧` : `🇻🇳`}
            </button>
            <Modal
                open={open}
                onClose={handleCloseModal}
                className="backdrop-none"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Slide
                    in={open}
                    direction="down"
                    style={{
                        outline: "none",
                        left: "50%",
                        marginLeft: "-16%",
                    }}
                    className="modal-custom mt-12 mx-auto"
                >
                    <div>
                        <div className="modal-content flex justify-between items-center">
                            <FormControl style={{ width: "100%" }}>
                                <label
                                    className="text-semibold label-input"
                                    style={{ width: "100%" }}
                                >
                                    {t("MGP_COMMON_LABEL_CHANGE_LANGUAGE")}
                                </label>
                                <NativeSelect
                                    value={i18n.language}
                                    onChange={changeLanguage}
                                    variant="outlined"
                                    className="custom-select"
                                >
                                    <option value="vi">VI</option>
                                    <option value="en">EN</option>
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </div>
                </Slide>
            </Modal>
        </div>
    );
};

export default User;
