import React from "react";
import { useTranslation } from "react-i18next";
import DashboardHeader from "./Header";
import DashboardInformation from "./StoreInformation";
import DashboardMembers from "./StoreMembers";
import DashboardPromotions from "./StorePromotions";

function Dashboard() {
    const { t } = useTranslation();

    return (
        <div className="dashboard-page">
            <DashboardHeader />
            <DashboardInformation />
            <DashboardPromotions />
            <DashboardMembers />
            {/* {t("DASHBOARD")} */}
        </div>
    );
}

export default Dashboard;
