import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as couponService from "../../../services/coupon";
// import * as voucherService from "../../../services/voucher";
import { useParams } from "react-router-dom";
import * as memberService from "../../../services/member";
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { useQueryParams, StringParam } from "use-query-params";
import SearchIcon from "@material-ui/icons/Search";
import { useForm, Controller } from "react-hook-form";
import CachedIcon from "@material-ui/icons/Cached";
import { Button, Grid, CircularProgress, FormControl, NativeSelect } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import * as commonService from "../../../services/common";
import { couponType } from "../../../constants/app";
import { useTranslation } from "react-i18next";

const Table = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [rowSelected, setRowSelected] = useState([]);
    const [dataChannel, setDataChannel] = useState([]);
    const [dataVoucherCat, setDataVoucherCat] = useState([]);
    const { id } = useParams();
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 1,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        selectedRow: [],
        columns: [
            {
                name: "Type",                label: "type",
            },
            {
                name: "Code",
                label: "Code",
            },

            {
                name: "CouponId",
                label: "CouponId",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "Name",
                label: "Name",
            },
            {
                name: "Amount",
                label: "Amount",
            },
            {
                name: "Discount",
                label: "Discount",
            },
            {
                name: "EffectiveFrom",
                label: "From",
            },
            {
                name: "EffectiveTo",
                label: "To",
            },
            {
                name: "Redeem",
                label: t("MGP_COMMON_TABLE_OPERATION"),
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%" }}
                                    // onClick={(e) => {
                                    //     e.stopPropagation();
                                    //     history.push(
                                    //         `/rewards/voucher/detail/${tableMeta.rowData[0]}`
                                    //     );
                                    // }}
                                >
                                    Redeem
                                </Button>
                            </div>
                        );
                    },
                },
            },
        ],
        isLoading: false,
    });
    const [visibleAdvanceSearch, setVisibleAdvanceSearch] = useState(false);

    const [query, setQuery] = useQueryParams({
        keyword: StringParam,
        voucher_type: StringParam,
        channel_id: StringParam,
        expiry_coupon: StringParam,
        voucher_category: StringParam,
        available_stock_from: StringParam,
        available_stock_to: StringParam,
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            keyword: query.keyword,
            voucher_type: query.voucher_type,
            channel_id: query.channel_id,
            expiry_coupon: query.expiry_coupon,
            voucher_category: query.voucher_category,
            available_stock_from: query.available_stock_from,
            available_stock_to: query.available_stock_to,
        },
    });

    // const { x: num, q: searchQuery, filters } = query;

    const onSubmit = (data) => {
        console.log("DATA FORM", data.keyword);
        // setQuery({ foo: 123, bar: 'zzz' }, 'push');

        setQuery(
            {
                keyword: data.keyword || undefined,
                voucher_type: data.voucher_type || undefined,
                channel_id: data.channel_id || undefined,
                expiry_coupon: data.expiry_coupon || undefined,
                voucher_category: data.voucher_category || undefined,
                available_stock_from: data.available_stock_from || undefined,
                available_stock_to: data.available_stock_to || undefined,
            },
            "push"
        );
    };

    useEffect(() => {
        getDataChannel();
    }, []);


    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (query) {
            setState({ ...state, isLoading: true });
            xhrRequest(0, state.sortOrder, state.numberOfRows, query).then((res) => {
                setState({
                    ...state,
                    isLoading: false,
                    sortOrder,
                    rowsPerPage: state.rowsPerPage,
                    data: res.data.data.data,
                    search: state.search,
                    lastPage: res.data.data.last_page,
                    count: res.data.data.total,
                });
            });
        } else {
            getData();
        }
    }, [query]);

    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
        });
        props.handleSetTotal(res.data.data.total);
    };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }

        Object.keys(search).forEach(
            (key) => search[key] === undefined || (search[key] === "" && delete search[key])
        );

        if (search) {
            model = { ...model, ...search };
        } else {
            model = { ...model, ...getValues() };
        }
        // setState({ ...state, page: page + 1 });
        return memberService.voucherCouponGift(model, id);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(state.page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
            props.handleSetTotal(res.data.data.total);
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        console.log(query);

        xhrRequest(state.page, temp, state.rowsPerPage, query).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };


    useImperativeHandle(ref, () => ({
        deleteMultiCoupon() {
            console.log(rowSelected);
            if (rowSelected.length > 0) {
                const arrId = rowSelected.map((item) => item.Id);
                const model = {
                    list_of_id: arrId,
                };
                voucherService.deleteVoucherService(model).then((res) =>
                    setState({
                        ...state,
                        isLoading: false,
                        sortOrder,
                        data: res.data.data.data,
                        search: state.search,
                        lastPage: res.data.data.last_page,
                        count: res.data.data.total,
                    })
                );
            }

            // getData();
        },
    }));

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;

    const handleRowSelected = (currentRowsSelected, allRowsSelected) => {
        const filteredData = allRowsSelected.map((item) => data[item.dataIndex]);
        setRowSelected(filteredData);
        const arrIndex = allRowsSelected.map((item) => item.dataIndex);
        setState({ ...state, selectedRow: arrIndex });
    };

    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "multiple",
        customToolbarSelect: () => {},
        onRowSelectionChange: handleRowSelected,
        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(sortOrder, numberOfRows, query),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, tableState.sortOrder, state.rowsPerPage, query);
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
        customFooter: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage
        ) => {

            return  state.rowsPerPage < state.count && <div className="flex" style={{padding: 4}} > <button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" ariant="contained"
                                                               size="medium"
                                                               color="primary"
                                                               style={{ width: "20%" }} onClick={Loadmore}>
                                                                Load More
                                                                </button>
                                                       </div>;
        }
    };
    const Loadmore = () => {
        xhrRequest(0, state.sortOrder, state.rowsPerPage + 10, query).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: state.rowsPerPage + 10,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
        });}
    const search = () => {
        setQuery(
            {
                keyword: state.search || undefined,
            },
            "push"
        );
    };

    const handleChangeSearch = (event) => {
        setState({ ...state, search: event.target.value });
    };

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTableSelectCell: {
                    fixedLeft: {
                        position: "relative",
                    },
                },
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                    },
                },
            },
        });

    const resetValue = () => {
        setValue("keyword", "");
        setValue("voucher_type", "");
        setValue("channel_id", "");
        setValue("expiry_coupon", "");
        setValue("voucher_category", "");
        setValue("available_stock_from", "");
        setValue("available_stock_to", "");
        setQuery({
            keyword: undefined,
            voucher_type: undefined,
            channel_id: undefined,
            expiry_coupon: undefined,
            voucher_category: undefined,
            available_stock_from: undefined,
            available_stock_to: undefined,
        });
    };

    return (
            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable data={data} columns={state.columns} options={options} />
            </MuiThemeProvider>
    );
});

export default Table;
