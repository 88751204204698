import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as couponService from "../../../services/coupon";
import AvatarImg from "../../../assets/images/avatar-example.png";
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { useQueryParams, StringParam } from "use-query-params";
import SearchIcon from "@material-ui/icons/Search";
import { useForm, Controller } from "react-hook-form";
import CachedIcon from "@material-ui/icons/Cached";
import {
    Button,
    Grid,
    CircularProgress,
    FormControl,
    NativeSelect,
    IconButton,
} from "@material-ui/core";
import { couponType, validityPeriod } from "../../../constants/app";
import * as commonService from "../../../services/common";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";

const CouponTable = forwardRef((props, ref) => {
    const history = useHistory();
    const [rowSelected, setRowSelected] = useState([]);
    const [dataChannel, setDataChannel] = useState([]);
    const { t } = useTranslation();
    const [pickerStatus, setPickerStatus] = useState({
        effectiveFromFrom: false,
        effectiveFromTo: false,
        effectiveToFrom: false,
        effectiveToTo: false,
    });
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        selectedRow: [],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "ValidityPeriodType",
                label: "validity period type",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "AfterReceptionDay",
                label: "AfterReceptionDay",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "AfterEffectiveDay",
                label: "AfterEffectiveDay",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "CouponCode",
                label: t("MGP_COUPON_TABLE_COLUMN_CODE"),
            },
            {
                name: "CouponName",
                label: t("MGP_COUPON_TABLE_COLUMN_NAME"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value || "-"}</span>;
                    },
                },
            },
            {
                name: "CouponType",
                label: t("MGP_COUPON_TABLE_COLUMN_TYPE"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value == 0 ? "Cash" : value == 1 ? "Amount" : "-"}</span>;
                    },
                },
            },
            {
                name: "ChannelName",
                label: t("MGP_COUPON_TABLE_COLUMN_CHANNEL"),
            },
            {
                name: "EffectiveDateBegin",
                label: t("MGP_COUPON_TABLE_COLUMN_EFFECTIVE_FROM"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span>
                                {tableMeta.rowData[1] == 0
                                    ? `${tableMeta.rowData[2]} day(s) after reception`
                                    : value
                                    ? value
                                    : "-"}
                            </span>
                        );
                    },
                },
            },
            {
                name: "EffectiveDateEnd",
                label: t("MGP_COUPON_TABLE_COLUMN_EFFECTIVE_TO"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span>
                                {tableMeta.rowData[1] == 0
                                    ? `${tableMeta.rowData[3]} day(s) after activation`
                                    : value
                                    ? value
                                    : "-"}
                            </span>
                        );
                    },
                },
            },
            {
                name: "Delete",
                label: t("MGP_COMMON_TABLE_OPERATION"),
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(`/campaign/coupon/${tableMeta.rowData[0]}`);
                                    }}
                                >
                                    {t("MGP_COMMON_TABLE_ACTION_VIEW")}
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%", marginLeft: 8 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(
                                            `/campaign/coupon/edit/${tableMeta.rowData[0]}`
                                        );
                                    }}
                                >
                                    {t("MGP_COMMON_LABEL_EDIT")}
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{
                                        width: "100%",
                                        margin: "0 8px",
                                        whiteSpace: "nowrap",
                                        padding: "0 32px",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(
                                            `/campaign/coupon/copy/${tableMeta.rowData[0]}`
                                        );
                                    }}
                                >
                                    {t("MGP_COMMON_LABEL_COPY")}
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteCoupon(tableMeta.rowData[0]);
                                    }}
                                >
                                    {t("MGP_COMMON_LABEL_DELETE")}
                                </Button>
                            </div>
                        );
                    },
                },
            },
        ],
        isLoading: false,
    });
    const [visibleAdvanceSearch, setVisibleAdvanceSearch] = useState(false);

    const [query, setQuery] = useQueryParams({
        keyword: StringParam,
        coupon_type: StringParam,
        channel_id: StringParam,
        expiry_coupon: StringParam,
        effective_from_from: StringParam,
        effective_from_to: StringParam,
        effective_to_from: StringParam,
        effective_to_to: StringParam,
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            keyword: query.keyword,
            coupon_type: query.coupon_type,
            channel_id: query.channel_id,
            expiry_coupon: query.expiry_coupon,
            effective_from_from: query.effective_from_from,
            effective_from_to: query.effective_from_to,
            effective_to_from: query.effective_to_from,
            effective_to_to: query.effective_to_to,
        },
    });

    // const { x: num, q: searchQuery, filters } = query;

    const onSubmit = (data) => {
        console.log("DATA FORM", data.keyword);
        // setQuery({ foo: 123, bar: 'zzz' }, 'push');

        setQuery(
            {
                keyword: data.keyword || undefined,
                coupon_type: data.coupon_type || undefined,
                channel_id: data.channel_id || undefined,
                expiry_coupon: data.expiry_coupon || undefined,
                effective_from_from: data.effective_from_from || undefined,
                effective_from_to: data.effective_from_to || undefined,
                effective_to_from: data.effective_to_from || undefined,
                effective_to_to: data.effective_to_to || undefined,
            },
            "push"
        );
    };

    useEffect(() => {
        getDataChannel();
    }, []);

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (query) {
            changeRowsPerPage(state.sortOrder, state.numberOfRows, query);
        } else {
            getData();
        }
    }, [query]);

    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.total,
            lastPage: res.data.data.last_page,
        });
        props.handleSetTotal(res.data.total);
    };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }

        Object.keys(search).forEach(
            (key) => search[key] === undefined || (search[key] === "" && delete search[key])
        );

        if (search) {
            model = { ...model, ...search };
        } else {
            model = { ...model, ...getValues() };
        }
        // setState({ ...state, page: page + 1 });
        return couponService.getListCouponService(model);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
            props.handleSetTotal(res.data.data.total);
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, query).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };

    const deleteCoupon = (id) => {
        couponService.deleteCoupon({ list_of_id: [id] }).then((res) =>
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            })
        );
    };

    useImperativeHandle(ref, () => ({
        deleteMultiCoupon() {
            console.log(rowSelected);
            if (rowSelected.length > 0) {
                const arrId = rowSelected.map((item) => item.Id);
                const model = {
                    list_of_id: arrId,
                };
                couponService.deleteCoupon(model).then((res) =>
                    setState({
                        ...state,
                        isLoading: false,
                        sortOrder,
                        data: res.data.data.data,
                        search: state.search,
                        lastPage: res.data.data.last_page,
                        count: res.data.data.total,
                    })
                );
            }

            // getData();
        },
    }));

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;

    const handleRowSelected = (currentRowsSelected, allRowsSelected) => {
        const filteredData = allRowsSelected.map((item) => data[item.dataIndex]);
        setRowSelected(filteredData);
        const arrIndex = allRowsSelected.map((item) => item.dataIndex);
        setState({ ...state, selectedRow: arrIndex });
    };

    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "multiple",
        customToolbarSelect: () => {},
        onRowSelectionChange: handleRowSelected,
        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(state.sortOrder, numberOfRows, ""),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, tableState.sortOrder, state.rowsPerPage, "");
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
    };
    const search = () => {
        setQuery(
            {
                keyword: state.search || undefined,
            },
            "push"
        );
    };

    const handleChangeSearch = (event) => {
        setState({ ...state, search: event.target.value });
    };

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTableSelectCell: {
                    fixedLeft: {
                        position: "relative",
                    },
                },
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                    },
                },
            },
        });

    const resetValue = () => {
        setValue("keyword", "");
        setValue("coupon_type", "");
        setValue("channel_id", "");
        setValue("expiry_coupon", "");
        setValue("effective_from_from", "");
        setValue("effective_from_to", "");
        setValue("effective_to_from", "");
        setValue("effective_to_to", "");
        setQuery({
            keyword: undefined,
            coupon_type: undefined,
            channel_id: undefined,
            expiry_coupon: undefined,
            effective_from_from: undefined,
            effective_from_to: undefined,
            effective_to_from: undefined,
            effective_to_to: undefined,
        });
    };

    const handleDateChange = (e, key) => {
        setValue(key, e.format("YYYY-MM-DD"));
    };

    return (
        <div className="wrapper-table">
            <div className="wrapper-advance-search">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="search-table flex items-center">
                        <input
                            className="p-2 w-5/12 mr-3"
                            placeholder="Search..."
                            {...register("keyword")}
                        />
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            style={{ minHeight: 42 }}
                            startIcon={<SearchIcon />}
                            disabled={visibleAdvanceSearch}
                            type="submit"
                            value="Search"
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            style={{ minHeight: 42, marginLeft: 8 }}
                            startIcon={<SearchIcon />}
                            onClick={(e) => {
                                e.stopPropagation();
                                setVisibleAdvanceSearch(!visibleAdvanceSearch);
                                // resetValue();
                            }}
                        >
                            Advance Search
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                style={{ marginLeft: 15, position: "relative", top: 4 }}
                            />
                        )}
                    </div>
                    {visibleAdvanceSearch && (
                        <div className="advance-search-box pb-8 mt-8">
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    Coupon type:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Controller
                                                    name="coupon_type"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, ref },
                                                    }) => (
                                                        <FormControl style={{ width: "100%" }}>
                                                            <NativeSelect
                                                                value={value}
                                                                onChange={onChange}
                                                                name="coupon_type"
                                                                inputRef={ref}
                                                                variant="outlined"
                                                                className="custom-select"
                                                            >
                                                                <option
                                                                    aria-label="None"
                                                                    value=""
                                                                />
                                                                {couponType.map((item, index) => (
                                                                    <option
                                                                        value={item.value}
                                                                        key={index}
                                                                    >
                                                                        {item.type}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </FormControl>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    Channel:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Controller
                                                    name="channel_id"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, ref },
                                                    }) => (
                                                        <FormControl style={{ width: "100%" }}>
                                                            <NativeSelect
                                                                value={value}
                                                                onChange={onChange}
                                                                name="channel_id"
                                                                inputRef={ref}
                                                                variant="outlined"
                                                                className="custom-select"
                                                            >
                                                                <option
                                                                    aria-label="None"
                                                                    value=""
                                                                />
                                                                {dataChannel.map((item, index) => (
                                                                    <option
                                                                        value={item.Id}
                                                                        key={index}
                                                                    >
                                                                        {item.ChannelName}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </FormControl>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    Effective date from:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <div className="flex items-center">
                                                    <div className="custom-date-picker flex">
                                                        <MuiPickersUtilsProvider
                                                            utils={MomentUtils}
                                                        >
                                                            <KeyboardDatePicker
                                                                inputProps={{ readOnly: true }}
                                                                margin="normal"
                                                                inputVariant="outlined"
                                                                id="date-picker-dialog"
                                                                InputAdornmentProps={{
                                                                    position: "start",
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setValue(
                                                                                    "effective_from_from",
                                                                                    null
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    ),
                                                                }}
                                                                format="DD/MM/yyyy"
                                                                value={
                                                                    watch("effective_from_from")
                                                                        ? watch(
                                                                              "effective_from_from"
                                                                          )
                                                                        : null
                                                                }
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "effective_from_from"
                                                                    )
                                                                }
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                onClick={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        effectiveFromFrom: true,
                                                                    })
                                                                }
                                                                onClose={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        effectiveFromFrom: false,
                                                                    })
                                                                }
                                                                open={
                                                                    pickerStatus.effectiveFromFrom
                                                                }
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <ArrowRightAltIcon
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                    <div className="custom-date-picker flex">
                                                        <MuiPickersUtilsProvider
                                                            utils={MomentUtils}
                                                            className="flex"
                                                        >
                                                            <KeyboardDatePicker
                                                                inputProps={{ readOnly: true }}
                                                                margin="normal"
                                                                inputVariant="outlined"
                                                                id="date-picker-dialog"
                                                                format="DD/MM/yyyy"
                                                                value={
                                                                    watch("effective_from_to")
                                                                        ? watch("effective_from_to")
                                                                        : null
                                                                }
                                                                InputAdornmentProps={{
                                                                    position: "start",
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setValue(
                                                                                    "effective_from_to",
                                                                                    null
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    ),
                                                                }}
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "effective_from_to"
                                                                    )
                                                                }
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                onClick={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        effectiveFromTo: true,
                                                                    })
                                                                }
                                                                onClose={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        effectiveFromTo: false,
                                                                    })
                                                                }
                                                                open={pickerStatus.effectiveFromTo}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    Effective date to:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <div className="flex items-center">
                                                    <div className="custom-date-picker">
                                                        <MuiPickersUtilsProvider
                                                            utils={MomentUtils}
                                                        >
                                                            <KeyboardDatePicker
                                                                inputProps={{ readOnly: true }}
                                                                margin="normal"
                                                                inputVariant="outlined"
                                                                id="date-picker-dialog"
                                                                format="DD/MM/yyyy"
                                                                value={
                                                                    watch("effective_to_from")
                                                                        ? watch("effective_to_from")
                                                                        : null
                                                                }
                                                                InputAdornmentProps={{
                                                                    position: "start",
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setValue(
                                                                                    "effective_to_from",
                                                                                    null
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    ),
                                                                }}
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "effective_to_from"
                                                                    )
                                                                }
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                onClick={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        effectiveToFrom: true,
                                                                    })
                                                                }
                                                                onClose={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        effectiveToFrom: false,
                                                                    })
                                                                }
                                                                open={pickerStatus.effectiveToFrom}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <ArrowRightAltIcon
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                    <div className="custom-date-picker">
                                                        <MuiPickersUtilsProvider
                                                            utils={MomentUtils}
                                                        >
                                                            <KeyboardDatePicker
                                                                inputProps={{ readOnly: true }}
                                                                margin="normal"
                                                                inputVariant="outlined"
                                                                id="date-picker-dialog"
                                                                format="DD/MM/yyyy"
                                                                value={
                                                                    watch("effective_to_to")
                                                                        ? watch("effective_to_to")
                                                                        : null
                                                                }
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "effective_to_to"
                                                                    )
                                                                }
                                                                InputAdornmentProps={{
                                                                    position: "start",
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setValue(
                                                                                    "effective_to_to",
                                                                                    null
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    ),
                                                                }}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                onClick={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        effectiveToTo: true,
                                                                    })
                                                                }
                                                                onClose={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        effectiveToTo: false,
                                                                    })
                                                                }
                                                                open={pickerStatus.effectiveToTo}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    Expiry coupon:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Controller
                                                    name="expiry_coupon"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, ref },
                                                    }) => (
                                                        <FormControl style={{ width: "100%" }}>
                                                            <NativeSelect
                                                                value={value}
                                                                onChange={onChange}
                                                                name="expiry_coupon"
                                                                inputRef={ref}
                                                                variant="outlined"
                                                                className="custom-select"
                                                            >
                                                                <option
                                                                    aria-label="None"
                                                                    value=""
                                                                />
                                                                <option aria-label="None" value="1">
                                                                    Expired
                                                                </option>
                                                                <option aria-label="None" value="2">
                                                                    Out of date
                                                                </option>
                                                            </NativeSelect>
                                                        </FormControl>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                            <div className="flex justify-end mt-3">
                                <Button
                                    style={{ marginTop: 8, textTransform: "capitalize" }}
                                    variant="contained"
                                    size="medium"
                                    className="btn-primary-filled mt-2 text-center"
                                    // disabled={loading}
                                    // onClick={() => setVisibleAdvanceSearch(false)}
                                    startIcon={<CachedIcon />}
                                    onClick={() => resetValue()}
                                >
                                    Reset
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{
                                        marginTop: 8,
                                        marginLeft: 12,
                                        textTransform: "capitalize",
                                    }}
                                    startIcon={<SearchIcon />}
                                    type="submit"
                                    value="Search"
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable data={data} columns={state.columns} options={options} />
            </MuiThemeProvider>

            {/* <Typography>Page: {state.page + 1}</Typography>
            <Pagination count={lastPage} page={state.page + 1} onChange={handleChange} /> */}
        </div>
    );
});

export default CouponTable;
