import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getListMemberService = (model) => {
    var url = `${ApiRouters.MEMBER}`;
    return api.get(url, { params: model });
};

export const addMember = (body) => {
    var url = `${ApiRouters.MEMBER}`;
    return api.post(url, body);
};

export const updateMember = (body, id) => {
    var url = `${ApiRouters.MEMBER}/${id}`;
    body.id = id;
    return api.put(url, body);
};

export const getMemberDetail = (id) => {
    var url = `${ApiRouters.MEMBER}/${id}`;
    return api.get(url);
};

export const getListMemberAccmulateService = (model, idMember) => {
    var url = `${ApiRouters.MEMBER}/${idMember}/loyalty-member-transaction`;
    return api.get(url, { params: model });
};

export const transactionSpendPoint = (body, idMember) => {
    var url = `${ApiRouters.MEMBER}/${idMember}/transaction-spend-point`;
    return api.post(url, body);
};

export const transactionEarnPoint = (body, idMember) => {
    var url = `${ApiRouters.MEMBER}/${idMember}/transaction-earn-point`;
    return api.post(url, body);
};

export const voucherCouponGift = (model, idMember) => {
    var url = `${ApiRouters.MEMBER}/${idMember}/vouchercoupongift`;
    return api.get(url, { params: model });
};
