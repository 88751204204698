import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./constants/route";
import Loader from "./components/Loader";
import { getToken } from "./utils/actionUtil";
import App from "./pages/app";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Member from "./pages/Member";
import MemberDetail from "./pages/Member/detail";
import MemberAdd from "./pages/Member/add";
import SpotCampaign from "./pages/SpotCampaign";
import SpotCampaignEdit from "./pages/SpotCampaign/Edit";
import SpotCampaignAdd from "./pages/SpotCampaign/Add";
import SpotCampaignDetail from "./pages/SpotCampaign/Detail";
import CouponCampaign from "./pages/CouponCampaign";
import CouponCampaignAdd from "./pages/CouponCampaign/add";
import CouponCampaignEdit from "./pages/CouponCampaign/edit";
import CouponCampaignCopy from "./pages/CouponCampaign/copy";
import CouponCampaignDetail from "./pages/CouponCampaign/detail";
import RecurringCampaign from "./pages/RecurringCampaign";
import RecurringCampaignAdd from "./pages/RecurringCampaign/Add";
import TriggerCampaign from "./pages/TriggerCampaign";
import TriggerCampaignAdd from "./pages/TriggerCampaign/Add";
import LifecycleCampaign from "./pages/LifecycleCampaign";
import RewardCentre from "./pages/RewardCentre";
import NotFoundPage from "./pages/Error/not-found";
import UserProfile from "./pages/UserProfile";
import { QueryParamProvider } from "use-query-params";
import LoyaltyTierRule from "./pages/Loyalty/TierRule/TierRule";
import LoyaltyPointRule from "./pages/Loyalty/PointRule/PointRule";
import Voucher from "./pages/Voucher";
import VoucherAdd from "./pages/Voucher/Add";
import VoucherDetail from "./pages/Voucher/Detail";
import VoucherEdit from "./pages/Voucher/Edit";
import Gift from "./pages/Gift";
import GiftAdd from "./pages/Gift/Add";
import GiftDetail from "./pages/Gift/Detail";
import GiftEdit from "./pages/Gift/Edit";
import CustomRuleDetail from "./pages/Loyalty/PointRule/Table/detail";
import RedeemPoint from "./pages/Redemption/RedeemPoint";
import RedeemCoupon from "./pages/Redemption/RedeemCoupon";
import SelectStore from "./pages/SelectStore";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page

        <Route
            {...rest}
            render={(props) => (getToken() ? <Component {...props} /> : <Redirect to="/" />)}
        />
    );
};

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route
            {...rest}
            render={(props) =>
                getToken() && restricted ? <Redirect to="/dashboard" /> : <Component {...props} />
            }
        />
    );
};

export default function RouteConfigExample() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <HashRouter>
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <App>
                            <Switch>
                                <PublicRoute restricted={true} component={Login} path="/" exact />
                                <PublicRoute
                                    restricted={true}
                                    component={SelectStore}
                                    path="/select-store"
                                    exact
                                />
                                {/* <PrivateRoute path="/dashboard" exact></PrivateRoute> */}
                                <PrivateRoute component={Dashboard} path="/dashboard" exact />
                                <PrivateRoute component={Member} path="/member" exact />
                                <PrivateRoute component={MemberAdd} path="/member/add" exact />
                                <PrivateRoute component={MemberDetail} path="/member/:id" />
                                <PrivateRoute component={UserProfile} path="/user/profile" exact />
                                <PrivateRoute
                                    component={CouponCampaign}
                                    path="/campaign/coupon"
                                    exact
                                />
                                <PrivateRoute
                                    component={CouponCampaignAdd}
                                    path="/campaign/coupon/add"
                                    exact
                                />
                                <PrivateRoute
                                    component={CouponCampaignEdit}
                                    path="/campaign/coupon/edit/:id"
                                    exact
                                />
                                <PrivateRoute
                                    component={CouponCampaignCopy}
                                    path="/campaign/coupon/copy/:id"
                                    exact
                                />
                                <PrivateRoute
                                    component={CouponCampaignDetail}
                                    path="/campaign/coupon/:id"
                                    exact
                                />
                                <PrivateRoute
                                    component={LoyaltyTierRule}
                                    path="/loyalty/tier-rule"
                                    exact
                                />
                                <PrivateRoute
                                    component={LoyaltyPointRule}
                                    path="/loyalty/point-rule"
                                    exact
                                />
                                <PrivateRoute
                                    component={SpotCampaign}
                                    path="/campaign/spot"
                                    exact
                                />
                                <PrivateRoute
                                    component={SpotCampaignAdd}
                                    path="/campaign/spot/add"
                                    exact
                                />
                                <PrivateRoute
                                    component={SpotCampaignDetail}
                                    path="/campaign/spot/detail/:id"
                                    exact
                                />
                                <PrivateRoute
                                    component={SpotCampaignEdit}
                                    path="/campaign/spot/:stt/:id"
                                    exact
                                />
                                <PrivateRoute
                                    component={RecurringCampaign}
                                    path="/campaign/recurring"
                                    exact
                                />
                                <PrivateRoute
                                    component={RecurringCampaignAdd}
                                    path="/campaign/recurring/add"
                                    exact
                                />
                                <PrivateRoute
                                    component={TriggerCampaign}
                                    path="/campaign/trigger"
                                    exact
                                />
                                <PrivateRoute
                                    component={TriggerCampaignAdd}
                                    path="/campaign/trigger/edit"
                                    exact
                                />
                                <PrivateRoute
                                    component={LifecycleCampaign}
                                    path="/campaign/lifecycle"
                                    exact
                                />
                                <PrivateRoute
                                    component={RewardCentre}
                                    path="/rewards/centre"
                                    exact
                                />
                                <PrivateRoute
                                    component={RedeemCoupon}
                                    path="/rewards/redeem-coupon"
                                    exact
                                />
                                <PrivateRoute
                                    component={RedeemPoint}
                                    path="/rewards/redeem-point"
                                    exact
                                />
                                <PrivateRoute component={Voucher} path="/rewards/voucher" exact />
                                <PrivateRoute
                                    component={VoucherAdd}
                                    path="/rewards/voucher/add"
                                    exact
                                />
                                <PrivateRoute
                                    component={VoucherDetail}
                                    path="/rewards/voucher/detail/:id"
                                    exact
                                />
                                <PrivateRoute
                                    component={VoucherEdit}
                                    path="/rewards/voucher/:stt/:id"
                                    exact
                                />
                                <PrivateRoute component={Gift} path="/rewards/gift" exact />
                                <PrivateRoute component={GiftAdd} path="/rewards/gift/add" exact />
                                <PrivateRoute
                                    component={GiftDetail}
                                    path="/rewards/gift/detail/:id"
                                    exact
                                />
                                <PrivateRoute
                                    component={GiftEdit}
                                    path="/rewards/gift/:stt/:id"
                                    exact
                                />
                                <PrivateRoute
                                    component={CustomRuleDetail}
                                    path="/customrule/detail/:id_url"
                                    exact
                                />
                            </Switch>
                        </App>
                    </QueryParamProvider>
                </HashRouter>
            </Suspense>
        </ErrorBoundary>
    );
}
