import { REACT_APP_API_URL } from "../constants/app";

export const ApiRouters = {
    // EXAMPLE: "EXAMPLE" + baseRouter,
    PROVINCE: REACT_APP_API_URL + "province",
    DISTRICT: REACT_APP_API_URL + "district",
    GET_JOB_TITLE: REACT_APP_API_URL + "jobtitle",
    GET_OCCUPATION: REACT_APP_API_URL + "occupation",
    GET_CHANNEL: REACT_APP_API_URL + "channel",
    GET_CHANNEL_PAGINATION: REACT_APP_API_URL + "channelPagination",
    GET_STORE: REACT_APP_API_URL + "store",
    GET_TIER: REACT_APP_API_URL + "loyalty/loyaltytiers",
    GET_MEMBER_TAG: REACT_APP_API_URL + "membertag",
    GET_MEMBER_GROUP: REACT_APP_API_URL + "membergroup",

    MEMBER: REACT_APP_API_URL + "member",
    GET_CODE_GENERATED: REACT_APP_API_URL + "member/generate-code",

    COUPON: REACT_APP_API_URL + "coupon",
    GET_CODE_COUPON_GENERATED: REACT_APP_API_URL + "coupon/generate-code",

    CAMPAIGN: REACT_APP_API_URL + "campaign",
    COUPON_CAMPAIGN: REACT_APP_API_URL + "coupon/campaign",

    REDEEM_COUPON: REACT_APP_API_URL + "redemption",
    REDEEM_POINT: REACT_APP_API_URL + "redemption-points",
    GENERATE_DISCOUNT_POINT: REACT_APP_API_URL + "discount-point",

    MEMBER_COUPON: REACT_APP_API_URL + "membercoupon",

    LOYALTY_POINT_RULE: REACT_APP_API_URL + "point-rule",
    LOYALTY_TIER_RULE: REACT_APP_API_URL + "tier-rule",

    VOUCHER: REACT_APP_API_URL + "voucher",
    ALL_VOUCHER_CATEGORY: REACT_APP_API_URL + "vouchercategory",
    VOUCHER_CATEGORY: REACT_APP_API_URL + "vouchercategorypagination",
    VOUCHER_CAMPAIGN: REACT_APP_API_URL + "voucher/campaign",

    GIFT: REACT_APP_API_URL + "gift",
    ALL_GIF_CATEGORY: REACT_APP_API_URL + "giftcategory",
    GIF_CATEGORY: REACT_APP_API_URL + "giftcategorypagination",
    GIFT_CAMPAIGN: REACT_APP_API_URL + "gift/campaign",

    LOYALTY_POINT_RULE_CATEGORY: REACT_APP_API_URL + "pointrulecategories",
    LOYALTY_POINT_RULE_PRODUCT: REACT_APP_API_URL + "pointruleproduct",
    LOYALTY_POINT_RULE_STORE: REACT_APP_API_URL + "pointrulestore",
    LOYALTY_POINT_RULE_BRAND: REACT_APP_API_URL + "pointrulebrand",
    LOYALTY_POINT_RULE_CHANNEL: REACT_APP_API_URL + "pointrulechannel",

    PRODUCT: REACT_APP_API_URL + "product",

    STORE: REACT_APP_API_URL + "store",

    BRAND: REACT_APP_API_URL + "brand",

    PRODUCTCATEGORY: REACT_APP_API_URL + "productcategories",

    POINT_RULE: REACT_APP_API_URL + "loyalty_point-rule",

    STORE_NAME: REACT_APP_API_URL + "store-name",


};
