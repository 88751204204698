import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Breadcrumbs, Link } from "@material-ui/core";
import GiftTable from "./Table/Gift";
import VoucherTable from "./Table/Voucher";
import CouponTable from "./Table/Coupon";
import GiftRedemption from "./Table/GiftRedemption";
import VoucherRedemption from "./Table/VoucherRedemption";
import CouponRedemption from "./Table/CouponRedemption";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function RewardCentre() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="reward-centre-page">
            <div className="header-content py-2">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" to="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Rewards campaign</Typography>
                    </Breadcrumbs>
                </div>
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-semibold uppercase">Rewards campaign</h1>
                    </div>
                </div>
            </div>
            <div className="wrapper-paper" style={{ padding: 0, overflow: "hidden" }}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className="custom-tabs"
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Gif" id="simple-tab-0" className="custom-tab-content" wrapped />
                        <Tab
                            label="Voucher"
                            id="simple-tab-1"
                            className="custom-tab-content"
                            wrapped
                        />
                        <Tab
                            label="Coupon"
                            id="simple-tab-2"
                            className="custom-tab-content"
                            wrapped
                        />
                        <Tab
                            label="Gift redemption history"
                            id="simple-tab-3"
                            className="custom-tab-content"
                            wrapped
                        />
                        <Tab
                            label="Voucher redemption history"
                            id="simple-tab-4"
                            className="custom-tab-content"
                            wrapped
                        />
                        <Tab
                            label="Coupon redemption history"
                            id="simple-tab-5"
                            className="custom-tab-content"
                            wrapped
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <GiftTable />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <VoucherTable />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CouponTable />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <GiftRedemption />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <VoucherRedemption />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <CouponRedemption />
                </TabPanel>
            </div>
        </div>
    );
}
