import actions from "./actions";

const initState = {
    newMembers: [],
    loading: false,
    totalItems: 0,
};

export default function dashboardReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_NEW_MEMBERS:
            return { ...state, loading: true };

        case actions.GET_NEW_MEMBERS_SUCCESS:
            const { data, total } = action.payload;
            return {
                ...state,
                newMembers: data?.length ? data : [],
                totalItems: total || 0,
                loading: false,
            };

        case actions.GET_NEW_MEMBERS_ERROR:
            return { ...state, newMembers: [], totalItems: 0, loading: false };

        default:
            return state;
    }
}
