import React from "react";
import HeaderPage from "../../components/Header/HeaderPage";
import {useSelector} from "react-redux";
function Header() {
    //Get store name
    const storeName = useSelector((state) => state.commonReducer.storeName);

    return (
        <HeaderPage extraClass="dashboard-header">
            <div className="header-left">
                <p className="header-title">DASHBOARD</p>
                <p className="header-sub-title">Welcome to [{storeName}]</p>
            </div>
        </HeaderPage>
    );
}

export default Header;
