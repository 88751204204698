import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import InfoIcon from "@material-ui/icons/Info";
import {
    Checkbox,
    FormControlLabel,
    FormControl,
    CircularProgress,
    NativeSelect,
    Breadcrumbs,
    Typography,
    Link,
    TextField,
    IconButton,
} from "@material-ui/core";
import commonAction from "../../redux/Common/actions";
import CloseIcon from "@material-ui/icons/Close";
import ImageIcon from "@material-ui/icons/Image";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import * as voucherService from "../../services/voucher";
import * as giftService from "../../services/gift";
import * as commonService from "../../services/common";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import Quantity from "../../components/Quantity";
import { couponType, validityPeriod } from "../../constants/app";
import DaterangePicker from "../../components/DaterangePicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ImageUploading from "react-images-uploading";
// import VoucherCategoryModal from "./Modal/VoucherCategoryModal";
import GiftCategoryModal from "./Modal/GiftCategoryModal";

function Edit(props) {
    const [loading, setLoading] = useState(false);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const history = useHistory();
    const [images, setImages] = useState([]);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const maxNumber = 1;
    const { id, stt } = useParams();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            // applicable_stores: "",
            gift_code_auto: "",
            amount: 0,
            gift_category: "",
            start_from: 0,
            end_to: 0,
            channel_id: 0,
            validity_period_type: 0,
            gift_type: 0,
            redemption_conditions: 0,
            minimum_timming: false,
            minimum_purchase: false,
            minimum_number: false,
            redemption_validity_begin: 0,
            redemption_validity_end: 0,
            active: 0,
        },
    });
    const [codeGenerate, setCodeGenerate] = useState(false);
    const [isActive, setActive] = useState(false);
    const [availableStockNoLimit, setAvailableStockNoLimit] = useState(false);
    const [dataGiftCat, setDataGiftCat] = useState([]);

    const [address, setAddress] = useState({
        province: "",
        district: "",
    });

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const [dataChannel, setDataChannel] = useState([]);

    useEffect(() => {
        getDataChannel();
        getAllGiftCategory();
        getData();
    }, []);

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        giftService
            .detailGiftService(id)
            .then((res) => {

                if (res.data.data) {
                    const resp = res.data.data;
                    setData(res.data.data);
                    console.log(resp);
                    if (stt == "edit") {
                        setValue("gift_code", resp?.GiftCode || "");
                    }

                    setValue("gift_type", resp?.GiftType || 0);
                    setValue("amount", resp?.Amount);
                    setValue("discount", resp?.Discount);
                    setValue("channel_id", resp?.ChannelId);
                    setValue("gift_category", resp?.CategoryId);
                    setValue("available_stock", resp?.AvailableStock);
                    setValue("active", resp?.Active);

                    setValue("gift_name", resp?.GiftName || "");

                    setValue("validity_period_type", resp?.ValidityPeriodType || 1);
                    if (resp?.ValidityPeriodType == 0) {
                        setValue("start_from", resp?.StartFrom);
                        setValue("end_to", resp?.EndTo);
                    }

                    if (resp?.ValidityPeriodType == 1) {
                        setValue("specific_date_from", resp?.EffectiveDateBegin);
                        setValue("specific_date_to", resp?.EffectiveDateEnd);
                    }

                    setValue("redemption_validity_begin", resp?.RedemptionValidityBegin || "");
                    setValue("redemption_validity_end", resp?.RedemptionValidityEnd || "");

                    resp?.MinimumItemPurchase &&
                        setValue("minimum_item_purchase", resp?.MinimumItemPurchase || "");
                    resp?.redemptionValidity &&
                        setValue("redemption_validity", resp?.redemptionValidity || "");

                    setValue("term_and_conditions", resp?.TermAndConditions || "");
                    // defaultImages={`https://${window.location.hostname}${data?.TemplateBanner}`}
                    if (resp?.TemplateBanner) {
                        setImages([
                            {
                                data_url: `http://${window.location.hostname}${resp?.TemplateBanner}`,
                            },
                        ]);
                    }
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };

    const getAllGiftCategory = () => {
        giftService
            .getAllGiftCategoryService()
            .then((res) => {
                if (res.data.data) {
                    setDataGiftCat([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const onSubmit = (data) => {
        setLoading(true);
        // if (dataStoreSelected.length > 0) {
        //     data.applicable_stores = dataStoreSelected.map((obj) => obj.Id);
        // }

        console.log(data);

        if (data.gift_code_auto && data.gift_code == "") {
            data.gift_code = data.gift_code_auto;
            delete data.gift_code_auto;
        } else {
            delete data.gift_code_auto;
        }

        if (data.gift_type == 0) {
            delete data.discount;
        } else {
            delete data.amount;
        }
        if (data.active) {
            data.active = 1;
        }else{
            data.active = 0;
        }


        // TODO: change available_stock to nolimit
        if (availableStockNoLimit) {
            data.available_stock == 1;
        }
        const fd = new FormData();
        for (var key in data) {
            fd.append(key, data[key]); // formdata doesn't take objects
        }

        if (images && images.length > 0) {
            if (images[0].file && images[0].file.name) {
                fd.append("template_banner", images[0].file, images[0].file.name);
            }
            // else {
            //     fd.append("template_banner", images[0].data_url);
            // }
        }

        console.log("DATA FORM", fd);

        if (stt == "edit") {
            giftService
                .updateGiftService(id, fd)
                .then((res) => {
                    toast.success("Edit gift successful!");
                    setLoading(false);
                    history.push("/rewards/gift");
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else {
            giftService
                .addGiftService(fd)
                .then((res) => {
                    toast.success("Add gift successful!");
                    setLoading(false);
                    history.push("/rewards/gift");
                })
                .catch((err) => {
                    console.log(err);
                    sextLoading(false);
                });
        }
    };
    
    const handleChangeActive = (e) => {
        console.log(e.target.checked);
        setActive(e.target.checked);
        // console.log(e);
        // const checked = e.target.checked
        // const checkedValue = e.target.value;
        // const checkedName = e.target.name;
    }

    const handleGenerateCode = (e) => {
        setCodeGenerate(e.target.checked);
        if (e.target.checked == true) {
            // couponService
            //     .getGenerateCode()
            //     .then((res) => setValue("voucher_code_auto", res.data.data))
            //     .catch((err) => console.log(err));
            setValue("gift_code", "");
        } else {
            setValue("gift_code", "");
        }
    };

    const handleSetAvailableStockNoLimit = (e) => {
        setAvailableStockNoLimit(e.target.checked);
        if (e.target.checked == true) {
            // couponService
            //     .getGenerateCode()
            //     .then((res) => setValue("voucher_code_auto", res.data.data))
            //     .catch((err) => console.log(err));
            setValue("available_stock", "");
        } else {
            setValue("available_stock", "");
        }
    };

    const directBack = (e) => {
        e.preventDefault();
        history.push("/campaign/gift");
    };

    const handleChangeAddress = (event) => {
        const name = event.target.name;

        if (name == "province") {
            setValue("province_id", event.target.value);
            setAddress({
                [name]: event.target.value,
                district: "",
            });
            setDDataistrict([]);
            setValue("district", "");
        } else {
            setValue("district_id", event.target.value);
            setAddress({
                ...address,
                [name]: event.target.value,
            });
        }
    };
    const handleChangeJobTitle = (event) => {
        // const name = event.target.name;
        setValue("jobTitle_id", event.target.value);
        setJobTitle(event.target.value);
    };

    const isExistCode = (code) => !code && !getValues("gift_code_auto");

    const handleChangeDaterange = (range) => {
        setValue("specific_date_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("specific_date_to", moment(range.endDate).format("YYYY-MM-DD"));
    };

    useEffect(() => {
        if (watch("validity_period_type") == 1) {
            setValue("start_from", 0);
            setValue("end_to", undefined);
        } else {
            setValue("specific_date_from", undefined);
            setValue("specific_date_to", undefined);
        }
    }, [watch("validity_period_type")]);

    useEffect(() => {
        setValue("redemption_validity_begin", "");
        setValue("redemption_validity_end", "");
    }, [watch("minimum_timming")]);

    useEffect(() => {
        setValue("redemption_validity", "");
    }, [watch("minimum_purchase")]);

    useEffect(() => {
        setValue("minimum_item_purchase", "");
    }, [watch("minimum_number")]);

    return (
        <div className="member-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            Home
                        </Link>
                        <Link color="inherit" href="/#/rewards/voucher">
                            Voucher
                        </Link>
                        <Typography color="textPrimary">
                            {stt == "edit" ? "Edit" : "Add"} gift
                        </Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">
                                {stt == "edit" ? "Edit" : "Add"} loyalty gift
                            </h1>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Gift code:{" "}
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("gift_code")}
                                            disabled={codeGenerate || stt == "edit"}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="form-group  h-full">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={codeGenerate}
                                                        onChange={handleGenerateCode}
                                                        name="checkedB"
                                                        color="primary"
                                                        disabled={stt == "edit"}
                                                    />
                                                }
                                                label="Auto generated gift code"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Gift name:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("gift_name", { required: true })}
                                        />
                                        {errors.coupon_name && (
                                            <span className="text-error">
                                                This field is required
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Gift category:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="flex items-center">
                                            <Controller
                                                name="gift_category"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={value}
                                                            onChange={onChange}
                                                            name="gift_category"
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option value=""></option>
                                                            {dataGiftCat
                                                                ? dataGiftCat.map(
                                                                      (item, index) => (
                                                                          <option
                                                                              value={item?.Id}
                                                                              key={index}
                                                                          >
                                                                              {item?.GiftName}
                                                                          </option>
                                                                      )
                                                                  )
                                                                : ""}
                                                        </NativeSelect>
                                                    </FormControl>
                                                )}
                                            />
                                            <GiftCategoryModal
                                                handleGetAllGiftCategory={getAllGiftCategory}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Gift type:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} className="flex items-center">
                                        <Controller
                                            name="gift_type"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="jobTitle_id"
                                                        inputProps={{ "aria-label": "province" }}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {couponType.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            {watch("gift_type") == 0
                                                ? "Cash amount:"
                                                : "Discount rate"}
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} className="flex items-center">
                                        {watch("gift_type") == 0 && (
                                            <Controller
                                                name="amount"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => {
                                                    return (
                                                        <Quantity
                                                            type="currency"
                                                            onChange={onChange}
                                                            value={value}
                                                            name="amount"
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                        {watch("gift_type") == 1 && (
                                            <Controller
                                                name="discount"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => {
                                                    return (
                                                        <Quantity
                                                            onChange={onChange}
                                                            value={value}
                                                            name="discount"
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <p className="flex items-center ml-1">
                                        {watch("gift_type") == 0 ? "" : "%"}
                                    </p>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Channel:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="channel_id"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="channel_id"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value=""></option>
                                                        {dataChannel.map((item, index) => (
                                                            <option value={item.Id} key={index}>
                                                                {item.ChannelName}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex">
                                        <label className="text-semibold label-input mt-2">
                                            Validity period:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="validity_period_type"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="jobTitle_id"
                                                        inputProps={{ "aria-label": "province" }}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {validityPeriod.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                        {watch("validity_period_type") == 0 ? (
                                            <div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            Start from:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="start_from"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after reception
                                                    </label>
                                                </div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-11 whitespace-nowrap ">
                                                            End to:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="end_to"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after effective
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-4">
                                                <DaterangePicker onChange={handleChangeDaterange} />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input">
                                            Active:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="active"
                                            control={control}
                                            render={({field: { onChange, value, ref },}) => {
                                                return (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={value}
                                                                onChange={onChange}
                                                                name="active"
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input">
                                    terms & conditions:
                                </label>
                                <textarea
                                    id="w3review"
                                    name="w3review"
                                    rows="4"
                                    cols="50"
                                    {...register("term_and_conditions")}
                                ></textarea>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="pb-4">
                        <Grid item xs={3}>
                            <button
                                // type="submit"
                                className="btn-primary-filled w-full mt-2 text-center"
                                disabled={loading}
                                onClick={directBack}
                            >
                                Cancel
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <input
                                type="submit"
                                className="btn-primary-filled w-full mt-2"
                                disabled={loading}
                                value="Save"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    style={{ position: "relative", top: 16 }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default Edit;
