import {
    Breadcrumbs,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, IconButton,
    NativeSelect,
    Radio,
    RadioGroup, Typography,
    CircularProgress, Button, Modal, Backdrop, Fade,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import React, {useEffect, useState} from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as loyaltyService from "../../../../services/loyalty";
import commonAction from "../../../../redux/Common/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import ProductCategoryModal from "./ProductCategoryModal";
import PointRuleModal from "./PointRuleModal";
import HeaderPage from "../../../../components/Header/HeaderPage";
import {MuiThemeProvider} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import * as productCategoryService from "../../../../services/productCategory";

import {addPointRule, addPointRuleCategory, getPointRuleCategory} from "../../../../services/loyalty";

const AddPointRuleModal = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [rowSelected, setRowSelected] = useState([]);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const [isActive, setActive] = useState(false);
    const [pickerStatus, setPickerStatus] = useState({
        effectiveFromFrom: false,
        effectiveFromTo: false,
        effectiveToFrom: false,
        effectiveToTo: false,
    });
    const directBack = (e) => {
        e.preventDefault();
        setOpen(false);
        history.push("/loyalty");
    };
    const handleDateChange = (e, key) => {
        setValue(key, e.format("YYYY-MM-DD"));
    };
    const dispatch = useDispatch();
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        selectedRow: [],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "Name",
                label: "Name",
            },
            {
                name: "Description",
                label: "Description",
            },
        ],
        isLoading: false,
    });
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            point_name: "Points",
            use_default: true,
            point_redemption: '',
            redemption_spend_one_sgd_get: 0.05, // 0 - Round down
            remark: "Earned points expire every 24 months",
            points: "Points",
            currency: "VND",
            point_expiry_rule: null,
            earning_rate: '',
            expire_on: null,
            expire_month: 0,
            rule_name: '',
        },
    });
    const [search, setSearch] = useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
    };

    useEffect(() => {
        getData(0);
    }, []);

    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        const dataFiltered = dataStoreSelected.map((item) =>
            res.data.data.data.findIndex((x) => x.Id === item.Id)
        );
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
            selectedRow: dataFiltered,
        });
    };



    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }

        if (search) {
            model.keyword = search;
        }
        setState({ ...state, page: page + 1 });
        return productCategoryService.getProductCategoryService(model);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage, selectedRow } = state;
    const handleRowSelected = (currentRowsSelected, allRowsSelected) => {
        const filteredData = allRowsSelected.map((item) => data[item.dataIndex]);
        setRowSelected(filteredData);
        const arrIndex = allRowsSelected.map((item) => item.dataIndex);
        setState({ ...state, selectedRow: arrIndex });
    };

    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "multiple",
        rowsSelected: selectedRow,
        customToolbarSelect: () => {},

        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        onRowSelectionChange: handleRowSelected,
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(state.sortOrder, numberOfRows, ""),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, tableState.sortOrder, state.rowsPerPage, "");
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
    };

    // const onSubmit = () => {
    //     xhrRequest(state.page, state.sortOrder, state.rowsPerPage, search);
    // };
    const onSubmit = (data) => {
        setLoading(true);
        console.log("DATA FORM", data);
        if (data.valid) {
            data.valid = 1;
        }else{
            data.valid = 0;
        }
        loyaltyService
            .addPointRule(data)
            .then((res) => {
                props.HandelSetRule(true);
                toast.success("Add Loyalty point rule successful!");
                setLoading(false);
                setOpen(false);
                history.push("/loyalty/point-rule");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });

    }

    const handleSave = (e) => {
        props.HandelSetRule(rowSelected);
        handleClose(e);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                // startIcon={<CloseIcon />}
                onClick={handleOpen}
                disabled={props.disabled || false}
            >
                Add Rule
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex items-center justify-center"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="modal-custom" style={{ width: "54%" }}>
                        <div className="modal-head flex justify-between items-center">
                            <h2 className="text-xl font-bold">Store</h2>
                            <button onClick={handleClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="modal-content">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* Point name */}
                                <Grid container spacing={2} className="setting-item">
                                    <Grid item xs={3}>
                                        <div className="form-group my-2">
                                            <label className="text-bold label-input w-full input-require">
                                                {'Rule Name'}:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <input
                                            className="p-2 w-full"
                                            disabled={false}
                                            {...register("rule_name", { required: true })}
                                        />
                                    </Grid>
                                </Grid>

                                {/* Point earn rule */}
                                <Grid container spacing={2} className="setting-item">
                                    <Grid item xs={3}>
                                        <div className="form-group my-2">
                                            <label className="text-bold label-input w-full input-require">
                                                {t("MGP_POINT_RULE_DETAIL_POINT_EARN_RULE")}:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {/* Decimal places */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <div className="form-group my-2">
                                                    <label className="label-input w-full">
                                                        {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PLACES")}:
                                                    </label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={0}
                                                        name="earn_decimal_places"
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value={0}>No decimal</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        {/* Decimal precision method */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <div className="form-group my-2">
                                                    <label className="label-input w-full">
                                                        {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PRECISION_METHOD")}:
                                                    </label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={0}
                                                        name="earn_decimal_precision_method"
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value={0}>Round down</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        {/* Spend 1 SGD get */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <div className="form-group my-2">
                                                    <label className="label-input w-full">
                                                        {t("MGP_POINT_RULE_TABLE_COLUMN_SPEND")}
                                                    </label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <input
                                                    type="number"
                                                    className="p-2 w-full"
                                                    disabled={false}
                                                    {...register("earning_rate", {
                                                        required: true,
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item sm={7}>
                                                <div className="flex">
                                                    <div className="addon" style={{ height: "46px", width: "30%" }}>
                                                        {watch("currency")}
                                                    </div>
                                                    <div className="form-group my-2 mx-4">
                                                        <label className="label-input w-full">
                                                            get [1] {watch("points")} ({watch("earning_rate")}{" "}
                                                            {watch("currency")} = 1 {watch("points") || ""})
                                                        </label>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Point redemption rule */}
                                <Grid container spacing={2} className="setting-item">
                                    <Grid item xs={3}>
                                        <div className="form-group my-2">
                                            <label className="text-bold label-input w-full input-require">
                                                {t("MGP_POINT_RULE_DETAIL_POINT_REDEMPTION_RULE")}:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {/* Decimal places */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <div className="form-group my-2">
                                                    <label className="label-input w-full">
                                                        {" "}
                                                        {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PLACES")}:
                                                    </label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={0}
                                                        name="redemption_decimal_places"
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value={0}>No decimal</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        {/* Decimal precision method */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <div className="form-group my-2">
                                                    <label className="label-input w-full">
                                                        {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PRECISION_METHOD")}:
                                                    </label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={0}
                                                        name="redemption_decimal_precision_method"
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value={0}>Round down</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        {/* Spend 1 SGD get */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={3}>
                                                <input
                                                    type="number"
                                                    className="p-2 w-full"
                                                    disabled={false}
                                                    // value="1"
                                                    {...register("point_redemption", {
                                                        required: true,
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item sm={7}>
                                                <div className="flex">
                                                    <div className="addon" style={{ height: "46px", width: "30%" }}>
                                                        {watch("points")} (s)
                                                    </div>
                                                    <div className="form-group my-2 mx-4">
                                                        <label className="label-input w-full">
                                                            for {watch("point_redemption")}{" "}
                                                            {watch("currency") || ""} (1 {watch("points")} ={" "}
                                                            {watch("point_redemption")}{" "}
                                                            {watch("currency") || ""})
                                                        </label>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} className="setting-item">
                                    <Grid item xs={3}>
                                        <div className="form-group my-2">
                                            <label className="text-bold label-input w-full input-require">
                                                Valid:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <Controller
                                            name="valid"
                                            control={control}
                                            render={({field: { onChange, value, ref },}) => {
                                                return (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={value}
                                                                onChange={onChange}
                                                                name="valid"
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} className="setting-item">
                                    <Grid
                                        item
                                        xs={3}
                                    >
                                        <div className="form-group my-2">
                                            <label className="text-bold label-input w-full input-require">
                                                Effective date to:{" "}
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className="flex items-center">
                                            <div className="custom-date-picker">
                                                <MuiPickersUtilsProvider
                                                    utils={MomentUtils}
                                                >
                                                    <KeyboardDatePicker
                                                        inputProps={{ readOnly: true }}
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        id="date-picker-dialog"
                                                        format="DD/MM/yyyy"
                                                        value={
                                                            watch("effective_from")
                                                                ? watch("effective_from")
                                                                : null
                                                        }
                                                        InputAdornmentProps={{
                                                            position: "start",
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setValue(
                                                                            "effective_from",
                                                                            null
                                                                        );
                                                                    }}
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        onChange={(e) =>
                                                            handleDateChange(
                                                                e,
                                                                "effective_from"
                                                            )
                                                        }
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        onClick={() =>
                                                            setPickerStatus({
                                                                ...pickerStatus,
                                                                effectiveToFrom: true,
                                                            })
                                                        }
                                                        onClose={() =>
                                                            setPickerStatus({
                                                                ...pickerStatus,
                                                                effectiveToFrom: false,
                                                            })
                                                        }
                                                        open={pickerStatus.effectiveToFrom}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <ArrowRightAltIcon
                                                style={{ margin: "0 8px" }}
                                            />
                                            <div className="custom-date-picker">
                                                <MuiPickersUtilsProvider
                                                    utils={MomentUtils}
                                                >
                                                    <KeyboardDatePicker
                                                        inputProps={{ readOnly: true }}
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        id="date-picker-dialog"
                                                        format="DD/MM/yyyy"
                                                        value={
                                                            watch("effective_to")
                                                                ? watch("effective_to")
                                                                : null
                                                        }
                                                        onChange={(e) =>
                                                            handleDateChange(
                                                                e,
                                                                "effective_to"
                                                            )
                                                        }
                                                        InputAdornmentProps={{
                                                            position: "start",
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setValue(
                                                                            "effective_to",
                                                                            null
                                                                        );
                                                                    }}
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        onClick={() =>
                                                            setPickerStatus({
                                                                ...pickerStatus,
                                                                effectiveToTo: true,
                                                            })
                                                        }
                                                        onClose={() =>
                                                            setPickerStatus({
                                                                ...pickerStatus,
                                                                effectiveToTo: false,
                                                            })
                                                        }
                                                        open={pickerStatus.effectiveToTo}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                {/* Remarks */}
                                <Grid container spacing={2} className="setting-item">
                                    <Grid item xs={3}>
                                        <div className="form-group my-2">
                                            <label className="text-bold label-input w-full input-require">
                                                {t("MGP_POINT_RULE_TABLE_COLUMN_REMARK")}:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className="form-group my-2">
                        <textarea
                            className="w-full p-2"
                            rows="6"
                            cols="50"
                            disabled={false}
                            {...register("remark")}
                        />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} className="pb-4">
                                    <Grid item xs={3}>
                                        <button
                                            // type="submit"
                                            className="btn-primary-filled w-full mt-2 text-center"
                                            disabled={loading}
                                            onClick={directBack}
                                        >
                                            Cancel
                                        </button>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <input
                                            type="submit"
                                            className="btn-primary-filled w-full mt-2"
                                            disabled={loading}
                                            value="Save"
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default AddPointRuleModal;
