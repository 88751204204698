import React, { Component, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CakeIcon from "@material-ui/icons/Cake";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import MemoryIcon from "@material-ui/icons/Memory";
import ReceiptIcon from "@material-ui/icons/Receipt";
import RedeemIcon from "@material-ui/icons/Redeem";
import SettingsInputSvideoIcon from "@material-ui/icons/SettingsInputSvideo";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ShareIcon from "@material-ui/icons/Share";

import AddModal from "./AddModal";

const TriggerCampaign = () => {
    return (
        <div className="trigger-campaign-page">
            <div className="header-content py-2">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" to="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Trigger campaign</Typography>
                    </Breadcrumbs>
                </div>
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-semibold uppercase">Trigger campaign</h1>
                        <p>Select, set and activate your member's trigger events</p>
                    </div>
                    <div className="flex">
                        <AddModal />
                    </div>
                </div>
            </div>
            <div className="wrapper-paper" style={{ padding: "24px 16px", overflow: "hidden" }}>
                {/*---- Greetings ----*/}
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ padding: 8 }}>
                        <h3 className="title">Greetings</h3>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <AccountBoxIcon />
                            </div>
                            <div className="inf">
                                <h3>Member registration</h3>
                                <p>Welcome your new Members with a message or a reward</p>
                                <div className="flex justify-end">
                                    <Link to="/campaign/trigger/edit">Details</Link>
                                </div>
                            </div>
                            <div className="stt active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <CakeIcon />
                            </div>
                            <div className="inf">
                                <h3>Birthday greeting</h3>
                                <p>
                                    Congratulate your Members with a treat or a friendly note on
                                    their birthday.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/campaign/trigger/edit">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <FavoriteIcon />
                            </div>
                            <div className="inf">
                                <h3>Wedding anniversary</h3>
                                <p>
                                    Congratulate your Members with a treat or a friendly note on
                                    their wedding anniversary.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <CardMembershipIcon />
                            </div>
                            <div className="inf">
                                <h3>Membership anniversary</h3>
                                <p>Congratulate your Members for being loyal to your program.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/*---- Reminder ----*/}
            <div
                className="wrapper-paper mt-8"
                style={{ padding: "24px 16px", overflow: "hidden" }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ padding: 8 }}>
                        <h3 className="title">Reminders</h3>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <AlarmOnIcon />
                            </div>
                            <div className="inf">
                                <h3>Expiring coupons</h3>
                                <p>Remind your Members of a coupon that is expiring soon.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <MonetizationOnIcon />
                            </div>
                            <div className="inf">
                                <h3>Expiring points</h3>
                                <p>Remind your Members of their points that are expiring soon.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <MemoryIcon />
                            </div>
                            <div className="inf">
                                <h3>Expiring membership</h3>
                                <p>Wake your Members up before their membership expire.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <TrendingDownIcon />
                            </div>
                            <div className="inf">
                                <h3>Downgrading tier</h3>
                                <p>Congratulate your Members for being loyal to your program.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <ExitToAppIcon />
                            </div>
                            <div className="inf">
                                <h3>Registration reminder</h3>
                                <p>Remind your Members to fill in their personal information.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <LockOpenIcon />
                            </div>
                            <div className="inf">
                                <h3>Registration confirmation</h3>
                                <p>
                                    Send you newly registered Members an email to confirm the
                                    registration and avoid fraud.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <DynamicFeedIcon />
                            </div>
                            <div className="inf">
                                <h3>Consent update</h3>
                                <p>
                                    Confirm membership and preferences to comply with Personal Data
                                    regulations.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/*---- Notifications ----*/}
            <div
                className="wrapper-paper mt-8"
                style={{ padding: "24px 16px", overflow: "hidden" }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ padding: 8 }}>
                        <h3 className="title">Notifications</h3>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <RedeemIcon />
                            </div>
                            <div className="inf">
                                <h3>Points redemption</h3>
                                <p>Notify your Members when they redeem points.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <SettingsInputSvideoIcon />
                            </div>
                            <div className="inf">
                                <h3>Earned points</h3>
                                <p>Notify your Members when they earn points.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <ReceiptIcon />
                            </div>
                            <div className="inf">
                                <h3>Transaction confirmation</h3>
                                <p>Confirm your Members when they make a purchase.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <TrendingDownIcon />
                            </div>
                            <div className="inf">
                                <h3>Tier downgrade</h3>
                                <p>Notify your Members when they demote to a lower tier.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <TrendingUpIcon />
                            </div>
                            <div className="inf">
                                <h3>Tier upgrade</h3>
                                <p>Notify your Members when they reach a higher tier.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <ConfirmationNumberIcon />
                            </div>
                            <div className="inf">
                                <h3>Expired membership</h3>
                                <p>Notify your Members when their Membership has expired.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <MonetizationOnIcon />
                            </div>
                            <div className="inf">
                                <h3>Expired points</h3>
                                <p>Inform your Members when their points have expired.</p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <ShareIcon />
                            </div>
                            <div className="inf">
                                <h3>Share credentials</h3>
                                <p>
                                    Send your Members their default login credentials when they
                                    register via different channels.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Active</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default TriggerCampaign;
