import { api } from "../utils/axios.configs";
import { REACT_APP_API_URL } from "../constants/app";

export const loginService = (data) => {
    return api.post(REACT_APP_API_URL + "auth/login", data);
};

export const loginStoreService = (data) => {
    return api.post(REACT_APP_API_URL + "auth/store/login", data);
};

export const userProfileService = (id) => {
    return api.get(REACT_APP_API_URL + `user`);
};

export const selectStoreService = (user_id) => {
    return api.get(REACT_APP_API_URL + `store/${user_id}`);
};

export const postStoreService = (model) => {
    // user_id, store_id
    return api.post(REACT_APP_API_URL + `auth/store/login`, model);
};
