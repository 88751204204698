import AppConstant from "../../constants/app";
import { clearToken, getToken } from "../../utils/actionUtil";
const actions = {
    LOGIN: "LOGIN",
    LOGIN_STORE: "LOGIN_STORE",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_ERROR: "LOGIN_ERROR",
    GET_USER_PROFILE: "GET_USER_PROFILE",
    GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
    GET_USER_PROFILE_ERROR: "GET_USER_PROFILE_ERROR",

    login: (model) => {
        return {
            type: actions.LOGIN,
            payload: model,
        };
    },

    loginStore: (model) => {
        return {
            type: actions.LOGIN_STORE,
            payload: model,
        };
    },

    verifyToken: () => {
        const token = getToken();
        // Update application state. User has token and is probably authenticated
        if (token) {
            return {
                type: actions.LOGIN_SUCCESS,
                payload: token,
            };
        } else {
            return {
                type: actions.LOGIN_ERROR,
                payload: token,
            };
        }
    },

    logout: () => {
        clearToken();
        window.location.reload();
    },

    getUserProfile: (model) => {
        return {
            type: actions.GET_USER_PROFILE,
            payload: model,
        };
    },
};
export default actions;
