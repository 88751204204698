import React, { useEffect, useState } from "react";
import { CircularProgress, Button, Grid, Modal, Backdrop, Fade } from "@material-ui/core";
import * as storeService from "../../../../services/store";
import CloseIcon from "@material-ui/icons/Close";
import MUIDataTable from "mui-datatables";
import SearchIcon from "@material-ui/icons/Search";
import { useForm } from "react-hook-form";
// import actionCoupon from "../../../redux/Coupon/actions";
import { useDispatch, useSelector } from "react-redux";

const StoreModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [rowSelected, setRowSelected] = useState([]);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        selectedRow: [],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "StoreName",
                label: "Store Name",
            },
            {
                name: "Address",
                label: "Address",
            },
            {
                name: "City",
                label: "City",
            },
        ],
        isLoading: false,
    });

    const [search, setSearch] = useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
    };

    useEffect(() => {
        getData(0);
    }, []);

    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        const dataFiltered = dataStoreSelected.map((item) =>
            res.data.data.data.findIndex((x) => x.Id === item.Id)
        );
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
            selectedRow: dataFiltered,
        });
    };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }

        if (search) {
            model.keyword = search;
        }
        setState({ ...state, page: page + 1 });
        return storeService.getStoreService(model);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage, selectedRow } = state;
    const handleRowSelected = (currentRowsSelected, allRowsSelected) => {
        const filteredData = allRowsSelected.map((item) => data[item.dataIndex]);
        setRowSelected(filteredData);
        const arrIndex = allRowsSelected.map((item) => item.dataIndex);
        setState({ ...state, selectedRow: arrIndex });
    };

    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "multiple",
        rowsSelected: selectedRow,
        customToolbarSelect: () => {},

        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        onRowSelectionChange: handleRowSelected,
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(state.sortOrder, numberOfRows, ""),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, tableState.sortOrder, state.rowsPerPage, "");
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
    };

    const onSubmit = () => {
        xhrRequest(state.page, state.sortOrder, state.rowsPerPage, search);
    };

    const handleSave = (e) => {
        props.HandelSetStore(rowSelected);
        handleClose(e);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                // startIcon={<CloseIcon />}
                onClick={handleOpen}
                disabled={props.disabled || false}
            >
                Add Store
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex items-center justify-center"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="modal-custom" style={{ width: "54%" }}>
                        <div className="modal-head flex justify-between items-center">
                            <h2 className="text-xl font-bold">Store</h2>
                            <button onClick={handleClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="modal-content">
                            <div
                                className="wrapper-table w-full"
                                style={{ boxShadow: "none", padding: 8 }}
                            >
                                <div className="search-table flex items-center">
                                    <input
                                        className="p-2 w-5/12 mr-3"
                                        placeholder="Search..."
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        style={{ minHeight: 42 }}
                                        startIcon={<SearchIcon />}
                                        value="Search"
                                        onClick={(e) => {
                                            onSubmit();
                                        }}
                                    >
                                        Search
                                    </Button>
                                    {isLoading && (
                                        <CircularProgress
                                            size={24}
                                            style={{
                                                marginLeft: 15,
                                                position: "relative",
                                                top: 4,
                                            }}
                                        />
                                    )}
                                </div>
                                <MUIDataTable
                                    data={data}
                                    columns={state.columns}
                                    options={options}
                                />
                            </div>
                            <div className="flex justify-end w-full">
                                <div className="w-6/12 flex mt-4 justify-end">
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            style={{ position: "relative", top: 16, right: 18 }}
                                        />
                                    )}
                                    <button
                                        // type="submit"
                                        className="btn-primary-filled mt-2 text-center w-5/12"
                                        disabled={loading}
                                        onClick={handleClose}
                                    >
                                        {" "}
                                        Cancel
                                    </button>

                                    <button
                                        className="btn-primary-filled mt-2 ml-3 w-5/12"
                                        disabled={loading}
                                        onClick={handleSave}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default StoreModal;
