import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getPRStoreIdService = (id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_STORE}/${id}`;
    return api.get(url);
};
export const addPRStoreService = (model) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_STORE}`;
    return api.post(url, model);
};
export const deleteStoreService = (model, id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_STORE}/${id}/delete`;
    return api.post(url, model);
};