import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input-field";

function Quantity(props) {
    const [value, setvalue] = useState(props.value ? props.value : 0);
    const decrement = (e) => {
        e.preventDefault();
        if (props.onChange) {
            props.type == "currency"
                ? props.onChange(
                      value -
                          (process.env.REACT_APP_MIN_VALUE_CURRENCY
                              ? parseInt(process.env.REACT_APP_MIN_VALUE_CURRENCY)
                              : 1)
                  )
                : props.onChange(value - 1);
        }
        setvalue(value - 1);
    };
    const increment = (e) => {
        e.preventDefault();
        if (props.onChange) {
            props.type == "currency"
                ? props.onChange(
                      value +
                          (process.env.REACT_APP_MIN_VALUE_CURRENCY
                              ? parseInt(process.env.REACT_APP_MIN_VALUE_CURRENCY)
                              : 1)
                  )
                : props.onChange(value + 1);
        }
        setvalue(value + 1);
    };

    useEffect(() => {
        setvalue(props.value ? props.value : value);
    }, [props.value]);

    const onChangeValue = (value) => {
        setvalue(value);
        props.onChange(value);
    };
    return (
        <div className="quantity-box flex w-full">
            <button
                onClick={decrement}
                disabled={value <= 0}
                className={`${props.isDisabled ? "d-none" : ""}`}
            >
                -
            </button>
            {props.type == "currency" ? (
                <CurrencyInput
                    className="font-semibold p-2 w-full text-center"
                    id="input-example"
                    name="input-name"
                    placeholder="Please enter a number"
                    value={value}
                    decimalsLimit={0}
                    onValueChange={(value, name) =>
                        value != 0 && value ? onChangeValue(parseInt(value)) : setvalue(0)
                    }
                />
            ) : (
                <input
                    type="number"
                    value={value}
                    onChange={(e) =>
                        e.target.value != 0 ? onChangeValue(parseInt(e.target.value)) : setvalue(0)
                    }
                    className="font-semibold p-2 w-full text-center"
                />
            )}
            <button onClick={increment} className={`${props.isDisabled ? "d-none" : ""}`}>
                +
            </button>
        </div>
    );
}

Quantity.propTypes = {
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
};

export default Quantity;
