import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getVoucherService = (model) => {
    var url = `${ApiRouters.VOUCHER}`;
    return api.get(url, { params: model });
};

export const addVoucherService = (model) => {
    var url = `${ApiRouters.VOUCHER}`;
    return api.post(url, model, {
        onUploadProgress: (ProgressEvent) => {
            console.log(
                "Upload Progress: " +
                    Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
                    "%"
            );
        },
    });
};

export const getAllVoucherCategoryService = () => {
    var url = `${ApiRouters.ALL_VOUCHER_CATEGORY}`;
    return api.get(url);
};

export const getVoucherCategoryService = (model) => {
    var url = `${ApiRouters.VOUCHER_CATEGORY}`;
    return api.get(url, { params: model });
};

export const addVoucherCategoryService = (model) => {
    var url = `${ApiRouters.ALL_VOUCHER_CATEGORY}`;
    return api.post(url, model);
};

export const updateVoucherCategoryService = (model, id) => {
    var url = `${ApiRouters.ALL_VOUCHER_CATEGORY}/${id}?_method=PUT`;
    return api.post(url, model);
};

export const deleteVoucherCategoryService = (id) => {
    var url = `${ApiRouters.ALL_VOUCHER_CATEGORY}/${id}`;
    return api.delete(url);
};

export const deleteVoucherService = (model) => {
    var url = `${ApiRouters.VOUCHER}/delete`;
    return api.put(url, model);
};

export const detailVoucherService = (id) => {
    var url = `${ApiRouters.VOUCHER}/${id}`;
    return api.get(url);
};

export const updateVoucherService = (id, model) => {
    var url = `${ApiRouters.VOUCHER}/${id}?_method=PUT `;
    return api.post(url, model);
};
