import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Typography,
    Button,
    Grid,
    Modal,
    Backdrop,
    Fade,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    TextField,
    createTheme,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { api } from "../../../../utils/axios.configs";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as memberService from "../../../../services/member";
import Pagination from "@material-ui/lab/Pagination";
import { PagesOutlined } from "@material-ui/icons";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import DatePickerGroupSelect from "../../../../components/DatePickerGroupSelect";
import { toast } from "react-toastify";
import EarnPointModal from "./earnPointModal";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CurrencyInput from "react-currency-input-field";
import { moneyFormatter } from "../../../../utils/converter";
import { pointType } from "../../../../constants/app";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";

const AccumulateTable = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [pickerStatus, setPickerStatus] = useState(false);
    const { id } = useParams();
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 5,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [["Loading Data..."]],
        columns: [
            {
                name: "PointTypeId",
                label: "PointType",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "TransactionDate",
                label: t("MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_DATE"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span className="whitespace-no-wrap" style={{ whiteSpace: "nowrap" }}>
                                {value || "-"}
                            </span>
                        );
                    },
                },
            },
            {
                name: "earnPoint",
                options: {
                    filter: true,
                    sort: false,
                    empty: true,
                    setCellProps: () => ({
                        align: "center",
                    }),
                    // setCellHeaderProps: (value) => ({
                    //     className: "centeredHeaderCell",
                    // }),
                    customHeadLabelRender: () => null,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const stt = tableMeta.rowData[0];
                        return (
                            <div
                                className={`flex ${
                                    stt && stt == pointType.EARNED ? "green-point" : "red-point"
                                } items-center`}
                            >
                                {stt && stt == pointType.EARNED ? (
                                    <AddIcon
                                        style={{
                                            color: "green",
                                            marginRight: 8,
                                            fontSize: "1.3em",
                                        }}
                                    />
                                ) : (
                                    <RemoveIcon
                                        style={{ color: "red", marginRight: 8, fontSize: "1.3em" }}
                                    />
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                name: "Point",
                label: t("MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_POINT"),
                // align: "center",
                options: {
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const stt = tableMeta.rowData[0];
                        return (
                            <div
                                className={`flex ${
                                    stt && stt == pointType.EARNED ? "green-point" : "red-point"
                                } items-center`}
                            >
                                <span>{value ? value : "-"}</span>
                            </div>
                        );
                    },
                },
            },
            {
                name: "InvoiceNo",
                label: t("MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_BILL_NO"),
                options: {
                    align: "center",
                },
            },
            {
                name: "Amount",
                label: t("MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_AMOUNT"),
                options: {
                    filter: true,
                    sort: true,
                    align: "right",
                    setCellProps: () => ({
                        align: "right",
                    }),
                    setCellHeaderProps: (value) => ({
                        className: "rightHeaderCell",
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span className="flex justify-end">
                                {value ? moneyFormatter(value) : "-"}
                            </span>
                        );
                    },
                },
            },
            {
                name: "StoreName",
                label: t("MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_STORE"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value || "-"}</span>;
                    },
                },
            },
            {
                name: "ChannelName",
                label: t("MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_CHANNELS"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value || "-"}</span>;
                    },
                },
            },
            {
                name: "CampaignName",
                label: t("MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_CAMPAIGN"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value || "-"}</span>;
                    },
                },
            },
        ],
        isLoading: false,
    });
    const history = useHistory();
    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
        });
    };
    const Loadmore = () => {
        xhrRequest(0, state.sortOrder, state.rowsPerPage + 10, query).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: state.rowsPerPage + 10,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
        });}
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            member_id: id,
            invoice_no: "",
            amount: "",
            point: "",
            transaction_date: moment(),
            comment: "",
        },
    });

    useEffect(() => {
        getData();
    }, []);

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        const model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };
        if (search) {
            model.keyword = search;
        }

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        }
        // setState({ ...state, page: page + 1 });
        return memberService.getListMemberAccmulateService(model, id);
        return {};
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    name: column,
                    direction: order,
                },
            });
        });
    };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;
    const options = {
        responsive: "scrollMaxHeight",
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        selectableRows: "none",
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 20],
        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
            console.log("changedColumn: ", changedColumn, "direction: ", direction);
        },
        onChangeRowsPerPage: (numberOfRows) =>
            changeRowsPerPage(state.sortOrder, numberOfRows, state.search),
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(
                        tableState.page,
                        tableState.sortOrder,
                        state.rowsPerPage,
                        state.search
                    );
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                    break;
                // console.log("action not handled.");
            }
        },

        customFooter: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage
        ) => {

            return  state.rowsPerPage < state.count && <div className="flex" style={{padding: 4}}> <button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" ariant="contained"
                                                                                      size="medium"
                                                                                      color="primary"
                                                                                      style={{ width: "20%" }} onClick={Loadmore}>
                Load More
                </button>
            </div>;
        }
    };

    const handleDateChange = (e) => {
        setSelectedDate(e);
        setValue("transaction_date", e);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        reset();
        setOpen(false);
    };

    const onSubmit = (data) => {
        // console.log("DATA FORM", data);
        setLoading(true);
        data.transaction_date = data.transaction_date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        memberService
            .transactionSpendPoint(data, id)
            .then((res) => {
                if (res.data.success) {
                    toast.success("point redeemption successful!");
                    setLoading(false);
                    getData();
                    reset();
                    setOpen(false);
                    props.getDataDetail();
                } else {
                    toast.error(res.data.message);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                        color: "red",
                    },
                },
            },
        });

    return (
        <div>
            <div className="header-info flex justify-between mt-14">
                <h3 className="text-xl font-semibold uppercase">
                    {t("MGP_MEMBER_DETAIL_EARN_REDEEM")}
                </h3>
                <div className="flex">
                    <EarnPointModal
                        currentPoint={props.currentPoint}
                        getDataList={getData}
                        getDataDetail={props.getDataDetail}
                        earnRate={props.earnRate}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        style={{ marginLeft: 8 }}
                        // startIcon={<SaveIcon />}
                        onClick={handleOpen}
                    >
                        {t("MGP_MEMBER_DETAIL_REDEEM")}
                    </Button>
                </div>
            </div>

            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable data={data} columns={state.columns} options={options} />
            </MuiThemeProvider>

            {/* <Typography>Page: {state.page + 1}</Typography>
            <Pagination count={lastPage} page={state.page + 1} onChange={handleChange} /> */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex items-center justify-center"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="modal-custom">
                        <div className="modal-head flex justify-between items-center">
                            <h2 className="text-xl font-bold">
                                {t("MGP_MEMBER_DETAIL_MODAL_ACCUMULATE_POINT_REDEEM")}
                            </h2>
                            <button onClick={handleClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="modal-content flex justify-between items-center">
                            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                                <Grid container spacing={2} className="py-6">
                                    <Grid item xs={12}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_DATE"
                                                )}
                                                :{" "}
                                            </label>
                                            {/* <div className="custom-date-picker">
                                                <DatePickerGroupSelect
                                                    handleDateChange={handleDateChange}
                                                />
                                            </div> */}
                                            <div className="custom-date-picker add-padding">
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        inputProps={{ readOnly: true }}
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        id="date-picker-dialog"
                                                        format="DD/MM/yyyy"
                                                        value={
                                                            selectedDate
                                                                ? moment(selectedDate)
                                                                : moment()
                                                        }
                                                        onChange={handleDateChange}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        onClick={() => setPickerStatus(true)}
                                                        onClose={() => setPickerStatus(false)}
                                                        open={pickerStatus}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_BILL_NO"
                                                )}
                                                :{" "}
                                            </label>
                                            <input
                                                className="p-2 w-full"
                                                {...register("invoice_no", {
                                                    required: true,
                                                })}
                                            />
                                            {errors.invoice_no && (
                                                <span className="text-error">
                                                    This field is required
                                                </span>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_AMOUNT"
                                                )}
                                                :{" "}
                                            </label>
                                            {/* <input
                                                className="p-2 w-full"
                                                type="number"
                                                {...register("amount", {
                                                    required: true,
                                                })}
                                            /> */}
                                            <Controller
                                                name="amount"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    // validate: (value) => isValidPhoneNumber(value),
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <CurrencyInput
                                                        id="input-example"
                                                        name="amount"
                                                        className="p-2 w-full"
                                                        decimalsLimit={2}
                                                        onValueChange={onChange}
                                                    />
                                                )}
                                            />
                                            {errors.amount && (
                                                <span className="text-error">
                                                    This field is required
                                                </span>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_MODAL_ACCUMULATE_AVAILABLE_POINT"
                                                )}
                                                :
                                            </label>
                                            <input
                                                className="p-2 w-full"
                                                type="number"
                                                disabled={true}
                                                value={props.currentPoint}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_POINT"
                                                )}
                                                :{" "}
                                            </label>
                                            <input
                                                className="p-2 w-full"
                                                type="number"
                                                {...register("point", {
                                                    required: true,
                                                })}
                                            />
                                            {errors.point && (
                                                <span className="text-error">
                                                    This field is required
                                                </span>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input">
                                                {t("MGP_COMMON_TABLE_ACTION_COMMENT")}:{" "}
                                            </label>
                                            <textarea
                                                id="w3review"
                                                name="w3review"
                                                rows="4"
                                                cols="50"
                                                {...register("comment")}
                                            ></textarea>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="flex justify-end w-full">
                                    <div className="w-6/12 flex mt-4">
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                style={{ position: "relative", top: 16, right: 18 }}
                                            />
                                        )}
                                        <button
                                            // type="submit"
                                            className="btn-primary-filled mt-2 text-center w-6/12"
                                            disabled={loading}
                                            onClick={handleClose}
                                        >
                                            {" "}
                                            {t("MGP_COMMON_LABEL_CANCEL")}
                                        </button>

                                        <input
                                            type="submit"
                                            className="btn-primary-filled mt-2 ml-3 w-6/12"
                                            disabled={loading}
                                            value={t("MGP_COMMON_LABEL_SAVE")}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default AccumulateTable;
