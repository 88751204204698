import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as couponService from "../../../services/coupon";
import AvatarImg from "../../../assets/images/avatar-example.png";
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { useQueryParams, StringParam } from "use-query-params";
import SearchIcon from "@material-ui/icons/Search";
import { useForm, Controller } from "react-hook-form";
import CachedIcon from "@material-ui/icons/Cached";
import { Button, Grid, CircularProgress, FormControl, NativeSelect } from "@material-ui/core";
import { couponType, validityPeriod } from "../../../constants/app";
import * as commonService from "../../../services/common";

const PastTable = forwardRef((props, ref) => {
    const history = useHistory();
    const [rowSelected, setRowSelected] = useState([]);
    const [dataChannel, setDataChannel] = useState([]);
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        selectedRow: [],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "CouponCode",
                label: "Campaign Name",
            },
            {
                name: "CouponName",
                label: "Reward type",
            },
            {
                name: "CouponType",
                label: "Estimated audience",
            },
            {
                name: "ChannelName",
                label: "Send date",
            },
            {
                name: "EffectiveDateBegin",
                label: "Channel",
            },
            {
                name: "Delete",
                label: "Operation",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(`/campaign/coupon/${tableMeta.rowData[0]}`);
                                    }}
                                >
                                    View
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%", marginLeft: 8 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(
                                            `/campaign/coupon/edit/${tableMeta.rowData[0]}`
                                        );
                                    }}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%", margin: "0 8px" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(
                                            `/campaign/coupon/copy/${tableMeta.rowData[0]}`
                                        );
                                    }}
                                >
                                    Copy
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteCoupon(tableMeta.rowData[0]);
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        );
                    },
                },
            },
        ],
        isLoading: false,
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
        });
    };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }
        // setState({ ...state, page: page + 1 });
        return couponService.getListCouponService(model);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };

    // const deleteCoupon = (id) => {
    //     couponService.deleteCoupon({ list_of_id: [id] }).then((res) =>
    //         setState({
    //             ...state,
    //             isLoading: false,
    //             sortOrder,
    //             data: res.data.data.data,
    //             search: state.search,
    //             lastPage: res.data.data.last_page,
    //             count: res.data.data.total,
    //         })
    //     );
    // };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;

    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "none",
        customToolbarSelect: () => {},
        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(state.sortOrder, numberOfRows, ""),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, tableState.sortOrder, state.rowsPerPage, "");
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
    };

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTableSelectCell: {
                    fixedLeft: {
                        position: "relative",
                    },
                },
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                    },
                },
            },
        });

    return (
        <div className="wrapper-table">
            <MuiThemeProvider theme={customTheme()}>
                <h3 className="title-table">Past</h3>
                <MUIDataTable data={[]} columns={state.columns} options={options} />
            </MuiThemeProvider>
        </div>
    );
});

export default PastTable;
