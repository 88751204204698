import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import {
    Checkbox,
    FormControlLabel,
    FormControl,
    CircularProgress,
    NativeSelect,
    Breadcrumbs,
    Link,
    Typography,
} from "@material-ui/core";
import * as couponService from "../../services/coupon";
import * as commonService from "../../services/common";
import commonAction from "../../redux/Common/actions";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import Quantity from "../../components/Quantity";
import StoreAddFormTable from "./Table/StoreAddForm";
import StoreModal from "./StoreModal";
import { couponType, validityPeriod } from "../../constants/app";
import DaterangePicker from "../../components/DaterangePicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function Edit(props) {
    const [loading, setLoading] = useState(false);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const [data, setData] = useState();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            // applicable_stores: "",
            coupon_code_auto: "",
            amount: 0,
            start_from: 0,
            channel_id: 1,
            validity_period_type: 0,
            coupon_type: 1,
            redemption_conditions: 0,
        },
    });
    const [codeGenerate, setCodeGenerate] = useState(false);

    const [address, setAddress] = useState({
        province: "",
        district: "",
    });
    const [dataChannel, setDataChannel] = useState([]);

    useEffect(() => {
        getDataChannel();
        getData();
    }, []);

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        couponService
            .detailCoupon(id)
            .then((res) => {
                if (res.data.data) {
                    const resp = res.data.data;
                    setData(resp);
                    setValue("coupon_name", resp?.CouponName || "");
                    setValue("channel_id", resp?.ChannelId || 1);
                    setValue("coupon_type", resp?.CouponType || 0);
                    setValue("amount", resp?.Amount);
                    setValue("discount", resp?.Discount);
                    setValue("redemption_conditions", resp?.MinimumPurchase || undefined);
                    setValue("validity_period_type", resp?.ValidityPeriodType || 0);
                    if (resp?.ValidityPeriodType == 0) {
                        setValue("start_from", resp?.StartFrom);
                        setValue("end_to", resp?.EndTo);
                    }

                    if (resp?.ValidityPeriodType == 1) {
                        setValue("specific_date_from", resp?.EffectiveDateBegin);
                        setValue("specific_date_to", resp?.EffectiveDateEnd);
                    }

                    setValue("term_and_conditions", resp?.TermAndConditions || "");
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const onSubmit = (data) => {
        setLoading(true);
        // if (dataStoreSelected.length > 0) {
        //     data.applicable_stores = dataStoreSelected.map((obj) => obj.Id);
        // }

        if (data.coupon_code_auto && data.coupon_code == "") {
            data.coupon_code = data.coupon_code_auto;
            delete data.coupon_code_auto;
        } else {
            delete data.coupon_code_auto;
        }

        if (data.coupon_type == 0) {
            delete data.discount;
        } else {
            delete data.amount;
        }

        console.log("DATA FORM", data);
        couponService
            .addCoupon(data)
            .then((res) => {
                toast.success("Add coupon successful!");
                setLoading(false);
                history.push("/campaign/coupon");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleGenerateCode = (e) => {
        setCodeGenerate(e.target.checked);
        if (e.target.checked == true) {
            // couponService
            //     .getGenerateCode()
            //     .then((res) => setValue("coupon_code_auto", res.data.data))
            //     .catch((err) => console.log(err));
            setValue("coupon_code", "");
        } else {
            setValue("coupon_code", "");
        }
    };

    const directBack = (e) => {
        e.preventDefault();
        history.push("/campaign/coupon");
    };

    const handleChangeDaterange = (range) => {
        setValue("specific_date_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("specific_date_to", moment(range.endDate).format("YYYY-MM-DD"));
    };

    return (
        <div className="member-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            Home
                        </Link>
                        <Link color="inherit" href="/#/campaign/coupon">
                            Coupon
                        </Link>
                        <Typography color="textPrimary">Add coupon</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">Add Coupon</h1>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Coupon code:{" "}
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("coupon_code")}
                                            disabled={codeGenerate}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="form-group  h-full">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={codeGenerate}
                                                        onChange={handleGenerateCode}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Auto generated member code"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Coupon name:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("coupon_name", { required: true })}
                                        />
                                        {errors.coupon_name && (
                                            <span className="text-error">
                                                This field is required
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Channel:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="channel_id"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="channel_id"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {dataChannel.map((item, index) => (
                                                            <option value={item.Id} key={index}>
                                                                {item.ChannelName}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Coupon type:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} className="flex items-center">
                                        <Controller
                                            name="coupon_type"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="jobTitle_id"
                                                        inputProps={{ "aria-label": "province" }}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {couponType.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            {watch("coupon_type") == 0
                                                ? "Cash amount:"
                                                : "Discount rate"}
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} className="flex items-center">
                                        {watch("coupon_type") == 0 && (
                                            <Controller
                                                name="amount"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => {
                                                    return (
                                                        <Quantity
                                                            type="currency"
                                                            onChange={onChange}
                                                            value={value}
                                                            name="amount"
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                        {watch("coupon_type") == 1 && (
                                            <Controller
                                                name="discount"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => {
                                                    return (
                                                        <Quantity
                                                            onChange={onChange}
                                                            value={value}
                                                            name="discount"
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <p className="flex items-center ml-1">
                                        {watch("coupon_type") == 0 ? "" : "%"}
                                    </p>
                                </Grid>
                            </div>
                        </Grid>
                        {/* <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex">
                                        <label className="text-semibold label-input input-require mt-2">
                                            Applicable to specific store(s):
                                        </label>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div>
                                            <StoreModal />
                                            <StoreAddFormTable />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid> */}
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input">
                                            Redemption conditions:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} className="flex items-center">
                                        <label className="mr-4 whitespace-nowrap ">
                                            Minimum purchase of
                                        </label>
                                        <Controller
                                            name="redemption_conditions"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => {
                                                return (
                                                    <Quantity
                                                        onChange={onChange}
                                                        value={value}
                                                        name="redemption_conditions"
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex">
                                        <label className="text-semibold label-input mt-2">
                                            Validity period:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="validity_period_type"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="jobTitle_id"
                                                        inputProps={{ "aria-label": "province" }}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {validityPeriod.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                        {watch("validity_period_type") == 0 ? (
                                            <div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            Start from:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="start_from"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after reception
                                                    </label>
                                                </div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            End to:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="end_to"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after effective
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-4">
                                                {data?.EffectiveDateBegin &&
                                                data?.EffectiveDateEnd ? (
                                                    <DaterangePicker
                                                        onChange={handleChangeDaterange}
                                                        defaultValue={{
                                                            date: {
                                                                specific_date_from:
                                                                    data?.EffectiveDateBegin,
                                                                specific_date_to:
                                                                    data?.EffectiveDateEnd,
                                                            },
                                                        }}
                                                    />
                                                ) : (
                                                    <DaterangePicker
                                                        onChange={handleChangeDaterange}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input input-require">
                                    terms & conditions:
                                </label>
                                <textarea
                                    id="w3review"
                                    name="w3review"
                                    rows="4"
                                    cols="50"
                                    {...register("term_and_conditions")}
                                ></textarea>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="pb-4">
                        <Grid item xs={3}>
                            <button
                                // type="submit"
                                className="btn-primary-filled w-full mt-2 text-center"
                                disabled={loading}
                                onClick={directBack}
                            >
                                Cancel
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <input
                                type="submit"
                                className="btn-primary-filled w-full mt-2"
                                disabled={loading}
                                value="Save"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    style={{ position: "relative", top: 16 }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default Edit;
