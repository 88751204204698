import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Typography,
    Button,
    Grid,
    Modal,
    Backdrop,
    Fade,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    TextField,
    createTheme,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";

import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "@material-ui/lab/Pagination";
import { PagesOutlined } from "@material-ui/icons";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CurrencyInput from "react-currency-input-field";
import { moneyFormatter } from "../../../../../utils/converter";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import * as prCategoryService from "../../../../../services/PRCategory";
import {pointType} from "../../../../../constants/app";
import ProductCategoryModal from "../../Modal/ProductCategoryModal";
import {getPRCategoryIdService} from "../../../../../services/PRCategory";
import * as prStoreService from "../../../../../services/PRStore";

const AccumulateTable = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [productCate, setProductCate] = useState([])
    const { id_url } = useParams();
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 5,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [["Loading Data..."]],
        columns: [
            {
                name: "Id",
                label: "Id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "Name",
                label: 'name',
                options: {
                    align: "center",
                },
            },
            {
                name: "ParentId",
                label: 'parent name',
            },
            {
                name: "Description",
                label: 'Description',
            },
            {
                name: "Remove",
                label: "OPERATION",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteCategory(tableMeta.rowData[0]);
                                    }}
                                >
                                    Remove
                                </Button>
                            </div>
                        );
                    },
                },
            },
        ],
        isLoading: false,
    });
    const history = useHistory();
    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
        });
    };
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            member_id: id_url,
            invoice_no: "",
            amount: "",
            point: "",
            transaction_date: moment(),
            comment: "",
        },
    });

    useEffect(() => {
        getData();
    }, []);

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        const model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };
        if (search) {
            model.keyword = search;
        }

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        }

        return prCategoryService.getPRCategoryIdService(id_url);
        return {};
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const deleteCategory = (id) => {
        if(id) {
            const data = [];
            data.push(id);
            prCategoryService.deletePRCategoryService({ list_of_id: data}, id_url).then((res) =>
                {
                    getData();
                }
            ).catch((err) => console.log(err));

        }

    };
    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    name: column,
                    direction: order,
                },
            });
        });
    };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;
    const options = {
        responsive: "scrollMaxHeight",
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        selectableRows: "none",
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 20],
        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
            console.log("changedColumn: ", changedColumn, "direction: ", direction);
        },
        onChangeRowsPerPage: (numberOfRows) =>
            changeRowsPerPage(state.sortOrder, numberOfRows, state.search),
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(
                        tableState.page,
                        tableState.sortOrder,
                        state.rowsPerPage,
                        state.search
                    );
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                    break;
                // console.log("action not handled.");
            }
        },
    };
    const HandelSetProductCate = (productCategory) => {
        if (productCategory.length > 0) {
            const arrId = productCategory.map((item) => item.Id);
            const modal = {
                list_of_id: arrId,
                point_rule_id: id_url,
            };
            addCategory(modal);
        }
        setProductCate(productCategory);



    }
    const handleDateChange = (e) => {
        setSelectedDate(e);
        setValue("transaction_date", e);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        reset();
        setOpen(false);
    };

    const onSubmit = (data) => {
        // console.log("DATA FORM", data);
        setLoading(true);
        data.transaction_date = data.transaction_date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        memberService
            .transactionSpendPoint(data, id)
            .then((res) => {
                if (res.data.success) {
                    toast.success("point redeemption successful!");
                    setLoading(false);
                    getData();
                    reset();
                    setOpen(false);
                    props.getDataDetail();
                } else {
                    toast.error(res.data.message);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const addCategory = (model) => {
        prCategoryService.addPRCategoryService(model)
            .then((res) => {
                if (res.data.success) {
                    getData();
                } else {
                    toast.error(res.data.message);
                    setLoading(false);
                }
            })
        .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                        color: "red",
                    },
                },
            },
        });

    return (
        <div>
            <div className="header-info flex justify-between mt-14">
                <h3 className="text-xl font-semibold uppercase">
                    Product Category
                </h3>
                <div className="flex">
                    <ProductCategoryModal HandelSetProductCate = {HandelSetProductCate}/>
                </div>
            </div>

            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable data={data} columns={state.columns} options={options} />
            </MuiThemeProvider>
        </div>
    );
};

export default AccumulateTable;
