import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    Breadcrumbs,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useState } from "react";
import HeaderPage from "../../../components/Header/HeaderPage";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import * as loyaltyService from "../../../services/loyalty";
import commonAction from "../../../redux/Common/actions";
import { useTranslation } from "react-i18next";

function TierRule() {
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState();
    const dispatch = useDispatch();

    function backToDashboard() {
        history.push("/dashboard");
    }

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        loyaltyService
            .getTierRule()
            .then((res) => {
                if (res.data.success) {
                    setData(res.data.data);
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };
    return (
        <div className="loyalty-tier-rule-page">
            <HeaderPage extraClass="flex-end">
                <div className="header-left">
                    <Breadcrumbs className="breadcrumb-wrapper" aria-label="breadcrumb">
                        <Typography color="inherit" className="active" onClick={backToDashboard}>
                            {t("MGP_SIDEBAR_LABEL_HOME")}
                        </Typography>
                        <Typography color="textPrimary">{t("MGP_TIER_RULE_TITLE")}</Typography>
                    </Breadcrumbs>
                    <p className="header-title uppercase mt-2">{t("MGP_TIER_RULE_TITLE")}</p>
                </div>
                {/* <div className="header-right">
                    <button className="request-help-btn btn-primary-filled">
                        Request for Edenred help
                    </button>
                </div> */}
            </HeaderPage>
            {data &&
                _.map(data, (item, index) => (
                    <div className="tier-rule-info mb-4">
                        <Accordion defaultExpanded={true} key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                                aria-controls="panel-silver-content"
                                id="panel-silver-header"
                                className="tier-rule-accordion"
                            >
                                <Typography className="tier-rule-accordion-title">
                                    {item?.TierName || "-"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="tier-rule-accordion-details">
                                <Grid container className="info-item">
                                    <Grid item xs={3} className="info-item__title">
                                        <p>{t("MGP_TIER_RULE_LABEL_TIER_INFO")}</p>
                                    </Grid>
                                    <Grid item xs={9} className="info-item__description">
                                        <p className="tier-line">
                                            {t("MGP_TIER_RULE_LABEL_TIER_NAME")}:{" "}
                                            {item?.TierName || "-"}
                                        </p>
                                        <p className="tier-line">
                                            {t("MGP_TIER_RULE_LABEL_TIER_LEVEL")}:{" "}
                                            {item?.TierLevel || "-"}
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid container className="info-item">
                                    <Grid item xs={3} className="info-item__title">
                                        <p>{t("MGP_TIER_RULE_LABEL_TIER_GENEROSITY")}</p>
                                    </Grid>
                                    <Grid item xs={9} className="info-item__description">
                                        <p className="tier-line">
                                            Base generosity: Spend 1 {item?.currency || "-"} get
                                            0.93458 point(s)
                                        </p>
                                        {/* <p className="tier-line">
                                            Base generosity: Spend 1 SGD get 0.93458 point(s)
                                        </p>
                                        <p className="tier-line">
                                            Tier bonus generosity: Spend 1 SGD get 0 extra point(s)
                                        </p>
                                        <p className="tier-line tier-line__link">
                                            <InfoIcon />
                                            In this tier, a member will earn 0.93458 point(s) for 1
                                            SGD spent (if no custom rules enabled).
                                        </p> */}
                                    </Grid>
                                </Grid>
                                <Grid container className="info-item">
                                    <Grid item xs={3} className="info-item__title">
                                        <p>{t("MGP_TIER_RULE_LABEL_PRIVILEGE")}</p>
                                    </Grid>
                                    <Grid item xs={9} className="info-item__description">
                                        <p className="tier-line">-</p>
                                    </Grid>
                                </Grid>
                                <Grid container className="info-item">
                                    <Grid item xs={3} className="info-item__title">
                                        <p>
                                            {t("MGP_TIER_RULE_LABEL_UPGRADE_RULE")}
                                            <HelpIcon className="icon-help" />
                                        </p>
                                    </Grid>
                                    <Grid item xs={9} className="info-item__description">
                                        <p>{item?.UpgradePoint}</p>
                                        {/* <p className="tier-line">
                                            Total transactions amount: In the past 0 day(s), member
                                            spent 0 SGD to 1,284 SGD
                                        </p>
                                        <p className="tier-line">
                                            Threhold to next tier: 1,284 SGD
                                        </p>
                                        <p className="tier-line">
                                            Point calculation rule: Use the Points rule of next tier
                                            the whole transaction
                                        </p> */}
                                    </Grid>
                                </Grid>
                                <Grid container className="info-item">
                                    <Grid item xs={3} className="info-item__title">
                                        <p className="title-help">
                                            {t("MGP_TIER_RULE_LABEL_DOWNGRADE_RULE")}
                                        </p>
                                    </Grid>
                                    <Grid item xs={9} className="info-item__description">
                                        <p className="tier-line">
                                            {item?.DowngradePoint
                                                ? item?.DowngradePoint
                                                : t("MGP_TIER_RULE_LABEL_DOWNGRADE_RULE_INFO")}
                                        </p>
                                        {/* <p className="tier-line">Never downgrade member</p> */}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
        </div>
    );
}

export default TierRule;
