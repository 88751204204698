import React, { useState } from "react";
import PropTypes from "prop-types";
import CardCouponImg from "../../../assets/images/card-coupon.png";
import { Button, Checkbox } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CouponCard = (props) => {
    const [checked, setChecked] = useState(false);
    const history = useHistory();
    const handleChange = (e) => {
        setChecked(e.target.checked);
        props.handleSetRowSelected(props.data);
    };
    return (
        <div
            className="items item-coupon flex"
            style={{ backgroundImage: `url('${CardCouponImg}')` }}
        >
            <div className="content flex">
                <div className="title-left flex items-center justify-center">
                    <span>Cash coupon</span>
                </div>
                <div className="content-right">
                    <div className="flex justify-end px-2 pt-3">
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            color="primary"
                            style={{ padding: 0 }}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>
                    <div className="p-4 pt-0">
                        <p>{props.data?.ChannelName || "-"}</p>
                        <p>
                            {props.data?.CouponType == 1
                                ? `$${props.data?.Amount} Cash`
                                : `${props.data?.Discount}%`}
                        </p>
                        <div className="overflow-e">
                            {props.data?.EffectiveDateBegin || "-"} -{" "}
                            {props.data?.EffectiveDateEnd || "-"}
                        </div>
                    </div>
                </div>
                <div className="action-group-btn">
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        style={{ width: "49%" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/campaign/coupon/edit/${props.data?.Id}`);
                        }}
                        startIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        style={{ width: "49%" }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        startIcon={<FileCopyIcon />}
                    >
                        Copy
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CouponCard;
