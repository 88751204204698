import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import bgLogin from "../../assets/images/bg-login.png";
import { useDispatch } from "react-redux";
import authActions from "../../redux/Auth/actions";
import { FormControl, NativeSelect } from "@material-ui/core";
import * as authService from "../../services/auth";
import { getUserID } from "../../utils/actionUtil";
function SelectStore(props) {
    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const [storeList, setStoreList] = useState([]);

    const onSubmit = (data) => {
        const user_id = getUserID();
        if (user_id) {
            const body = {
                user_id: user_id,
                store_id: data.store_id,
            };
            dispatch(authActions.loginStore(body));
        }
    };

    useEffect(() => {
        const userID = getUserID();
        authService.selectStoreService(userID).then((res) => {
            if (res.data) {
                setStoreList(res.data.data);
                setValue("store_id", res.data.data[0].StoreId);
            }
        });
    }, []);

    return (
        <div
            id="login-page"
            className="wrapper-login-page h-screen flex items-center justify-center"
            style={{ background: `url(${bgLogin})` }}
        >
            <div className="login-box p-8">
                <div className="login-box-body p-6 py-4 mt-3 pb-6">
                    {/* <p className="text-center">Sign in to start your session</p> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group my-2 mt-4">
                            <label className="text-semibold label-input">Select store</label>
                            <Controller
                                name="store_id"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <FormControl style={{ width: "100%" }}>
                                        <NativeSelect
                                            value={value}
                                            onChange={onChange}
                                            name="store_id"
                                            inputRef={ref}
                                            variant="outlined"
                                            className="custom-select"
                                        >
                                            {storeList.map((item, index) => (
                                                <option value={item?.StoreId} key={index}>
                                                    {item?.StoreName}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                )}
                            />
                            {errors.store && <span>This field is required</span>}
                        </div>

                        <div className="flex items-center">
                            <Link to="/" className="btn-primary-filled mt-2 mr-2">
                                Back
                            </Link>
                            <input
                                type="submit"
                                className="btn-primary-filled w-full mt-2"
                                value="Go"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SelectStore;
