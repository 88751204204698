import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as redemptionService from "../../../services/redeemption";
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { useQueryParams, StringParam } from "use-query-params";
import SearchIcon from "@material-ui/icons/Search";
import { useForm, Controller } from "react-hook-form";
import { Button, CircularProgress } from "@material-ui/core";
import PointRedeem from "../Modal/PointRedeem";
import { useTranslation } from "react-i18next";

const PointTable = (props) => {
    const { t } = useTranslation();
    const [pickerStatus, setPickerStatus] = useState({
        registerDateFrom: false,
        registerDateTo: false,
    });
    const [id, setId] = useState();
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "MemberCode",
                label: t("MGP_MEMBER_TABLE_COLUMN_NO"),
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                name: "FirstName",
                label: t("MGP_MEMBER_TABLE_COLUMN_FIRST_NAME"),
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                name: "LastName",
                label: t("MGP_MEMBER_TABLE_COLUMN_FIRST_NAME"),
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_PHONE_NUMBER"),
                name: "PhoneNumber",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                name: "Email",
                label: t("MGP_MEMBER_TABLE_COLUMN_EMAIL"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_POINT"),
                name: "Point",
                options: {
                    filter: true,
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_MEMBER"),
                name: "TierName",
                options: {
                    filter: true,
                },
            },
            {
                label: t("MGP_REDEEM_COUPON_TABLE_COLUMN_OPERATION"),
                name: "Delete",
                options: {
                    filter: true,
                    sort: false,
                    empty: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            position: "sticky",
                            left: "0",
                            background: "white",
                            zIndex: 100,
                        },
                    }),
                    setCellHeaderProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            position: "sticky",
                            left: 0,
                            background: "white",
                            zIndex: 101,
                        },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            // <Button
                            //     variant="contained"
                            //     size="medium"
                            //     color="primary"
                            //     style={{ width: "100%" }}
                            //     onClick={(e) => {
                            //         e.stopPropagation();
                            //         history.push(`/member/${tableMeta.rowData[0]}`);
                            //     }}
                            // >
                            //     Redeem
                            // </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                // startIcon={<CloseIcon />}
                                onClick={() => {
                                    setId(tableMeta.rowData[0]);
                                    handleOpen();
                                }}
                            >
                                {t("MGP_REDEEM_COUPON_BUTTON_REDEEM")}
                            </Button>
                        );
                    },
                },
            },
        ],
        isLoading: false,
    });
    const [visibleAdvanceSearch, setVisibleAdvanceSearch] = useState(false);

    const [query, setQuery] = useQueryParams({
        keyword: StringParam,
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            keyword: query.keyword,
        },
    });

    // const { x: num, q: searchQuery, filters } = query;

    const onSubmit = (data) => {
        // setQuery({ foo: 123, bar: 'zzz' }, 'push');
        console.log(data);
        setQuery(
            {
                keyword: data.keyword || undefined,
            },
            "push"
        );
    };

    useEffect(() => {
        if (query) {
            changeRowsPerPage(state.sortOrder, state.numberOfRows, query);
        } else {
            getData();
        }
    }, [query]);

    const history = useHistory();
    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
        });
    };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }

        if (search) {
            model = { ...model, ...search };
        } else {
            model = { ...model, ...getValues() };
        }
        // setState({ ...state, page: page + 1 });
        return redemptionService.getListRedeemPointService(model);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;
    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        selectableRows: "none",
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],

        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(state.sortOrder, numberOfRows, ""),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, tableState.sortOrder, state.rowsPerPage, "");
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
    };

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                        fontWeight: "bold",
                    },
                },
            },
        });

    const handleClose = () => {
        // e.preventDefault();
        setId(null);
        setOpen(false);
    };

    const refreshTable = () => {
        changeRowsPerPage(state.sortOrder, state.numberOfRows, query);
    };

    return (
        <div className="wrapper-table">
            <div className="wrapper-advance-search">
                {console.log(id)}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="search-table flex items-center">
                        <input
                            className="p-2 w-5/12 mr-3"
                            placeholder={`${t("MGP_COMMON_LABEL_SEARCH")}...`}
                            {...register("keyword")}
                        />
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            style={{ minHeight: 42 }}
                            startIcon={<SearchIcon />}
                            disabled={visibleAdvanceSearch}
                            type="submit"
                            value="Search"
                        >
                            {t("MGP_COMMON_LABEL_SEARCH")}
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                style={{ marginLeft: 15, position: "relative", top: 4 }}
                            />
                        )}
                    </div>
                </form>
            </div>
            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable data={data} columns={state.columns} options={options} />
            </MuiThemeProvider>

            {id ? (
                <PointRedeem
                    id={id}
                    refreshTable={refreshTable}
                    handleClose={handleClose}
                    open={open}
                />
            ) : (
                ""
            )}
            {/* <Typography>Page: {state.page + 1}</Typography>
            <Pagination count={lastPage} page={state.page + 1} onChange={handleChange} /> */}
        </div>
    );
};

export default PointTable;
