import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Button,
    Grid,
    Modal,
    Backdrop,
    Fade,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
} from "@material-ui/core";
import * as memberService from "../../../services/member";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import * as redemptionService from "../../../services/redeemption";
import { useTranslation } from "react-i18next";

const CouponRedeem = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        if (props.id) {
            redemptionService
                .getRedeemCouponDetailService(props.id)
                .then((res) => {
                    console.log(res, "Data");
                    if (res?.data && res.data.data) {
                        setData(res?.data?.data);
                    }
                })
                .catch((err) => {
                    toast.error("Something went wrong, please try again!");
                });
        }
    };

    const onSubmit = (data) => {
        // console.log("DATA FORM", data);
        console.log("DATA FORM", data);
        setLoading(true);
        // data.transaction_date = data.transaction_date.toISOString();
        // data.transaction_date = new Date();
        data.id = props.id;
        redemptionService
            .postRedeemDiscountService(data, props.id)
            .then((res) => {
                if (res.data.success) {
                    toast.success("Coupon redeemption successful!");
                    setLoading(false);
                    reset();
                    props.refreshTable();
                    props.handleClose();
                } else {
                    toast.error(res.data.message);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex items-center justify-center"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className="modal-custom" style={{ width: "55%" }}>
                        <div className="modal-head flex justify-between items-center">
                            <h2 className="text-xl font-bold">
                                {t("MGP_REDEEM_COUPON_DETAIL_BUTTON_REDEEM")}
                            </h2>
                            <button onClick={props.handleClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="modal-content flex justify-between items-center">
                            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                                <Grid container spacing={2} className="py-6">
                                    <Grid item xs={12}>
                                        <label className="text-semibold label-input font-bold">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_INFO")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group">
                                            <label className="text-semibold label-input mr-2">
                                                {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_NAME")}:{" "}
                                            </label>
                                            <span>{data?.FullName || "-"}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group">
                                            <label className="text-semibold label-input mr-2">
                                                {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_TIER")}:{" "}
                                            </label>
                                            <span>{data?.TierName || "-"}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group">
                                            <label className="text-semibold label-input mr-2">
                                                {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_EMAIL")}:{" "}
                                            </label>
                                            <span>{data?.Email || "-"}</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group">
                                            <label className="text-semibold label-input mr-2">
                                                {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_MOBILE")}:{" "}
                                            </label>
                                            <span>{data?.PhoneNumber || "-"}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid container spacing={2} className="py-6">
                                    <Grid item xs={12}>
                                        <label className="text-semibold label-input font-bold">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_COUPON_INFO")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <div className="form-group">
                                            <Grid container spacing={2}>
                                                <Grid item xs={5}>
                                                    <label className="text-semibold label-input mr-2">
                                                        {t(
                                                            "MGP_REDEEM_COUPON_DETAIL_LABEL_AVAILABLE_POINTS"
                                                        )}
                                                        :{" "}
                                                    </label>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <span>{data?.Point || "-"}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <div className="form-group">
                                            <Grid container spacing={2}>
                                                <Grid item xs={5}>
                                                    <label className="text-semibold label-input mr-2 flex h-full items-center">
                                                        {t(
                                                            "MGP_REDEEM_COUPON_DETAIL_LABEL_COUPON_NAME"
                                                        )}
                                                        :{" "}
                                                    </label>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <span>{data?.CouponName || "-"}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid container spacing={2} className="py-6">
                                    <Grid item xs={12}>
                                        <label className="text-semibold label-input font-bold">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_POINT_REDEMPTION")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioGroup
                                            aria-label="noti"
                                            name="noti"
                                            // value={gender}
                                            // onChange={handleChangeGender}
                                            className="flex"
                                            style={{ flexDirection: "row" }}
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<Radio color="primary" />}
                                                label={t(
                                                    "MGP_REDEEM_COUPON_DETAIL_LABEL_CODE_EMAIL"
                                                )}
                                            />
                                            <FormControlLabel
                                                value="2"
                                                control={<Radio color="primary" />}
                                                label={t(
                                                    "MGP_REDEEM_COUPON_DETAIL_LABEL_CODE_MOBILE"
                                                )}
                                            />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="form-group">
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <label className="text-semibold label-input mr-2 input-require flex h-full items-center">
                                                        {t(
                                                            "MGP_REDEEM_COUPON_DETAIL_LABEL_VERFICATION_CODE"
                                                        )}
                                                        :{" "}
                                                    </label>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <div className="flex">
                                                        <input
                                                            className="p-2 w-full mr-2"
                                                            {...register("point_redemption", {})}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            // onClick={handleOpen}
                                                        >
                                                            {t(
                                                                "MGP_REDEEM_COUPON_DETAIL_BUTTON_SEND"
                                                            )}
                                                        </Button>
                                                    </div>
                                                    {errors.point_redemption && (
                                                        <span className="text-error">
                                                            This field is required
                                                        </span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="form-group">
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <label className="text-semibold input-require label-input mr-2 flex h-full items-center">
                                                        {t(
                                                            "MGP_REDEEM_COUPON_DETAIL_LABEL_INVOICE_NO"
                                                        )}
                                                        :{" "}
                                                    </label>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <input
                                                        className="p-2 w-full"
                                                        {...register("invoice_no", {
                                                            required: true,
                                                        })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="form-group">
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <label className="text-semibold label-input mr-2">
                                                        {t("MGP_REDEEM_COUPON_DETAIL_LABEL_REMARK")}
                                                        :{" "}
                                                    </label>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <textarea
                                                        className="w-full p-2"
                                                        rows="4"
                                                        cols="50"
                                                        {...register("remark")}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="flex justify-end w-full">
                                    <div className="w-6/12 flex">
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                style={{ position: "relative", top: 16, right: 18 }}
                                            />
                                        )}
                                        <button
                                            // type="submit"
                                            className="btn-primary-filled mt-2 text-center w-6/12"
                                            disabled={loading}
                                            onClick={props.handleClose}
                                        >
                                            {t("MGP_REDEEM_COUPON_DETAIL_BUTTON_CANCEL")}
                                        </button>

                                        <input
                                            type="submit"
                                            className="btn-primary-filled mt-2 ml-3 w-6/12"
                                            disabled={loading}
                                            value={t("MGP_REDEEM_COUPON_DETAIL_BUTTON_REDEEM")}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default CouponRedeem;
