import React, { Component } from "react";
import { Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import CouponTable from "./table/CouponTable";
import { withTranslation } from "react-i18next";

class RedeemCoupon extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="member-detail">
                <div className="header-content py-2">
                    <div className="mb-2">
                        <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                            <Link color="inherit" to="/">
                                {this.props.t("MGP_SIDEBAR_LABEL_HOME")}
                            </Link>
                            <Typography color="textPrimary">
                                {this.props.t("MGP_REDEEM_COUPON_TITLE")}
                            </Typography>
                        </Breadcrumbs>
                    </div>
                    <div className="flex items-center justify-between">
                        <div>
                            <h1 className="text-2xl font-semibold uppercase">
                                {this.props.t("MGP_REDEEM_COUPON_TITLE")}
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="wrapper-paper" style={{ padding: 0, overflow: "hidden" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ padding: 8 }}>
                            <CouponTable />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withTranslation()(RedeemCoupon);
