import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getSpotCampaign = (model) => {
    var url = `${ApiRouters.CAMPAIGN}?campaign_type=1`;
    return api.get(url, { params: model });
};

export const getCouponCampaign = () => {
    var url = `${ApiRouters.COUPON_CAMPAIGN}`;
    return api.get(url);
};

export const addCampaign = (model) => {
    var url = `${ApiRouters.CAMPAIGN}`;
    return api.post(url, model);
};

export const updateCampaign = (id, model) => {
    var url = `${ApiRouters.CAMPAIGN}/${id}`;
    return api.put(url, model);
};

export const getDetailCampaign = (id) => {
    var url = `${ApiRouters.CAMPAIGN}/${id}`;
    return api.get(url);
};

export const sendCampaign = (id) => {
    var url = `${ApiRouters.CAMPAIGN}/sendcoupon/${id}`;
    return api.post(url);
};

export const getVoucherCampaign = () => {
    var url = `${ApiRouters.VOUCHER_CAMPAIGN}`;
    return api.get(url);
};

export const getGiftCampaign = () => {
    var url = `${ApiRouters.GIFT_CAMPAIGN}`;
    return api.get(url);
};
