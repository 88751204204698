import Cookies from "universal-cookie";

const cookies = new Cookies();

import AppConstant from "../constants/app";

export const setToken = (token) => cookies.set(AppConstant.TOKEN, token, { path: "/" });
export const getToken = () => cookies.get(AppConstant.TOKEN);
export const clearToken = () => cookies.remove(AppConstant.TOKEN, { path: "/" });
export const setUserID = (user_id) => cookies.set(AppConstant.USER_ID, user_id, { path: "/" });
export const getUserID = (user_id) => cookies.get(AppConstant.USER_ID, user_id, { path: "/" });

export const generateArrayOfYears = (minY) => {
    var max = new Date().getFullYear();
    var min = max - minY;
    var years = [];

    for (var i = max; i >= min; i--) {
        years.push(i);
    }
    return years;
};
