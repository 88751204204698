import axios from "axios";
import { REACT_APP_API_URL } from "../constants/app";
import { clearToken, getToken } from "./actionUtil";
// eslint-disable-next-line import/no-cycle
import { toast } from "react-toastify";
import { get, isString } from "lodash";
// import i18next from "../i18n";

export const api = axios.create({
    baseURL: REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        let message = get(error, "response.data.message", "Sorry, something went wrong");
        let status = get(error, "response.status", null);
        if (status === 401) {
            toast.error("Token Expired !");
            setTimeout(() => {
                clearToken();
                window.location.href = "/";
            }, 1500);
        } else if (status === 422) {
            const err = get(error, "response.data.errors");
            console.log(err[Object.keys(err)[0]][0]);
            err
                ? toast.error(err[Object.keys(err)[0]][0])
                : toast.error("Sorry, something went wrong");
            // if (
            //   get(error, "response.data.errors.0.code", "") ===
            //   "reservation_not_created"
            // ) {
            //   toast.error(i18next.t("TABLE_NOT_AVAILABLE"));
            // } else {
            //   toast.error(i18next.t("TC_COMMON_ERR_NOT_FOUND"));
            // }
        } else if (status >= 500 || message === "Network Error") {
            toast.error("No internet connection, or backend system has failed!");
        } else {
            if (isString(message)) {
                toast.error(message);
            } else {
                toast.error("Sorry, something went wrong");
            }
        }
        return Promise.reject(error);
    }
);

api.defaults.headers.common.Authorization = getToken() ? `Bearer ${getToken()}` : null;

export function setAuthorization(token) {
    api.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null;
}

export function removeAuthorization() {
    // for Logout
    setAuthorization(null);
}
