import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getGiftService = (model) => {
    var url = `${ApiRouters.GIFT}`;
    return api.get(url, { params: model });
};

export const addGiftService = (model) => {
    var url = `${ApiRouters.GIFT}`;
    return api.post(url, model, {
        onUploadProgress: (ProgressEvent) => {
            console.log(
                "Upload Progress: " +
                    Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
                    "%"
            );
        },
    });
};

export const getAllGiftCategoryService = () => {
    var url = `${ApiRouters.ALL_GIF_CATEGORY}`;
    return api.get(url);
};

export const getGiftCategoryService = (model) => {
    var url = `${ApiRouters.GIF_CATEGORY}`;
    return api.get(url, { params: model });
};

export const addGiftCategoryService = (model) => {
    var url = `${ApiRouters.ALL_GIF_CATEGORY}`;
    return api.post(url, model);
};

export const updateGiftCategoryService = (model, id) => {
    var url = `${ApiRouters.ALL_GIF_CATEGORY}/${id}?_method=PUT`;
    return api.post(url, model);
};

export const deleteGiftCategoryService = (id) => {
    var url = `${ApiRouters.ALL_GIF_CATEGORY}/${id}`;
    return api.delete(url);
};

export const deleteGiftService = (model) => {
    var url = `${ApiRouters.GIFT}/delete`;
    return api.put(url, model);
};

export const detailGiftService = (id) => {
    var url = `${ApiRouters.GIFT}/${id}`;
    return api.get(url);
};

export const updateGiftService = (id, model) => {
    var url = `${ApiRouters.GIFT}/${id}?_method=PUT `;
    return api.post(url, model);
};
