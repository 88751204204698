import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getPRChannelIdService = (id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_CHANNEL}/${id}`;
    return api.get(url);
};
export const deletePRChannelIdService = (model, id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_CHANNEL}/${id}/delete`;
    return api.post(url, model);
};
export const addPRChannelService = (model) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_CHANNEL}`;
    return api.post(url, model);
};