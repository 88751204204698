import React, { useEffect } from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const DaterangePicker = (props) => {
    const [open, setOpen] = React.useState(false);
    const [dateRange, setDateRange] = React.useState({});

    const toggle = () => setOpen(!open);

    const handleChange = (range) => {
        setDateRange(range);
        if (props.onChange) {
            props.onChange(range);
        }
    };

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.date) {
            const dateFrom = props.defaultValue.date.specific_date_from
                ? props.defaultValue.date.specific_date_from.split("-")
                : "";
            const dateFromConverted = dateFrom
                ? new Date(dateFrom[2], dateFrom[1] - 1, dateFrom[0])
                : "";
            const dateTo = props.defaultValue.date.specific_date_to
                ? props.defaultValue.date.specific_date_to.split("-")
                : "";
            const dateToConverted = dateTo ? new Date(dateTo[2], dateTo[1] - 1, dateTo[0]) : "";

            setDateRange({
                startDate: dateFromConverted,
                endDate: dateToConverted,
            });
        } else {
            setDateRange({
                startDate: null,
                endDate: null,
            });
        }
    }, [props.defaultValue]);
    return (
        <div className="custom-date-range">
            <div className="wrapper" onClick={toggle}>
                <span>
                    {dateRange.startDate ? moment(dateRange.startDate).format("YYYY-MM-DD") : "-"}
                </span>
                <ArrowRightAltIcon />
                <span>
                    {dateRange.startDate ? moment(dateRange.endDate).format("YYYY-MM-DD") : "-"}
                </span>
            </div>
            <div className="wrapper-date">
                <DateRangePicker open={open} toggle={toggle} onChange={handleChange} />
            </div>
        </div>
    );
};

export default DaterangePicker;
