const appConstant = {
    API_URL: process.env.REACT_APP_API_URL || "http://habber.local/api/v1/",
    ROOT_URL: process.env.REACT_APP_ROOT_URL || "http://habber.local/#/",
    // API_URL: 'http://dev.react.com/api/',
    // ROOT_URL: 'http://dev.react.com/#/',
    AUTHORIZATION: "Authorization",
    BEARER: "Bearer",
    TOKEN: "token",
    USER_ID: "user_id",
    REFRESH_TOKEN: "refresh_token",
    ACCEPT: "Accept",
    ACCEPT_ENCODING: "Accept-Encoding",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_JSON: "application/json",
    CONTENT_DISPOSITION: "Content-Disposition",
    ST_LANGUAGE: "lang",
};
export default appConstant;

export const pointType = {
    EARNED: "Earned",
    SPEND: "Spend",
};

export const couponType = [
    { type: "Cash", value: 0 },
    { type: "Amount", value: 1 },
];

export const validityPeriod = [
    { type: "Relative date", value: 0 },
    { type: "Specific date", value: 1 },
];

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || "http://habber.local/api/v1/";
