import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import {
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress,
    Select,
    NativeSelect,
    FormHelperText,
    Button,
    Breadcrumbs,
    Typography,
    Link,
    Divider,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as couponService from "../../services/coupon";
import * as commonService from "../../services/common";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import Quantity from "../../components/Quantity";
import { couponType, validityPeriod } from "../../constants/app";
import DaterangePicker from "../../components/DaterangePicker";
import { useSelector } from "react-redux";
import moment from "moment";
import MomentUtils from "@date-io/moment";

function Add(props) {
    const [loading, setLoading] = useState(false);
    const [pickerStatus, setPickerStatus] = useState(false);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(null);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            // applicable_stores: "",
            coupon_code_auto: "",
            amount: 0,
            start_from: 0,
            channel_id: 1,
            validity_period_type: 1,
            coupon_type: 1,
            redemptionConditions: 0,
            campaign_member_type: 1,
        },
    });

    const handleDateChange = (e) => {
        setSelectedDate(e);
        setValue("transaction_date", e);
    };

    const [codeGenerate, setCodeGenerate] = useState(false);

    const [address, setAddress] = useState({
        province: "",
        district: "",
    });
    const [dataChannel, setDataChannel] = useState([]);

    useEffect(() => {
        getDataChannel();
    }, []);

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const onSubmit = (data) => {
        setLoading(true);
        // if (dataStoreSelected.length > 0) {
        //     data.applicable_stores = dataStoreSelected.map((obj) => obj.Id);
        // }

        if (data.coupon_code_auto && data.coupon_code == "") {
            data.coupon_code = data.coupon_code_auto;
            delete data.coupon_code_auto;
        } else {
            delete data.coupon_code_auto;
        }

        if (data.amount) {
            data.discount = data.amount;
        }

        console.log("DATA FORM", data);
        couponService
            .addCoupon(data)
            .then((res) => {
                toast.success("Add coupon successful!");
                setLoading(false);
                history.push("/campaign/coupon");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleGenerateCode = (e) => {
        setCodeGenerate(e.target.checked);
        if (e.target.checked == true) {
            // couponService
            //     .getGenerateCode()
            //     .then((res) => setValue("coupon_code_auto", res.data.data))
            //     .catch((err) => console.log(err));
            setValue("coupon_code", "");
        } else {
            setValue("coupon_code", "");
        }
    };

    const directBack = (e) => {
        e.preventDefault();
        history.push("/campaign/recurring");
    };

    const handleChangeAddress = (event) => {
        const name = event.target.name;

        if (name == "province") {
            setValue("province_id", event.target.value);
            setAddress({
                [name]: event.target.value,
                district: "",
            });
            setDDataistrict([]);
            setValue("district", "");
        } else {
            setValue("district_id", event.target.value);
            setAddress({
                ...address,
                [name]: event.target.value,
            });
        }
    };
    const handleChangeJobTitle = (event) => {
        // const name = event.target.name;
        setValue("jobTitle_id", event.target.value);
        setJobTitle(event.target.value);
    };

    const isExistCode = (code) => !code && !getValues("coupon_code_auto");

    const handleChangeDaterange = (range) => {
        setValue("specific_date_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("specific_date_to", moment(range.endDate).format("YYYY-MM-DD"));
    };

    useEffect(() => {
        if (watch("validity_period_type") == 2) {
            setValue("start_from", 0);
            setValue("end_to", undefined);
        } else {
            setValue("specific_date_from", undefined);
            setValue("specific_date_to", undefined);
        }
    }, [watch("validity_period_type")]);

    return (
        <div className="spot-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            Home
                        </Link>
                        <Link color="inherit" href="/#/campaign/coupon">
                            Spot campaign
                        </Link>
                        <Typography color="textPrimary">Add</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">
                                Add recurring campaign
                            </h1>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <div className="form-group my-2 mt-6">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Campaign name:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("coupon_name", { required: true })}
                                        />
                                        {errors.coupon_name && (
                                            <span className="text-error">
                                                This field is required
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ marginTop: 8 }}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Public day:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="custom-date-picker">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    inputProps={{ readOnly: true }}
                                                    margin="normal"
                                                    inputVariant="outlined"
                                                    id="date-picker-dialog"
                                                    format="DD/MM/yyyy"
                                                    value={
                                                        selectedDate
                                                            ? moment(selectedDate)
                                                            : moment()
                                                    }
                                                    onChange={handleDateChange}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                                    onClick={() => setPickerStatus(true)}
                                                    onClose={() => setPickerStatus(false)}
                                                    open={pickerStatus}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="title-spot" style={{ marginTop: 16 }}>
                                <h3>WHEN</h3>
                                <p>Select your time period</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex">
                                        <label className="text-semibold label-input mt-2">
                                            Validity period:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="validity_period_type"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="jobTitle_id"
                                                        inputProps={{ "aria-label": "province" }}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {validityPeriod.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                        {watch("validity_period_type") == 1 ? (
                                            <div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            Start from:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="start_from"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after reception
                                                    </label>
                                                </div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            End to:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="end_to"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after effective
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-4">
                                                <DaterangePicker onChange={handleChangeDaterange} />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="title-spot">
                                <h3>WHO</h3>
                                <p>Define your member targets</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Campaign member type:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="campaign_member_type"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="campaign_member_type"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {/* All member, Tier, group, tag, segment */}
                                                        <option value="1">All member</option>
                                                        <option value="2">Tier</option>
                                                        <option value="3">Group</option>
                                                        <option value="4">Tag</option>
                                                        <option value="5">Segment</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                {watch("campaign_member_type") != 1 ? (
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} className="flex items-center">
                                            <label
                                                className="text-semibold label-input input-require"
                                                style={{ width: "100%" }}
                                            >
                                                {watch("campaign_member_type") == 2
                                                    ? "Tier"
                                                    : watch("campaign_member_type") == 3
                                                    ? "Group"
                                                    : watch("campaign_member_type") == 4
                                                    ? "Tag"
                                                    : watch("campaign_member_type") == 5
                                                    ? "Segment"
                                                    : ""}
                                            </label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="channel_id"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={value}
                                                            onChange={onChange}
                                                            name="channel_id"
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option value=""></option>
                                                        </NativeSelect>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="title-spot">
                                <h3>WHAT</h3>
                                <p>Choose your reward type</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Reward type:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="channel_id"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="channel_id"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value=""></option>
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Coupon:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="channel_id"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="channel_id"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value=""></option>
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="title-spot">
                                <h3>HOW</h3>
                                <p>Select your channel</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            Channel:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="channel_id"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="channel_id"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {dataChannel.map((item, index) => (
                                                            <option value={item.Id} key={index}>
                                                                {item.ChannelName}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex mt-2">
                                        <label className="text-semibold label-input input-require">
                                            terms & conditions:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <textarea
                                            id="w3review"
                                            name="w3review"
                                            rows="4"
                                            cols="50"
                                            {...register("term_and_conditions")}
                                        ></textarea>
                                        {errors.coupon_name && (
                                            <span className="text-error">
                                                This field is required
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={9}>
                            <div className="form-group my-2">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex mt-2">
                                        <label className="text-semibold label-input input-require">
                                            Remark:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <textarea
                                            id="w3review"
                                            name="w3review"
                                            rows="4"
                                            cols="50"
                                            {...register("term_and_conditions")}
                                        ></textarea>
                                        {errors.coupon_name && (
                                            <span className="text-error">
                                                This field is required
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="pb-4">
                        <Grid item xs={3}>
                            <button
                                // type="submit"
                                className="btn-primary-filled w-full mt-2 text-center"
                                disabled={loading}
                                onClick={directBack}
                            >
                                Cancel
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <input
                                type="submit"
                                className="btn-primary-filled w-full mt-2"
                                disabled={loading}
                                value="Save"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    style={{ position: "relative", top: 16 }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default Add;
