import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as couponService from "../../../services/coupon";
import AvatarImg from "../../../assets/images/avatar-example.png";
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { useQueryParams, StringParam } from "use-query-params";
import SearchIcon from "@material-ui/icons/Search";
import { useForm, Controller } from "react-hook-form";
import CachedIcon from "@material-ui/icons/Cached";
import { Button, Grid, CircularProgress, FormControl, NativeSelect } from "@material-ui/core";
import { couponType, validityPeriod } from "../../../constants/app";
import * as commonService from "../../../services/common";

const VoucherRedemption = forwardRef((props, ref) => {
    const history = useHistory();
    const [rowSelected, setRowSelected] = useState([]);
    const [dataChannel, setDataChannel] = useState([]);
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        selectedRow: [],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "CouponCode",
                label: "Member name",
            },
            {
                name: "CouponName",
                label: "Voucher name",
            },
            {
                name: "CouponType",
                label: "Redemption date",
            },
            {
                name: "ChannelName",
                label: "Redemption points",
            },
            {
                name: "EffectiveDateBegin",
                label: "Redemption status",
            },
            {
                name: "EffectiveDateEnd",
                label: "Expired date",
            },
            {
                name: "Delete",
                label: "Operation",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(`/campaign/coupon/${tableMeta.rowData[0]}`);
                                    }}
                                >
                                    Detail
                                </Button>
                            </div>
                        );
                    },
                },
            },
        ],
        isLoading: false,
    });
    const [visibleAdvanceSearch, setVisibleAdvanceSearch] = useState(false);

    const [query, setQuery] = useQueryParams({
        keyword: StringParam,
        coupon_type: StringParam,
        channel_id: StringParam,
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            keyword: query.keyword,
            coupon_type: query.coupon_type,
            channel_id: query.channel_id,
        },
    });

    // const { x: num, q: searchQuery, filters } = query;

    const onSubmit = (data) => {
        console.log("DATA FORM", data.keyword);
        // setQuery({ foo: 123, bar: 'zzz' }, 'push');

        setQuery(
            {
                keyword: data.keyword || undefined,
                coupon_type: data.coupon_type || undefined,
                channel_id: data.channel_id || undefined,
            },
            "push"
        );
    };

    useEffect(() => {
        getDataChannel();
    }, []);

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (query) {
            changeRowsPerPage(state.sortOrder, state.numberOfRows, query);
        } else {
            getData();
        }
    }, [query]);

    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
        });
        props.handleSetTotal(res.data.data.total);
    };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }

        if (search) {
            model = { ...model, ...search };
        } else {
            model = { ...model, ...getValues() };
        }
        // setState({ ...state, page: page + 1 });
        return couponService.getListCouponService(model);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
            props.handleSetTotal(res.data.data.total);
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };

    const deleteCoupon = (id) => {
        couponService.deleteCoupon({ list_of_id: [id] }).then((res) =>
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            })
        );
    };

    useImperativeHandle(ref, () => ({
        deleteMultiCoupon() {
            console.log(rowSelected);
            if (rowSelected.length > 0) {
                const arrId = rowSelected.map((item) => item.Id);
                const model = {
                    list_of_id: arrId,
                };
                couponService.deleteCoupon(model).then((res) =>
                    setState({
                        ...state,
                        isLoading: false,
                        sortOrder,
                        data: res.data.data.data,
                        search: state.search,
                        lastPage: res.data.data.last_page,
                        count: res.data.data.total,
                    })
                );
            }

            // getData();
        },
    }));

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;

    const handleRowSelected = (currentRowsSelected, allRowsSelected) => {
        const filteredData = allRowsSelected.map((item) => data[item.dataIndex]);
        setRowSelected(filteredData);
        const arrIndex = allRowsSelected.map((item) => item.dataIndex);
        setState({ ...state, selectedRow: arrIndex });
    };

    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],
        selectableRows: "none",
        customToolbarSelect: () => {},
        onRowSelectionChange: handleRowSelected,
        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(state.sortOrder, numberOfRows, ""),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, tableState.sortOrder, state.rowsPerPage, "");
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
    };
    const search = () => {
        setQuery(
            {
                keyword: state.search || undefined,
            },
            "push"
        );
    };

    const handleChangeSearch = (event) => {
        setState({ ...state, search: event.target.value });
    };

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTableSelectCell: {
                    fixedLeft: {
                        position: "relative",
                    },
                },
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                    },
                },
            },
        });

    const resetValue = () => {
        setValue("keyword", "");
        setValue("coupon_type", "");
        setValue("channel_id", "");
        setQuery({
            keyword: undefined,
            coupon_type: undefined,
            channel_id: undefined,
        });
    };

    return (
        <div>
            <div className="wrapper-advance-search mb-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="search-table flex items-center">
                        <input
                            className="p-2 w-5/12 mr-3"
                            placeholder="Search..."
                            {...register("keyword")}
                        />
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            style={{ minHeight: 42 }}
                            startIcon={<SearchIcon />}
                            disabled={visibleAdvanceSearch}
                            type="submit"
                            value="Search"
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            style={{ minHeight: 42, marginLeft: 8 }}
                            startIcon={<SearchIcon />}
                            onClick={(e) => {
                                e.stopPropagation();
                                setVisibleAdvanceSearch(!visibleAdvanceSearch);
                                // resetValue();
                            }}
                        >
                            Advance Search
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                style={{ marginLeft: 15, position: "relative", top: 4 }}
                            />
                        )}
                    </div>
                    {visibleAdvanceSearch && (
                        <div className="advance-search-box mt-8">
                            <div className="flex justify-end">
                                <Button
                                    style={{ marginTop: 8, textTransform: "capitalize" }}
                                    variant="contained"
                                    size="medium"
                                    className="btn-primary-filled mt-2 text-center"
                                    // disabled={loading}
                                    // onClick={() => setVisibleAdvanceSearch(false)}
                                    startIcon={<CachedIcon />}
                                    onClick={() => resetValue()}
                                >
                                    Reset
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{
                                        marginTop: 8,
                                        marginLeft: 12,
                                        textTransform: "capitalize",
                                    }}
                                    startIcon={<SearchIcon />}
                                    type="submit"
                                    value="Search"
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable data={[]} columns={state.columns} options={options} />
            </MuiThemeProvider>

            {/* <Typography>Page: {state.page + 1}</Typography>
            <Pagination count={lastPage} page={state.page + 1} onChange={handleChange} /> */}
        </div>
    );
});

export default VoucherRedemption;
