import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    NativeSelect,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as loyaltyService from "../../../services/loyalty";
import commonAction from "../../../redux/Common/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";

function StandardSetting(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            point_name: "Points",
            use_default: true,
            earn_decimal_places: 0, // 0 - No decimal
            earn_decimal_precision_method: 0, // 0 - Round down
            earn_spend_one_sgd_get: 0.93458,
            redemption_decimal_places: 0,
            redemption_decimal_precision_method: 0, // 0 - No decimal
            redemption_spend_one_sgd_get: 0.05, // 0 - Round down
            point_expiry_rule: 0, // 0 - never expire, 1 - expire after, 2 - expire the last day, 3 - expire on
            expire_after_value: 24,
            expire_after_time: 1, // 0 - day(s), 1 - month(s), 2 - year(s)
            expire_last_day: 0,
            expire_on_month: 1, //1 - Jan,
            expire_on_count: 1, // 1 - 1st
            expire_on_year: 0,
            remark: "Earned points expire every 24 months",
            points: "Points",
            currency: "VND",
            point_expiry_rule: null,
            earn_rate: 0,
            expire_on: null,
            expire_month: 0,
        },
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        loyaltyService
            .getPointRule()
            .then((res) => {
                if (res.data.success) {
                    const { data } = res.data;
                    if (data) {
                        setValue("point_name", data.Name || "");
                        setValue("earn_spend_one_sgd_get", data.EarningRate || "");
                        setValue("redemption_spend_one_sgd_get", data.RedeemedRate || "");
                        setValue("remark", data.Remark || "");
                        setValue("points", data.Name || "");
                        setValue("currency", data.currency || "");
                        setValue("point_expiry_rule", data.PointExpiryRule || "");
                        setValue("earn_rate", data.earnRateGen || "");
                        setValue("expire_on", data.PointExpiryOn || "");
                        setValue("expire_month", data.PointExpiryMonth || "");
                    }
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };

    return (
        <div className="point-rule-settings">
            <form>
                {/* Point name */}
                <Grid container spacing={2} className="setting-item">
                    <Grid item xs={3}>
                        <div className="form-group my-2">
                            <label className="text-bold label-input w-full input-require">
                                {t("MGP_POINT_RULE_DETAIL_POINT_NAME")}:
                            </label>
                        </div>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <input
                            className="p-2 w-full"
                            disabled={true}
                            {...register("point_name", { required: true })}
                        />
                    </Grid>
                    {/* <Grid item xs={4} lg={3}>
                        <Controller
                            name="use_default"
                            control={control}
                            defaultValue={true}
                            render={({ field }) => (
                                <FormControlLabel
                                    className="custom-checkbox"
                                    control={<Checkbox {...field} color="primary" />}
                                    label="Use default value"
                                />
                            )}
                        />
                    </Grid> */}
                </Grid>

                {/* Point earn rule */}
                <Grid container spacing={2} className="setting-item">
                    <Grid item xs={3}>
                        <div className="form-group my-2">
                            <label className="text-bold label-input w-full input-require">
                                {t("MGP_POINT_RULE_DETAIL_POINT_EARN_RULE")}:
                            </label>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        {/* Decimal places */}
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <div className="form-group my-2">
                                    <label className="label-input w-full">
                                        {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PLACES")}:
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl style={{ width: "100%" }}>
                                    <NativeSelect
                                        value={0}
                                        name="earn_decimal_places"
                                        variant="outlined"
                                        className="custom-select"
                                    >
                                        <option value={0}>No decimal</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Decimal precision method */}
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <div className="form-group my-2">
                                    <label className="label-input w-full">
                                        {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PRECISION_METHOD")}:
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl style={{ width: "100%" }}>
                                    <NativeSelect
                                        value={0}
                                        name="earn_decimal_precision_method"
                                        variant="outlined"
                                        className="custom-select"
                                    >
                                        <option value={0}>Round down</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Spend 1 SGD get */}
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <div className="form-group my-2">
                                    <label className="label-input w-full">
                                        {t("MGP_POINT_RULE_TABLE_COLUMN_SPEND")}
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    type="number"
                                    className="p-2 w-full"
                                    disabled={true}
                                    {...register("earn_rate", {
                                        required: true,
                                    })}
                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <input
                                    type="number"
                                    className="p-2 w-full"
                                    disabled={true}
                                    {...register("earn_spend_one_sgd_get", {
                                        required: true,
                                    })}
                                />
                            </Grid> */}
                            <Grid item sm={7}>
                                <div className="flex">
                                    <div className="addon" style={{ height: "46px", width: "30%" }}>
                                        {watch("currency")}
                                    </div>
                                    <div className="form-group my-2 mx-4">
                                        <label className="label-input w-full">
                                            get [1] {watch("points")} ({watch("earn_rate")}{" "}
                                            {watch("currency")} = 1 {watch("points") || ""})
                                        </label>
                                    </div>
                                </div>
                            </Grid>
                            {/* <Grid item xs={5}>
                                <div className="form-group my-2">
                                    <label className="label-input w-full">
                                        for Spending to get [1] {watch("points")} (
                                        {watch("earn_rate")} {watch("currency")} = 1{" "}
                                        {watch("points") || ""})
                                    </label>
                                </div>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>

                {/* Point redemption rule */}
                <Grid container spacing={2} className="setting-item">
                    <Grid item xs={3}>
                        <div className="form-group my-2">
                            <label className="text-bold label-input w-full input-require">
                                {t("MGP_POINT_RULE_DETAIL_POINT_REDEMPTION_RULE")}:
                            </label>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        {/* Decimal places */}
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <div className="form-group my-2">
                                    <label className="label-input w-full">
                                        {" "}
                                        {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PLACES")}:
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl style={{ width: "100%" }}>
                                    <NativeSelect
                                        value={0}
                                        name="redemption_decimal_places"
                                        variant="outlined"
                                        className="custom-select"
                                    >
                                        <option value={0}>No decimal</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Decimal precision method */}
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <div className="form-group my-2">
                                    <label className="label-input w-full">
                                        {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PRECISION_METHOD")}:
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl style={{ width: "100%" }}>
                                    <NativeSelect
                                        value={0}
                                        name="redemption_decimal_precision_method"
                                        variant="outlined"
                                        className="custom-select"
                                    >
                                        <option value={0}>Round down</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Spend 1 SGD get */}
                        <Grid container spacing={2}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={3}>
                                <input
                                    type="number"
                                    className="p-2 w-full"
                                    disabled={true}
                                    value="1"
                                    // {...register("redemption_spend_one_sgd_get", {
                                    //     required: true,
                                    // })}
                                />
                            </Grid>
                            {/* <Grid item xs={2} sm={2}>
                                <div className="addon">{watch("points")} (s)</div>
                            </Grid> */}
                            <Grid item sm={7}>
                                <div className="flex">
                                    <div className="addon" style={{ height: "46px", width: "30%" }}>
                                        {watch("points")} (s)
                                    </div>
                                    <div className="form-group my-2 mx-4">
                                        <label className="label-input w-full">
                                            for {watch("redemption_spend_one_sgd_get")}{" "}
                                            {watch("currency") || ""} (1 {watch("points")} ={" "}
                                            {watch("redemption_spend_one_sgd_get")}{" "}
                                            {watch("currency") || ""})
                                        </label>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Point expire rule */}
                <Grid container spacing={2} className="setting-item">
                    <Grid item xs={3}>
                        <div className="form-group my-2">
                            <label className="text-bold label-input w-full input-require">
                                {t("MGP_POINT_RULE_DETAIL_POINT_EXPIRY_RULE")}:
                                <HelpIcon className="icon-help" />
                            </label>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl component="fieldset" className="w-full">
                            <FormLabel component="legend">
                                {" "}
                                {t("MGP_POINT_RULE_DETAIL_POINT_EXPIRY_RULE")}:
                            </FormLabel>
                            <RadioGroup
                                aria-label="point_expiry_rule "
                                name="point_expiry_rule "
                                value={0}
                                className="custom-radio"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value="0"
                                            control={<Radio color="primary" />}
                                            label={t("MGP_POINT_RULE_LABEL_NEVER_EXPIRE")}
                                            checked={watch("point_expiry_rule") === 1}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color="primary" />}
                                                    label={t("MGP_POINT_RULE_LABEL_EXPIRE_AFTER")}
                                                    checked={watch("point_expiry_rule") === 2}
                                                />
                                            </Grid>
                                            <Grid item xs={3} lg={2}>
                                                <input
                                                    type="number"
                                                    className="p-2 w-full"
                                                    {...register("expire_month", {
                                                        required: true,
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={3} lg={2}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={0}
                                                        name="expire_after_time"
                                                        variant="outlined"
                                                        className="custom-select"
                                                        checked={watch("point_expiry_rule") === 3}
                                                    >
                                                        <option value={0}>month(s)</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} lg={5}>
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color="primary" />}
                                                    label={t("MGP_POINT_RULE_LABEL_EXPIRE_X_YEAR")}
                                                    checked={watch("point_expiry_rule") === 4}
                                                />
                                            </Grid>
                                            <Grid item xs={3} lg={2}>
                                                <input
                                                    type="number"
                                                    className="p-2 mr-2 w-full"
                                                    value={
                                                        watch("expire_on")
                                                            ? moment(watch("expire_on")).format(
                                                                  "YYYY"
                                                              )
                                                            : ""
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={2} lg={1}>
                                                <div className="form-group my-2">
                                                    <label className="label-input w-full">
                                                        year(s)
                                                    </label>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color="primary" />}
                                                    label={t("MGP_POINT_RULE_LABEL_EXPIRE_ON")}
                                                />
                                            </Grid>
                                            <Grid item xs={3} lg={2}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={0}
                                                        name="expire_after_time"
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value={0}>
                                                            {watch("expire_on")
                                                                ? moment(watch("expire_on")).format(
                                                                      "MMM"
                                                                  )
                                                                : ""}
                                                        </option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} lg={2}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={0}
                                                        name="expire_after_time"
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value={0}>
                                                            {" "}
                                                            {watch("expire_on")
                                                                ? moment(watch("expire_on")).format(
                                                                      "Do"
                                                                  )
                                                                : ""}
                                                        </option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} lg={1}>
                                                <div className="form-group my-2 text-center">
                                                    <label className="label-input w-full">
                                                        after
                                                    </label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} lg={2}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={0}
                                                        name="expire_after_time"
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value={0}>
                                                            {" "}
                                                            {watch("expire_on")
                                                                ? moment(watch("expire_on")).format(
                                                                      "YYYY"
                                                                  )
                                                                : ""}
                                                        </option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2} lg={1}>
                                                <div className="form-group my-2">
                                                    <label className="label-input w-full">
                                                        year(s)
                                                    </label>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className="form-group my-2 flex flex-center">
                                            <InfoIcon className="icon-info" />
                                            <label className="label-input w-full color-blue">
                                                {t("MGP_POINT_RULE_LABEL_EXPIRE_INFO")}
                                            </label>
                                        </div>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Remarks */}
                <Grid container spacing={2} className="setting-item">
                    <Grid item xs={3}>
                        <div className="form-group my-2">
                            <label className="text-bold label-input w-full input-require">
                                {t("MGP_POINT_RULE_TABLE_COLUMN_REMARK")}:
                            </label>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <div className="form-group my-2">
                            <textarea
                                className="w-full p-2"
                                rows="6"
                                cols="50"
                                disabled={true}
                                {...register("remark")}
                            />
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

export default StandardSetting;
