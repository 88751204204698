import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getListProvince = () => {
    var url = `${ApiRouters.PROVINCE}`;
    return api.get(url);
};

export const getListDistrict = (id) => {
    var url = `${ApiRouters.DISTRICT}?province_id=${id}`;
    return api.get(url);
};

export const getListOccupation = (id) => {
    var url = `${ApiRouters.GET_OCCUPATION}`;
    return api.get(url);
};

export const getListJobTitle = () => {
    var url = `${ApiRouters.GET_JOB_TITLE}`;
    return api.get(url);
};

export const getListChannel = () => {
    var url = `${ApiRouters.GET_CHANNEL}`;
    return api.get(url);
};

export const getListStore = (model) => {
    var url = `${ApiRouters.GET_STORE}`;
    return api.get(url, { params: model });
};

export const getListTier = () => {
    var url = `${ApiRouters.GET_TIER}`;
    return api.get(url);
};

export const getListMembertag = () => {
    var url = `${ApiRouters.GET_MEMBER_TAG}`;
    return api.get(url);
};

export const getListMemberGroup = () => {
    var url = `${ApiRouters.GET_MEMBER_GROUP}`;
    return api.get(url);
};

export const getListChannelPagination = () => {
    var url = `${ApiRouters.GET_CHANNEL_PAGINATION}`;
    return api.get(url);
};

export const getStoreName = () => {
    var url = `${ApiRouters.STORE_NAME}`;
    return api.get(url);
};

