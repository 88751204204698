const actions = {
    GET_NEW_MEMBERS: "GET_NEW_MEMBERS",
    GET_NEW_MEMBERS_SUCCESS: "GET_NEW_MEMBERS_SUCCESS",
    GET_NEW_MEMBERS_ERROR: "GET_NEW_MEMBERS_ERROR",

    getNewMembers: (model) => ({
        type: actions.GET_NEW_MEMBERS,
        payload: { model },
    }),
};

export default actions;
