import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import RouterBreadcrumbs from "../../components/RouterBreadcrumbs";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Grid } from "@material-ui/core";
import MemberTable from "./Table";
import { withTranslation } from "react-i18next";

class Member extends Component {
    constructor(props) {
        super(props);

        this.state = {
            total: 0,
        };
    }

    setTotal = (totalNums) => {
        this.setState({ total: totalNums });
    };
    render() {
        return (
            <div className="member-detail">
                <div className="header-content py-2">
                    <div className="mb-2">
                        <RouterBreadcrumbs />
                    </div>
                    <div className="flex items-center justify-between">
                        <div>
                            <h1 className="text-2xl font-semibold uppercase">
                                {this.props.t("MGP_SIDEBAR_LABEL_MEMBER")}
                            </h1>
                            <p>
                                {this.props.t("MGP_COMMON_LABEL_TOTAL")} {this.state.total}{" "}
                                <span className="lowercase">
                                    {this.props.t("MGP_MEMBER_TITLE")}
                                </span>
                            </p>
                        </div>
                        <Link to="/member/add" className="btn-primary-filled">
                            + {this.props.t("MGP_MEMBER_BUTTON_ADD")}
                        </Link>
                    </div>
                </div>
                <div className="wrapper-paper" style={{ padding: 0, overflow: "hidden" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ padding: 8 }}>
                            <MemberTable handelSetTotal={this.setTotal} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Member);
