import React, { Component, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import RouterBreadcrumbs from "../../components/RouterBreadcrumbs";
import {
    Avatar,
    Button,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress,
    NativeSelect,
    FormHelperText,
    Breadcrumbs,
    Typography,
    Link,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import { useForm, Controller } from "react-hook-form";
import LoyaltyTable from "./Table/loyalty";
import AccumulateTable from "./Table/Accumulate";
import TransactionTable from "./Table/transaction";
// import VoucherTable from "./table/voucher";
import VoucherCouponGiftTable from "./Table/voucherCouponGift";
import * as memberService from "../../services/member";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import SaveIcon from "@material-ui/icons/Save";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import * as commonService from "../../services/common";
import DatePickerGroupSelect from "../../components/DatePickerGroupSelect";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AvatarImg from "../../assets/images/avatar-example.png";
import commonAction from "../../redux/Common/actions";
import { useDispatch, useSelector } from "react-redux";
import { MonthPicker, DayPicker, YearPicker } from "react-dropdown-date";
import { useTranslation } from "react-i18next";

function Detail(props) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            code: "",
            store_id: 1,
            channel_id: 1,
            phone_number: "",
            gender: 1,
            dob: null,
            birth_year: "",
            birth_month: "",
            birth_day: "",
        },
    });
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [infoMember, setInfoMember] = useState({});
    const [pickerStatus, setPickerStatus] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [editForm, setEditForm] = useState(false);
    const [gender, setGender] = React.useState("1");
    const [loadingForm, setLoadingForm] = useState(false);
    const [state, setState] = useState([]);
    const { id } = useParams();

    const [address, setAddress] = useState({
        province: "",
        district: "",
    });
    const [dataProvince, setDataProvince] = useState([]);
    const [dataDistrict, setDDataistrict] = useState([]);
    const [dataJobTitle, setDataJobTitle] = useState([]);
    const [jobTitle, setJobTitle] = useState("");
    const [dataOccupation, setDataOccupation] = useState([]);
    const [occupation, setOccupation] = useState("");
    useEffect(() => {
        getDataProvince();
        getJobTitle();
        getOccupation();
    }, []);
    useEffect(() => {
        getDataDistrict();
    }, [address.province]);

    const getDataProvince = () => {
        commonService
            .getListProvince()
            .then((res) => {
                if (res.data.data) {
                    setDataProvince([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getJobTitle = () => {
        commonService
            .getListJobTitle()
            .then((res) => {
                if (res.data.data) {
                    setDataJobTitle([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getOccupation = () => {
        commonService
            .getListOccupation()
            .then((res) => {
                if (res.data.data) {
                    setDataOccupation([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataDistrict = () => {
        address.province &&
            commonService
                .getListDistrict(address.province)
                .then((res) => {
                    if (res.data.data) {
                        setDDataistrict([...res.data.data]);
                        // if (editForm) {
                        //     setAddress({ ...address, district: res.data.data[0].Id });
                        //     setValue("district_id", res.data.data[0].Id);
                        // }
                    }
                })
                .catch((err) => console.log(err));
    };

    const handleChangeAddress = (event) => {
        const name = event.target.name;

        if (name == "province") {
            setValue("province_id", event.target.value);
            setAddress({
                [name]: event.target.value,
                district: "",
            });
            setValue("district", "");
        } else {
            setValue("district_id", event.target.value);
            setAddress({
                ...address,
                [name]: event.target.value,
            });
        }
    };

    const onSubmit = (data) => {
        setLoadingForm(true);
        if (data.birth_day && data.birth_month && data.birth_year) {
            const birthMonthFormat = parseInt(data.birth_month) + 1;
            var d = new Date();
            var hr = d.getHours();
            var min = d.getMinutes();
            var sec = d.getSeconds();
            data.birthday = new Date(
                data.birth_year,
                data.birth_month,
                parseInt(data.birth_day),
                hr,
                min,
                sec
            ).toISOString();
            data.birth_month = birthMonthFormat.toString();
        } else {
            delete data.birthday;
        }

        memberService
            .updateMember(data, id)
            .then((res) => {
                if (res.data.success) {
                    toast.success("Update member successful!");
                    setInfoMember(res.data.data);
                    setValue("code", res.data.data.MemberCode);
                    setValue("last_name", res.data.data.LastName);
                    setValue("first_name", res.data.data.FirstName);
                    setValue("gender", res.data.data.Gender);
                    setValue("phone_number", res.data.data.PhoneNumber);
                    setValue("email", res.data.data.Email);
                    setValue("gender", "3");
                    if (res.data.data.DOB) {
                        setSelectedDate(moment(res.data.data.DOB, "DD-MM-YYYY"));
                        setValue("dob", moment(res.data.data.DOB, "DD-MM-YYYY"));
                        setValue("birthday", moment(res.data.data.DOB, "DD-MM-YYYY"));
                    }
                    setGender(converGender(res.data.data.Gender) || 1);
                    setEditForm(false);
                }
                setLoadingForm(false);
            })
            .catch((err) => setLoadingForm(false));
    };

    useEffect(() => {
        getData();
    }, []);

    const handleDateChange = (e) => {
        setSelectedDate(e);
        setValue("birthday", e);
    };

    const openEditForm = () => {
        setEditForm(true);
    };

    const converGender = (gender) => {
        if (gender == "Female") {
            return "1";
        }
        if (gender == "Male") {
            return "2";
        }
        if (gender == "Unspecified") {
            return "3";
        } else return null;
    };

    const closeEditForm = () => {
        setValue("code", infoMember.MemberCode);
        setValue("last_name", infoMember.LastName);
        setValue("first_name", infoMember.FirstName);
        setValue("gender", infoMember.Gender);
        setValue("phone_number", infoMember.PhoneNumber);
        setValue("email", infoMember.Email);
        if (infoMember.DOB) {
            setSelectedDate(moment(infoMember.DOB, "DD-MM-YYYY"));

            setValue("dob", moment(infoMember.DOB, "DD-MM-YYYY"));
            setValue("birthday", moment(infoMember.DOB, "DD-MM-YYYY"));
        }
        if (infoMember.address && infoMember.address.length > 0) {
            setValue("address_1", infoMember.address.Address1);

            setAddress({
                province: infoMember.address.ProvinceId,
                district: infoMember.address.DistrictId,
            });
            setValue("province_id", infoMember.address.ProvinceId);
            setOccupation(infoMember.OccupationId);
            setJobTitle(infoMember.JobTitleId);
            setValue("jobTitle_id", infoMember.JobTitleId);
            setValue("occupation_id", infoMember.OccupationId);
            setValue("district_id", infoMember.address.DistrictId);
        }
        setGender(converGender(infoMember.Gender) || 1);
        setValue("gender", converGender(infoMember.Gender));
        setEditForm(false);
    };

    const handleChangeGender = (event) => {
        setGender(event.target.value);
        setValue("gender", event.target.value);
    };

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        memberService
            .getMemberDetail(id)
            .then((res) => {
                if (res.data.data) {
                    setInfoMember(res.data.data);
                    setValue("member_code", res.data?.data?.MemberCode);
                    setValue("last_name", res.data?.data?.LastName);
                    setValue("first_name", res.data?.data?.FirstName);
                    setValue("gender", res.data?.data?.Gender);
                    setValue("phone_number", res.data?.data?.PhoneNumber);
                    setValue("email", res.data?.data?.Email);
                    setValue("address_id", res.data?.data?.AddressId);
                    if (res.data?.data.address) {
                        setValue("address_1", res.data.data?.address?.Address1);
                        setAddress({
                            province: res.data.data?.address?.ProvinceId,
                            district: res.data.data?.address?.DistrictId,
                        });
                        setValue("province_id", res.data?.data?.address?.ProvinceId);
                        setValue("district_id", res.data?.data?.address?.DistrictId);
                    }
                    setOccupation(res.data?.data?.OccupationId);
                    setJobTitle(res.data?.data?.JobTitleId);
                    setValue("jobTitle_id", res.data?.data?.JobTitleId);
                    setValue("occupation_id", res.data?.data?.OccupationId);
                    setValue("gender", "3");
                    if (res.data.data.DOB) {
                        setSelectedDate(moment(res.data?.data?.DOB, "DD-MM-YYYY"));
                        setValue("dob", moment(res.data?.data?.DOB, "DD-MM-YYYY"));
                        setValue("birthday", moment(res.data?.data?.DOB, "DD-MM-YYYY"));
                    }
                    if (res.data?.data?.BirthYear && res.data.data.BirthYear != "0000") {
                        setValue("birth_year", res.data?.data?.BirthYear.toString());
                    }
                    if (res.data.data.BirthMonth != "00") {
                        setValue("birth_month", parseInt(res.data?.data?.BirthMonth) - 1);
                    }
                    if (res.data.data.BirthDay != "00") {
                        setValue("birth_day", parseInt(res.data.data.BirthDay));
                    }

                    setGender(converGender(res.data?.data?.Gender) || 1);
                    setEditForm(false);
                }
                dispatch(commonAction.toggleLoading(false));
            })
            .catch((err) => {
                console.log(err, "asd");
                toast.error("Something went wrong, please try again!");
                dispatch(commonAction.toggleLoading(false));
            });
    };

    const handleChangeJobTitle = (event) => {
        // const name = event.target.name;
        setValue("jobTitle_id", event.target.value);
        setJobTitle(event.target.value);
    };
    const handleChangeOccupation = (event) => {
        // const name = event.target.name;
        setValue("occupation_id", event.target.value);
        setOccupation(event.target.value);
    };

    return (
        <div className="member-detail">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <div className="mb-2">
                        <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                            <Link color="inherit" href="/">
                                {t("MGP_SIDEBAR_LABEL_HOME")}
                            </Link>
                            <Link color="inherit" href="/#/member">
                                {t("MGP_SIDEBAR_LABEL_MEMBER")}
                            </Link>
                            <Typography color="textPrimary">
                                {infoMember.MemberCode || "-"}
                            </Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">
                                {t("MGP_SIDEBAR_LABEL_MEMBER")}
                            </h1>
                            <p>{infoMember.FullName || "-"}</p>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="h-full flex flex-col justify-center text-center">
                            <h1 className="text-base font-semibold uppercase mb-1">
                                {t("MGP_MEMBER_DETAIL_MEMBERSHIP")}
                            </h1>
                            <p>{infoMember.TierName || "-"}</p>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="h-full flex flex-col justify-center text-center">
                            <h1 className="text-base font-semibold uppercase mb-1">
                                {t("MGP_MEMBER_DETAIL_SCORE")}
                            </h1>
                            <p>{infoMember.Point || "0"}</p>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="h-full flex flex-col justify-center text-center">
                            <h1 className="text-base font-semibold uppercase mb-1">
                                {t("MGP_MEMBER_DETAIL_DATE_CREATE")}
                            </h1>
                            <p>{infoMember.RegisterDate || "-"}</p>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="h-full flex flex-col justify-center text-center">
                            <h1 className="text-base font-semibold uppercase mb-1">
                                {t("MGP_MEMBER_DETAIL_STORE")}
                            </h1>
                            <p>{infoMember.StoreName || "-"}</p>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="h-full flex flex-col justify-center text-center">
                            <h1 className="text-base font-semibold uppercase mb-1">
                                {t("MGP_MEMBER_DETAIL_CHANNEL")}
                            </h1>
                            <p>{infoMember.ChannelName || "-"}</p>
                        </div>
                    </Grid>
                </Grid>
                {/* <div className="flex items-center justify-between">
                        <button className="btn-primary-filled">+ Add member</button>
                    </div> */}
            </div>
            <div className="wrapper-paper" style={{ padding: 0, overflow: "hidden" }}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={3}
                        style={{
                            padding: 16,
                            borderRight: "2px solid #242e52",
                            background: "#d8d8d8",
                        }}
                    >
                        <div className="wrapper-avatar flex flex-col items-center">
                            <Avatar
                                alt="Remy Sharp"
                                src={`${infoMember.PhotoUrl || AvatarImg}`}
                                className="mb-3"
                            />
                            <h4 className="text-lg font-semibold">
                                {" "}
                                {infoMember.MemberCode || "-"}
                            </h4>
                            <h4 className="text-base"> {infoMember.FullName || "-"}</h4>
                            <div className="text-center wrapper-vip">
                                <p className="text-xl"> {infoMember.TierName || "-"}</p>
                                <p className="text-base">
                                    {" "}
                                    {infoMember.Point || "0"} {t("MGP_MEMBER_TABLE_COLUMN_POINT")}
                                </p>
                            </div>
                            <div className="mb-4 text-center">
                                <h4 className="text-lg font-semibold">
                                    {t("MGP_COMMON_LABEL_BIRTHDAY")}
                                </h4>
                                <h4 className="text-base">{infoMember.DOB || "-"}</h4>
                            </div>
                            <div className="mb-4 text-center">
                                <h4 className="text-lg font-semibold">
                                    {t("MGP_MEMBER_TABLE_COLUMN_PHONE_NUMBER")}
                                </h4>
                                <h4 className="text-base">{infoMember.PhoneNumber || "-"}</h4>
                            </div>
                            <div className="mb-4 text-center">
                                <h4 className="text-lg font-semibold">
                                    {t("MGP_MEMBER_TABLE_COLUMN_EMAIL")}
                                </h4>
                                <h4 className="text-base">{infoMember.Email || "-"}</h4>
                            </div>
                            <div className="mb-4 text-center w-10/12">
                                <h4 className="text-lg font-semibold">
                                    {t("MGP_COMMON_LABEL_ADDRESS")}
                                </h4>
                                <h4 className="text-base">
                                    {infoMember.address ? infoMember.address.AddressFull : "-"}
                                </h4>
                            </div>
                            <div className="mb-4 text-center">
                                <h4 className="text-lg font-semibold">
                                    {t("MGP_MEMBER_DETAIL_OCCUPATION")}
                                </h4>
                                <h4 className="text-base">{infoMember.OccupationName || "-"}</h4>
                            </div>
                            <div className="mb-4 text-center">
                                <h4 className="text-lg font-semibold">
                                    {t("MGP_MEMBER_DETAIL_JOB_TITLE")}
                                </h4>
                                <h4 className="text-base">{infoMember.JobtitleName || "-"}</h4>
                            </div>
                            <div className="mb-4 text-center">
                                <h4 className="text-lg font-semibold">
                                    {t("MGP_COMMON_LABEL_DESCRIPTION")}
                                </h4>
                                <h4 className="text-base">{infoMember.Description || "-"}</h4>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={9} style={{ padding: 16 }}>
                        <div className="info">
                            <div className="header-info flex justify-between mt-4">
                                <h3 className="text-xl font-semibold uppercase">
                                    {t("MGP_COMMON_TABLE_ACTION_INFOMATION")}
                                </h3>
                                {editForm ? (
                                    <div className="flex">
                                        {loadingForm && (
                                            <CircularProgress
                                                size={24}
                                                style={{ position: "relative", top: 8, right: 8 }}
                                            />
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            startIcon={<CloseIcon />}
                                            onClick={closeEditForm}
                                            disabled={loadingForm}
                                        >
                                            {t("MGP_COMMON_LABEL_CLOSE")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            style={{ marginLeft: 8 }}
                                            startIcon={<SaveIcon />}
                                            onClick={handleSubmit(onSubmit)}
                                            disabled={loadingForm}
                                        >
                                            {t("MGP_COMMON_LABEL_SAVE")}
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        startIcon={<EditIcon />}
                                        onClick={openEditForm}
                                    >
                                        {t("MGP_COMMON_LABEL_EDIT")}
                                    </Button>
                                )}
                            </div>
                            <Grid item xs={12}>
                                <form>
                                    <Grid container spacing={1}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label className="text-semibold label-input">
                                                        {t("MGP_MEMBER_TABLE_COLUMN_NO")}:{" "}
                                                    </label>
                                                    <input
                                                        className="p-2 w-full"
                                                        disabled={true}
                                                        ref={register}
                                                        {...register("member_code", {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors.code && (
                                                        <span>This field is required</span>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}></Grid>

                                            {/* <Grid container item xs={12} spacing={5}> */}
                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label className="text-semibold label-input">
                                                        {t("MGP_MEMBER_TABLE_COLUMN_LAST_NAME")}:{" "}
                                                    </label>
                                                    <input
                                                        className="p-2 w-full"
                                                        disabled={!editForm}
                                                        {...register("last_name")}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label className="text-semibold label-input">
                                                        {t("MGP_MEMBER_TABLE_COLUMN_FIRST_NAME")}:{" "}
                                                    </label>
                                                    <input
                                                        className="p-2 w-full"
                                                        disabled={!editForm}
                                                        {...register("first_name", {
                                                            required: true,
                                                        })}
                                                    />
                                                    {errors.code && (
                                                        <span>This field is required</span>
                                                    )}
                                                </div>
                                            </Grid>
                                            {/* </Grid> */}
                                            {/* <Grid container item xs={12} spacing={5}> */}
                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label className="text-semibold label-input">
                                                        {t("MGP_MEMBER_TABLE_COLUMN_GENDER")}:{" "}
                                                    </label>
                                                    <FormControl
                                                        component="fieldset"
                                                        className="w-full"
                                                    >
                                                        <FormLabel component="legend">
                                                            Gender
                                                        </FormLabel>
                                                        <RadioGroup
                                                            aria-label="gender"
                                                            name="gender1"
                                                            value={gender}
                                                            onChange={handleChangeGender}
                                                            className="flex"
                                                            style={{ flexDirection: "row" }}
                                                        >
                                                            <FormControlLabel
                                                                value="2"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={
                                                                            gender == 2
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        disabled={!editForm}
                                                                    />
                                                                }
                                                                label={t("MGP_COMMON_LABEL_MALE")}
                                                            />
                                                            <FormControlLabel
                                                                value="1"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={
                                                                            gender == 1
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        disabled={!editForm}
                                                                    />
                                                                }
                                                                label={t("MGP_COMMON_LABEL_FEMALE")}
                                                            />

                                                            <FormControlLabel
                                                                value="3"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={
                                                                            gender == 3
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        disabled={!editForm}
                                                                    />
                                                                }
                                                                label={t(
                                                                    "MGP_COMMON_LABEL_UNSPECTIFIED"
                                                                )}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>

                                                    {errors.code && (
                                                        <span>This field is required</span>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label className="text-semibold label-input">
                                                        {t("MGP_COMMON_LABEL_BIRTHDAY")}:{" "}
                                                    </label>
                                                    {/* <div className="custom-date-picker">
                                                        {selectedDate ? (
                                                            <DatePickerGroupSelect
                                                                handleDateChange={handleDateChange}
                                                                dateDefault={selectedDate}
                                                                disabled={!editForm}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div> */}
                                                    <div className="custom-date-picker-group grid grid-flow-col grid-cols-3 gap-4">
                                                        <Controller
                                                            name="birth_year"
                                                            control={control}
                                                            render={({
                                                                field: { onChange, value, ref },
                                                            }) => {
                                                                return (
                                                                    <YearPicker
                                                                        defaultValue={"select year"}
                                                                        start={1900} // default is 1900
                                                                        end={2020} // default is current year
                                                                        reverse // default is ASCENDING
                                                                        // disabled={true} // default is false
                                                                        disabled={!editForm}
                                                                        value={value} // mandatory
                                                                        onChange={onChange}
                                                                        id={"year"}
                                                                        name={"year"}
                                                                        classes={"classes"}
                                                                        optionClasses={
                                                                            "option classes"
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                        <Controller
                                                            name="birth_month"
                                                            control={control}
                                                            render={({
                                                                field: { onChange, value, ref },
                                                            }) => (
                                                                <MonthPicker
                                                                    defaultValue={"select month"}
                                                                    numeric // to get months as numbers
                                                                    short // default is full name
                                                                    caps // default is Titlecase
                                                                    endYearGiven // mandatory if end={} is given in YearPicker
                                                                    year={watch("birth_year")} // mandatory
                                                                    // disabled={true} // default is false
                                                                    disabled={!editForm}
                                                                    value={value} // mandatory
                                                                    onChange={onChange}
                                                                    id={"month"}
                                                                    name={"month"}
                                                                    classes={"classes"}
                                                                    optionClasses={"option classes"}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            name="birth_day"
                                                            control={control}
                                                            render={({
                                                                field: { onChange, value, ref },
                                                            }) => (
                                                                <DayPicker
                                                                    defaultValue={"select day"}
                                                                    year={watch("birth_year")} // mandatory
                                                                    month={watch("birth_month")} // mandatory
                                                                    endYearGiven // mandatory if end={} is given in YearPicker
                                                                    // disabled={true} // default is false
                                                                    disabled={!editForm}
                                                                    value={value} // mandatory
                                                                    onChange={onChange}
                                                                    id={"day"}
                                                                    name={"day"}
                                                                    classes={"classes"}
                                                                    optionClasses={"option classes"}
                                                                />
                                                            )}
                                                        />

                                                        {/* <DatePickerGroupSelect handleDateChange={handleDateChange} /> */}
                                                    </div>
                                                    {errors.code && (
                                                        <span>This field is required</span>
                                                    )}
                                                </div>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label className="text-semibold label-input input-require">
                                                        {t("MGP_MEMBER_TABLE_COLUMN_EMAIL")}:
                                                    </label>
                                                    <input
                                                        className="p-2 w-full"
                                                        disabled={!editForm}
                                                        {...register("email", {
                                                            pattern:
                                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        })}
                                                    />
                                                    {errors.email && (
                                                        <span className="text-error">
                                                            Invalid email address
                                                        </span>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label className="text-semibold label-input w-full input-require">
                                                        {t("MGP_MEMBER_TABLE_COLUMN_PHONE_NUMBER")}:
                                                    </label>
                                                    <input
                                                        className="p-2 w-full"
                                                        disabled={!editForm}
                                                        {...register("phone_number")}
                                                    />
                                                    {errors["phone_number"] && (
                                                        <span className="text-error">
                                                            Invalid phone number
                                                        </span>
                                                    )}
                                                </div>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label
                                                        className="text-semibold label-input"
                                                        style={{ width: "100%" }}
                                                    >
                                                        {t("MGP_MEMBER_DETAIL_OCCUPATION")}:
                                                    </label>
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={occupation}
                                                            onChange={handleChangeOccupation}
                                                            name="occupation_id"
                                                            inputProps={{
                                                                "aria-label": "province",
                                                            }}
                                                            disabled={!editForm}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option aria-label="None" value="">
                                                                -
                                                            </option>
                                                            {dataOccupation.map((item, index) => (
                                                                <option value={item.Id} key={index}>
                                                                    {item.Name}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="form-group my-2 mt-4">
                                                    <label
                                                        className="text-semibold label-input"
                                                        style={{ width: "100%" }}
                                                    >
                                                        {t("MGP_MEMBER_DETAIL_JOB_TITLE")}:
                                                    </label>
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={jobTitle}
                                                            onChange={handleChangeJobTitle}
                                                            name="jobTitle_id"
                                                            inputProps={{
                                                                "aria-label": "province",
                                                            }}
                                                            disabled={!editForm}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option aria-label="None" value="">
                                                                -
                                                            </option>
                                                            {dataJobTitle.map((item, index) => (
                                                                <option value={item.Id} key={index}>
                                                                    {item.Name}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="form-group my-2 mt-4">
                                                    <label className="text-semibold label-input">
                                                        {t("MGP_COMMON_LABEL_ADDRESS")}:
                                                    </label>
                                                    <input
                                                        className="p-2 w-full"
                                                        disabled={!editForm}
                                                        {...register("address_1")}
                                                    />
                                                    {errors.email && (
                                                        <span className="text-error">
                                                            Invalid address
                                                        </span>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="form-group my-2 mt-4">
                                                    <label
                                                        className="text-semibold label-input input-require"
                                                        style={{ width: "100%" }}
                                                    >
                                                        {t("MGP_COMMON_LABEL_PROVINCE")}:
                                                    </label>
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={address.province}
                                                            onChange={handleChangeAddress}
                                                            disabled={!editForm}
                                                            name="province"
                                                            inputProps={{
                                                                "aria-label": "province",
                                                            }}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            {dataProvince.map((item, index) => (
                                                                <option value={item.Id} key={index}>
                                                                    {item.Name}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="form-group my-2 mt-4">
                                                    <label
                                                        className="text-semibold label-input"
                                                        style={{ width: "100%" }}
                                                    >
                                                        {t("MGP_COMMON_LABEL_DISTRICT")}:
                                                    </label>
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={address.district}
                                                            disabled={!editForm}
                                                            onChange={handleChangeAddress}
                                                            name="district"
                                                            inputProps={{
                                                                "aria-label": "district",
                                                            }}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option aria-label="None" value="">
                                                                -
                                                            </option>
                                                            {dataDistrict.map((item, index) => (
                                                                <option value={item.Id} key={index}>
                                                                    {item.Name}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </div>
                        <div className="info mt-3">
                            <div className="header-info mt-14">
                                <h3 className="text-xl font-semibold uppercase">
                                    Gift Card / Voucher / Coupon
                                </h3>
                            </div>
                            <Grid item xs={12}>
                                <VoucherCouponGiftTable />
                            </Grid>
                            <AccumulateTable
                                getDataDetail={getData}
                                currentPoint={infoMember.Point}
                                earnRate={infoMember.EarningRate}
                            />
                            <div className="header-info mt-14">
                                <h3 className="text-xl font-semibold uppercase">Transaction</h3>
                            </div>
                            <Grid item xs={12}>
                                    <TransactionTable />
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Detail;
