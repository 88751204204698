import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import {
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress,
    Select,
    NativeSelect,
    FormHelperText,
    Breadcrumbs,
    Typography,
    Link,
} from "@material-ui/core";
import * as memberService from "../../services/member";
import * as commonService from "../../services/common";
import "react-phone-input-2/lib/style.css";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import _ from "lodash";
import DatePickerGroupSelect from "../../components/DatePickerGroupSelect";
import { generateArrayOfYears } from "../../utils/actionUtil";
import { MonthPicker, DayPicker, YearPicker } from "react-dropdown-date";
import { useTranslation } from "react-i18next";

function Add(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            store_id: 1,
            channel_id: 1,
            accept_contact_via_phone: false,
            accept_contact_via_email: false,
            not_accept_any_contact: false,
            accept_terms_and_conditions: false,
            gender: 3,
            birthday: new Date(),
            member_code_auto: "",
            birth_year: "",
            birth_month: "",
            birth_day: "",
        },
    });
    const [codeGenerate, setCodeGenerate] = useState(false);
    const [pickerStatus, setPickerStatus] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [gender, setGender] = React.useState("3");

    const [address, setAddress] = useState({
        province: "",
        district: "",
    });
    const [dataProvince, setDataProvince] = useState([]);
    const [dataDistrict, setDDataistrict] = useState([]);
    const [dataJobTitle, setDataJobTitle] = useState([]);
    const [jobTitle, setJobTitle] = useState("");
    const [dataOccupation, setDataOccupation] = useState([]);
    const [occupation, setOccupation] = useState("");

    useEffect(() => {
        getDataProvince();
        getJobTitle();
        getOccupation();
    }, []);

    const getJobTitle = () => {
        commonService
            .getListJobTitle()
            .then((res) => {
                if (res.data.data) {
                    setDataJobTitle([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getOccupation = () => {
        commonService
            .getListOccupation()
            .then((res) => {
                if (res.data.data) {
                    setDataOccupation([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getDataDistrict();
    }, [address.province]);

    const getDataProvince = () => {
        commonService
            .getListProvince()
            .then((res) => {
                if (res.data.data) {
                    setDataProvince([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataDistrict = () => {
        address.province &&
            commonService
                .getListDistrict(address.province)
                .then((res) => {
                    if (res.data.data) {
                        setDDataistrict([...res.data.data]);
                        // setAddress({ ...address, district: res.data.data[0].Id });
                        // setValue("district_id", res.data.data[0].Id);
                    }
                })
                .catch((err) => console.log(err));
    };

    const onSubmit = (data) => {
        console.log("DATA FORM", data);
        setLoading(true);
        if (data.birth_day && data.birth_month && data.birth_year) {
            const birthMonthFormat = parseInt(data.birth_month) + 1;
            var d = new Date();
            var hr = d.getHours();
            var min = d.getMinutes();
            var sec = d.getSeconds();
            data.birthday = new Date(
                data.birth_year,
                data.birth_month,
                parseInt(data.birth_day),
                hr,
                min,
                sec
            ).toISOString();
            data.birth_month = birthMonthFormat.toString();
        } else {
            delete data.birthday;
        }

        if (data.member_code_auto && data.member_code == "") {
            data.member_code = data.member_code_auto;
            delete data.member_code_auto;
        } else {
            delete data.member_code_auto;
        }

        memberService
            .addMember(data)
            .then((res) => {
                toast.success("Add member successful!");
                setLoading(false);
                history.push("/member");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleGenerateCode = (e) => {
        setCodeGenerate(e.target.checked);
        if (e.target.checked == true) {
            // memberService
            //     .getGenerateCode()
            //     .then((res) => setValue("member_code_auto", res.data.data))
            //     .catch((err) => console.log(err));
            setValue("member_code", "");
        } else {
            setValue("member_code", "");
        }
    };

    const handleDateChange = (e) => {
        console.log(e);
        setSelectedDate(e);
        setValue("birthday", e);
    };

    const handleChangeGender = (event) => {
        setGender(event.target.value);
        setValue("gender", event.target.value);
    };

    const directBack = (e) => {
        e.preventDefault();
        history.push("/member");
    };

    const handleChangeAddress = (event) => {
        const name = event.target.name;

        if (name == "province") {
            setValue("province_id", event.target.value);
            setAddress({
                [name]: event.target.value,
                district: "",
            });
            setDDataistrict([]);
            setValue("district", "");
        } else {
            setValue("district_id", event.target.value);
            setAddress({
                ...address,
                [name]: event.target.value,
            });
        }
    };
    const handleChangeJobTitle = (event) => {
        // const name = event.target.name;
        setValue("jobTitle_id", event.target.value);
        setJobTitle(event.target.value);
    };
    const handleChangeOccupation = (event) => {
        // const name = event.target.name;
        setValue("occupation_id", event.target.value);
        setOccupation(event.target.value);
    };

    const isExistCode = (code) => !code && !getValues("member_code_auto");

    return (
        <div className="member-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            {t("MGP_SIDEBAR_LABEL_HOME")}
                        </Link>
                        <Link color="inherit" href="/#/member">
                            {t("MGP_SIDEBAR_LABEL_MEMBER")}
                        </Link>
                        <Typography color="textPrimary">{t("MGP_COMMON_LABEL_ADD")}</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">
                                {t("MGP_MEMBER_TITLE")}
                            </h1>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input input-require">
                                    {t("MGP_MEMBER_TABLE_COLUMN_NO")}:{" "}
                                </label>
                                <input
                                    className="p-2 w-full"
                                    {...register("member_code")}
                                    disabled={codeGenerate}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4 flex items-center h-full">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={codeGenerate}
                                            onChange={handleGenerateCode}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Auto generated member code"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input input-require">
                                    {t("MGP_MEMBER_TABLE_COLUMN_FIRST_NAME")}:
                                </label>
                                <input
                                    className="p-2 w-full"
                                    {...register("first_name", { required: true })}
                                />
                                {errors.first_name && (
                                    <span className="text-error">This field is required</span>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input">
                                    {t("MGP_MEMBER_TABLE_COLUMN_LAST_NAME")}:
                                </label>
                                <input className="p-2 w-full" {...register("last_name")} />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input w-full input-require">
                                    {t("MGP_MEMBER_TABLE_COLUMN_GENDER")}:
                                </label>
                                <FormControl component="fieldset" className="w-full">
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={gender}
                                        onChange={handleChangeGender}
                                        className="flex"
                                        style={{ flexDirection: "row" }}
                                    >
                                        <FormControlLabel
                                            value="2"
                                            control={<Radio color="primary" />}
                                            label={t("MGP_COMMON_LABEL_MALE")}
                                        />
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio color="primary" />}
                                            label={t("MGP_COMMON_LABEL_FEMALE")}
                                        />
                                        <FormControlLabel
                                            value="3"
                                            control={<Radio color="primary" />}
                                            label={t("MGP_COMMON_LABEL_UNSPECTIFIED")}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label
                                    className="text-semibold label-input"
                                    style={{ display: "block" }}
                                >
                                    {t("MGP_COMMON_LABEL_BIRTHDAY")}:
                                </label>
                                <div className="custom-date-picker-group grid grid-flow-col grid-cols-3 gap-4">
                                    <Controller
                                        name="birth_year"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <YearPicker
                                                defaultValue={t("MGP_COMMON_LABEL_YEAR")}
                                                start={1900} // default is 1900
                                                end={2020} // default is current year
                                                reverse // default is ASCENDING
                                                // disabled={true} // default is false
                                                value={value} // mandatory
                                                onChange={onChange}
                                                id={"year"}
                                                name={"year"}
                                                classes={"classes"}
                                                optionClasses={"option classes"}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="birth_month"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <MonthPicker
                                                defaultValue={t("MGP_COMMON_LABEL_MONTH")}
                                                numeric // to get months as numbers
                                                short // default is full name
                                                caps // default is Titlecase
                                                endYearGiven // mandatory if end={} is given in YearPicker
                                                year={watch("birth_year")} // mandatory
                                                // disabled={true} // default is false
                                                value={value} // mandatory
                                                onChange={onChange}
                                                id={"month"}
                                                name={"month"}
                                                classes={"classes"}
                                                optionClasses={"option classes"}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="birth_day"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <DayPicker
                                                defaultValue={t("MGP_COMMON_LABEL_DAY")}
                                                year={watch("birth_year")} // mandatory
                                                month={watch("birth_month")} // mandatory
                                                endYearGiven // mandatory if end={} is given in YearPicker
                                                // disabled={true} // default is false
                                                value={value} // mandatory
                                                onChange={onChange}
                                                id={"day"}
                                                name={"day"}
                                                classes={"classes"}
                                                optionClasses={"option classes"}
                                            />
                                        )}
                                    />

                                    {/* <DatePickerGroupSelect handleDateChange={handleDateChange} /> */}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label
                                    className={`text-semibold label-input w-full ${
                                        watch("phone_number") ? "" : "input-require"
                                    }`}
                                >
                                    {t("MGP_MEMBER_TABLE_COLUMN_EMAIL")}:
                                </label>
                                <input
                                    className="p-2 w-full"
                                    {...register("email", {
                                        // required: watch("phone_number"),
                                        pattern:
                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    })}
                                />
                                {errors.email && (
                                    <span className="text-error">Invalid email address</span>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label
                                    className={`text-semibold label-input w-full ${
                                        watch("email") ? "" : "input-require"
                                    }`}
                                >
                                    {t("MGP_MEMBER_TABLE_COLUMN_PHONE_NUMBER")}:
                                </label>
                                <Controller
                                    name="phone_number"
                                    control={control}
                                    rules={
                                        {
                                            // required: watch("email"),
                                            // validate: (value) => isValidPhoneNumber(value),
                                        }
                                    }
                                    render={({ field: { onChange, value, ref } }) => (
                                        <PhoneInput
                                            value={value}
                                            onChange={(value, data, event, formattedValue) => {
                                                setValue("phone_number", formattedValue);
                                            }}
                                            country={"vn"}
                                            id="phone_number"
                                            inputRef={register}
                                            autoFormat={true}
                                            // enableAreaCodes?: boolean;
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label
                                    className="text-semibold label-input"
                                    style={{ width: "100%" }}
                                >
                                    {t("MGP_MEMBER_DETAIL_OCCUPATION")}:
                                </label>
                                <FormControl style={{ width: "100%" }}>
                                    <NativeSelect
                                        value={occupation}
                                        onChange={handleChangeOccupation}
                                        name="occupation_id"
                                        inputProps={{ "aria-label": "province" }}
                                        variant="outlined"
                                        className="custom-select"
                                    >
                                        <option aria-label="None" value="" />
                                        {dataOccupation.map((item, index) => (
                                            <option value={item.Id} key={index}>
                                                {item.Name}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group my-2 mt-4">
                                <label
                                    className="text-semibold label-input"
                                    style={{ width: "100%" }}
                                >
                                    {t("MGP_MEMBER_DETAIL_JOB_TITLE")}:
                                </label>
                                <FormControl style={{ width: "100%" }}>
                                    <NativeSelect
                                        value={jobTitle}
                                        onChange={handleChangeJobTitle}
                                        name="jobTitle_id"
                                        inputProps={{ "aria-label": "province" }}
                                        variant="outlined"
                                        className="custom-select"
                                    >
                                        {dataJobTitle.map((item, index) => (
                                            <option value={item.Id} key={index}>
                                                {item.Name}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input">
                                    {t("MGP_COMMON_LABEL_ADDRESS")}:
                                </label>
                                <input className="p-2 w-full" {...register("address_1")} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="form-group my-2 mt-4">
                                <label
                                    className="text-semibold label-input input-require"
                                    style={{ width: "100%" }}
                                >
                                    {t("MGP_COMMON_LABEL_PROVINCE")}:
                                </label>
                                <FormControl style={{ width: "100%" }}>
                                    <NativeSelect
                                        value={address.province}
                                        onChange={handleChangeAddress}
                                        name="province"
                                        inputProps={{ "aria-label": "province" }}
                                        variant="outlined"
                                        className="custom-select"
                                    >
                                        <option aria-label="None" value="" />
                                        {dataProvince.map((item, index) => (
                                            <option value={item.Id} key={index}>
                                                {item.Name}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="form-group my-2 mt-4">
                                <label
                                    className="text-semibold label-input"
                                    style={{ width: "100%" }}
                                >
                                    {t("MGP_COMMON_LABEL_DISTRICT")}:
                                </label>
                                <FormControl style={{ width: "100%" }}>
                                    <NativeSelect
                                        value={address.district}
                                        onChange={handleChangeAddress}
                                        name="district"
                                        inputProps={{ "aria-label": "district" }}
                                        variant="outlined"
                                        className="custom-select"
                                    >
                                        <option value=""></option>
                                        {dataDistrict.map((item, index) => (
                                            <option value={item.Id} key={index}>
                                                {item.Name}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input">Privacy:</label>
                                <div className="privacy-group">
                                    <Grid container spacing={2}>
                                        <Grid item xs={10} className="flex items-center">
                                            <label className="text-semibold label-input">
                                                Yes, i accept to receive communication and offers
                                                via mobile phone
                                            </label>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div className="flex justify-end">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        {...register("accept_contact_via_phone")}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="privacy-group">
                                    <Grid container spacing={2}>
                                        <Grid item xs={10} className="flex items-center">
                                            <label className="text-semibold label-input">
                                                Yes, i accept to receive communication and offers
                                                via email
                                            </label>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div className="flex justify-end">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        {...register("accept_contact_via_email")}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="privacy-group">
                                    <Grid container spacing={2}>
                                        <Grid item xs={10} className="flex items-center">
                                            <label className="text-semibold label-input">
                                                No, i would not like to receive communication and
                                                offers by mobile phone or email
                                            </label>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div className="flex justify-end">
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        {...register("not_accept_any_contact")}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="flex items-center">
                                <label className="text-semibold label-input mr-2">
                                    Terms and conditions:
                                </label>
                                <Controller
                                    name="accept_terms_and_conditions"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Checkbox {...field} />}
                                            label="I have read and accept the terms and conditions"
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <button
                                // type="submit"
                                className="btn-primary-filled w-full mt-2 text-center"
                                disabled={loading}
                                onClick={directBack}
                            >
                                {t("MGP_COMMON_LABEL_CANCEL")}
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <input
                                type="submit"
                                className="btn-primary-filled w-full mt-2"
                                disabled={loading}
                                value={t("MGP_COMMON_LABEL_SUBMIT")}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    style={{ position: "relative", top: 16 }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default Add;
