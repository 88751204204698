import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getListCouponService = (model) => {
    var url = `${ApiRouters.COUPON}`;
    return api.get(url, { params: model });
};

export const detailCoupon = (id) => {
    var url = `${ApiRouters.COUPON}/${id}`;
    return api.get(url);
};

export const updateCoupon = (id, model) => {
    var url = `${ApiRouters.COUPON}/${id}`;
    return api.put(url, model);
};

export const addCoupon = (model) => {
    var url = `${ApiRouters.COUPON}`;
    return api.post(url, model);
};

export const deleteCoupon = (model) => {
    var url = `${ApiRouters.COUPON}/delete`;
    return api.post(url, model);
};
