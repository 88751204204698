import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React from "react";
import AverageIcon from "../../assets/images/ic-dashboard-average.svg";
import OrdersIcon from "../../assets/images/ic-dashboard-orders.svg";
import RevenueIcon from "../../assets/images/ic-dashboard-revenue.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const storeInfo = [
    {
        id: 1,
        title: "orders",
        result: "1,685",
        isIncrease: true,
        percentage: "+12",
        icon: OrdersIcon,
    },
    {
        id: 2,
        title: "revenue",
        result: "52,368",
        isIncrease: false,
        percentage: "-28",
        icon: RevenueIcon,
    },
    {
        id: 3,
        title: "average price",
        result: "15.8",
        isIncrease: true,
        percentage: "00",
        icon: AverageIcon,
    },
];

function StoreInformation(props) {
    return (
        <Grid container className="dashboard-block dashboard-store-information">
            <Grid item xs={12}>
                <Grid container justifyContent="flex-start" spacing={3}>
                    {storeInfo.map((item) => (
                        <Grid key={item.id} item className="card-wrapper" xs={4}>
                            <Paper className="card" xs={12}>
                                <div className="card-top">
                                    <div className="card-item card-item-icon">
                                        <img src={item.icon} alt="dashboard-icon" />
                                    </div>
                                    <div className="card-item card-item-info">
                                        <div className="item-title-percentage">
                                            <div className="title">
                                                <p>{item.title}</p>
                                            </div>
                                            <div className="percentage">
                                                <div className={`ribbon ribbon-${item.title}`}>
                                                    {item.percentage}%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-info-result">
                                            <p className="result">{item.result}</p>
                                            {item.isIncrease ? (
                                                <ArrowUpwardIcon className="icon-up" />
                                            ) : (
                                                <ArrowDownwardIcon className="icon-down" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-bottom">
                                    <p className="time-before">Since last month</p>
                                    <ArrowForwardIcon className="icon-right" />
                                </div>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default StoreInformation;
