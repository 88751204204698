import React, { useEffect, useState } from "react";
import { CircularProgress, Button, Grid, Modal, Backdrop, Fade } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as memberService from "../../../../services/member";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import DatePickerGroupSelect from "../../../../components/DatePickerGroupSelect";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";

const EarnPointModal = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(null);
    const [pickerStatus, setPickerStatus] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            member_id: id,
            invoice_no: "",
            amount: "",
            point: "",
            transaction_date: moment(),
            comment: "",
        },
    });

    const watchAmount = watch("amount", false);

    useEffect(() => {
        setValue("point", Math.floor(watchAmount * props.earnRate));
    }, [watchAmount]);

    const handleDateChange = (e) => {
        setSelectedDate(e);
        setValue("transaction_date", e);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        reset();
        setOpen(false);
    };

    const onSubmit = (data) => {
        // console.log("DATA FORM", data);
        setLoading(true);
        // data.transaction_date = data.transaction_date.toISOString();
        data.transaction_date = data.transaction_date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        memberService
            .transactionEarnPoint(data, id)
            .then((res) => {
                if (res.data.success) {
                    toast.success("point redeemption successful!");
                    setLoading(false);
                    reset();
                    setSelectedDate(null);
                    setOpen(false);
                    props.getDataList();
                    props.getDataDetail();
                } else {
                    toast.error(res.data.message);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                // startIcon={<CloseIcon />}
                onClick={handleOpen}
            >
                {t("MGP_MEMBER_DETAIL_EARN_POINT")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex items-center justify-center"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="modal-custom">
                        <div className="modal-head flex justify-between items-center">
                            <h2 className="text-xl font-bold">Point Earn</h2>
                            <button onClick={handleClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="modal-content flex justify-between items-center">
                            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                                <Grid container spacing={2} className="py-6">
                                    <Grid item xs={12}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_DATE"
                                                )}
                                                :{" "}
                                            </label>
                                            {/* <div className="custom-date-picker">
                                                <DatePickerGroupSelect
                                                    handleDateChange={handleDateChange}
                                                />
                                            </div> */}
                                            <div className="custom-date-picker add-padding">
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        inputProps={{ readOnly: true }}
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        id="date-picker-dialog"
                                                        format="DD/MM/yyyy"
                                                        value={
                                                            selectedDate
                                                                ? moment(selectedDate)
                                                                : moment()
                                                        }
                                                        onChange={handleDateChange}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        onClick={() => setPickerStatus(true)}
                                                        onClose={() => setPickerStatus(false)}
                                                        open={pickerStatus}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_BILL_NO"
                                                )}
                                                :{" "}
                                            </label>
                                            <input
                                                className="p-2 w-full"
                                                {...register("invoice_no", {
                                                    required: true,
                                                })}
                                            />
                                            {errors.invoice_no && (
                                                <span className="text-error">
                                                    This field is required
                                                </span>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_TABLE_ACCUMULATE_COLUMN_AMOUNT"
                                                )}
                                                :{" "}
                                            </label>
                                            <Controller
                                                name="amount"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    // validate: (value) => isValidPhoneNumber(value),
                                                }}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <CurrencyInput
                                                        id="input-example"
                                                        name="amount"
                                                        className="p-2 w-full"
                                                        decimalsLimit={2}
                                                        onValueChange={onChange}
                                                    />
                                                )}
                                            />
                                            {errors.amount && (
                                                <span className="text-error">
                                                    This field is required
                                                </span>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_MODAL_ACCUMULATE_AVAILABLE_POINT"
                                                )}
                                                :
                                            </label>
                                            <input
                                                className="p-2 w-full"
                                                type="number"
                                                disabled={true}
                                                value={props.currentPoint}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input">
                                                {t(
                                                    "MGP_MEMBER_DETAIL_MODAL_ACCUMULATE_EARNING_POINT"
                                                )}
                                                :{" "}
                                            </label>
                                            <input
                                                className="p-2 w-full disable"
                                                type="number"
                                                readOnly="readOnly"
                                                {...register("point", {
                                                    required: true,
                                                })}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input">
                                                {t("MGP_COMMON_TABLE_ACTION_COMMENT")}:{" "}
                                            </label>
                                            <textarea
                                                id="w3review"
                                                name="w3review"
                                                rows="4"
                                                cols="50"
                                                {...register("comment")}
                                            ></textarea>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="flex justify-end w-full">
                                    <div className="w-6/12 flex mt-4">
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                style={{ position: "relative", top: 16, right: 18 }}
                                            />
                                        )}
                                        <button
                                            // type="submit"
                                            className="btn-primary-filled mt-2 text-center w-6/12"
                                            disabled={loading}
                                            onClick={handleClose}
                                        >
                                            {" "}
                                            {t("MGP_COMMON_LABEL_CANCEL")}
                                        </button>

                                        <input
                                            type="submit"
                                            className="btn-primary-filled mt-2 ml-3 w-6/12"
                                            disabled={loading}
                                            value={t("MGP_COMMON_LABEL_SAVE")}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default EarnPointModal;
