import { AppBar, Box, Breadcrumbs, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import HeaderPage from "../../../components/Header/HeaderPage";
import PointStandardSetting from "./StandardSetting";
import PointRuleCategory from "./PointRuleCategory";
import CustomRule from "./Table"
import { useTranslation } from "react-i18next";

function PointRule(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [tabIndex, setTabIndex] = useState(0);

    function applyProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    function backToDashboard() {
        history.push("/dashboard");
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <div className="loyalty-point-rule-page">
            <HeaderPage extraClass="flex-end">
                <div className="header-left">
                    <Breadcrumbs className="breadcrumb-wrapper" aria-label="breadcrumb">
                        <Typography color="inherit" className="active" onClick={backToDashboard}>
                            {t("MGP_SIDEBAR_LABEL_HOME")}
                        </Typography>
                        <Typography color="textPrimary">{t("MGP_POINT_RULE_TITLE")}</Typography>
                    </Breadcrumbs>
                    <p className="header-title uppercase mt-2">{t("MGP_POINT_RULE_TITLE")}</p>
                    <p className="header-sub-title">{t("MGP_POINT_RULE_DESC")}</p>
                </div>
            </HeaderPage>
            <div className="point-rule-tabs">
                <AppBar position="static" className="point-rule-tabs-header">
                    <Tabs
                        value={tabIndex}
                        onChange={(e, newValue) => setTabIndex(newValue)}
                        aria-label="simple tabs example"
                    >
                        <Tab label={t("MGP_POINT_RULE_TAB")} {...applyProps(0)} />
                        <Tab label={"Point Custom Rule"} {...applyProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabIndex} index={0}>
                    <PointStandardSetting />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <CustomRule />
                </TabPanel>
            </div>
        </div>
    );
}

export default PointRule;
