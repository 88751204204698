import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import bgLogin from "../../assets/images/bg-login.png";
import {useDispatch, useSelector} from "react-redux";
import authActions from "../../redux/Auth/actions";
import action from "../../redux/Common/actions";
import {call} from "@redux-saga/core/effects";
import * as commonService from "../../services/common";
function LoginForm(props) {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();

    // const visible = useSelector((state) => state.commonReducer.sidebarLeft.active);
    // const dispatch = useDispatch();
    //
    // const toggleSidebar = () => {
    //     dispatch(action.toggleSidebarLeft(!visible));
    // };
    const onSubmit = async (data) => {
        dispatch(authActions.login(data));
    };

    return (
        <div
            id="login-page"
            className="wrapper-login-page h-screen flex items-center justify-center"
            style={{ background: `url(${bgLogin})` }}
        >
            <div className="login-box p-8">
                <div className="login-box-body p-6 py-4 mt-3 pb-6">
                    <div className="login-logo text-center text-2xl font-semibold uppercase mt-2">
                        Login
                    </div>
                    {/* <p className="text-center">Sign in to start your session</p> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group my-2 mt-4">
                            <label className="text-semibold label-input">Email</label>
                            <input
                                className="p-2 w-full"
                                {...register("email", {
                                    required: true,
                                    pattern:
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                })}
                            />
                            {errors.email && <span>This field is required</span>}
                        </div>
                        <div className="form-group my-3">
                            <label className="text-semibold label-input">Password</label>
                            <input
                                className="p-2 w-full"
                                type="password"
                                {...register("password", { required: true })}
                            />
                            {errors.password && <span> This field is required</span>}
                        </div>
                        <div className="flex aligns-center justify-between text-base my-3">
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    name="remember-me"
                                    value="true"
                                    className="test-sm"
                                />
                                <label htmlFor="remember-me" className="ml-2">
                                    Remember me
                                </label>
                            </div>
                            <div className="form-group">
                                <Link to="#" style={{ textDecoration: "underline" }}>
                                    Forgot password
                                </Link>
                            </div>
                        </div>
                        <input
                            type="submit"
                            className="btn-primary-filled w-full mt-2"
                            value="Login"
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}

LoginForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
};

export default LoginForm;
