import React, { useEffect, useState } from "react";
import { CircularProgress, Button, Grid, Modal, Backdrop, Fade, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import * as redemptionService from "../../../services/redeemption";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
const DetailCoupon = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        if (props.id) {
            redemptionService
                .getRedeemCouponDetailService(props.id)
                .then((res) => {
                    console.log(res, "Data");
                    if (res?.data && res.data.data) {
                        setData(res?.data?.data);
                    }
                })
                .catch((err) => {
                    toast.error("Something went wrong, please try again!");
                });
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex items-center justify-center"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className="modal-custom" style={{ width: "70%" }}>
                        <div className="modal-head flex justify-between items-center">
                            <h2 className="text-xl font-bold">
                                {t("MGP_REDEEM_COUPON_DETAIL_TITLE")}
                            </h2>
                            <button onClick={props.handleClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="modal-content">
                            <Grid container spacing={2} className="py-6">
                                <Grid item xs={12}>
                                    <label className="text-semibold label-input font-bold">
                                        {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_INFO")}:
                                    </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_NAME")}:{" "}
                                        </label>
                                        <span>{data?.FullName || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_TIER")}:{" "}
                                        </label>
                                        <span>{data?.TierName || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_POINT")}:{" "}
                                        </label>
                                        <span>{data?.Point || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_EMAIL")}:{" "}
                                        </label>
                                        <span>{data?.Email || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_MEM_MOBILE")}:{" "}
                                        </label>
                                        <span>{data?.PhoneNumber || "-"}</span>
                                    </div>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={2} className="py-6">
                                <Grid item xs={12}>
                                    <label className="text-semibold label-input font-bold">
                                        {t("MGP_REDEEM_COUPON_DETAIL_LABEL_COUPON_INFO")}:
                                    </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_COUPON_NAME")}:{" "}
                                        </label>
                                        <span>{data?.CouponName || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_COUPON_TYPE")}:{" "}
                                        </label>
                                        <span>
                                            {data?.CouponType == 1
                                                ? "Cash"
                                                : data?.CouponType == 2
                                                ? "Amount"
                                                : "-"}
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_EFFECTIVE_DATE")}:{" "}
                                        </label>
                                        <span>{data?.EffectiveDate || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_SERIAL_NO")}:{" "}
                                        </label>
                                        <span>{data?.CouponCode || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_AMOUNT")}:{" "}
                                        </label>
                                        {data?.Amount ? (
                                            <CurrencyInput
                                                className="p-0 border-0"
                                                id="input-example"
                                                name="input-name"
                                                placeholder="Please enter a number"
                                                value={data?.Amount}
                                                decimalsLimit={0}
                                            />
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_EXPIRY_DATE")}:{" "}
                                        </label>
                                        <span>{data?.EffectiveDateEnd || "-"}</span>
                                    </div>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={2} className="py-6">
                                <Grid item xs={12}>
                                    <label className="text-semibold label-input font-bold">
                                        {t("MGP_REDEEM_COUPON_DETAIL_LABEL_COUPON_REDEMPTION")}:
                                    </label>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_REDEMPTION_DATE")}:{" "}
                                        </label>
                                        <span>{data?.RedemptionDate || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_INVOICE_NO")}:{" "}
                                        </label>
                                        <span>{data?.InvoiceNo || "-"}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="form-group">
                                        <label className="text-semibold label-input mr-2">
                                            {t("MGP_REDEEM_COUPON_DETAIL_LABEL_REMARK")}:{" "}
                                        </label>
                                        <span>{data?.Remark || "-"}</span>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className="flex justify-center w-full">
                                <button
                                    // type="submit"
                                    className="btn-primary-filled mt-2 text-center w-2/12"
                                    disabled={loading}
                                    onClick={props.handleClose}
                                >
                                    {t("MGP_REDEEM_COUPON_DETAIL_BUTTON_CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default DetailCoupon;
