import actions from "./actions";

const initState = {
    coupon: [],
};

export default function couponReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_STORE_LIST:
            console.log(action.payload, "payload");
            return { ...state, coupon: action.payload };
        default:
            return state;
    }
}
