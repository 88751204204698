import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import ImgBirthday from "../../assets/images/image-dashboard-birthday.png";
import ImgDiscount from "../../assets/images/image-dashboard-discount.png";
import ImgGift from "../../assets/images/image-dashboard-gift.png";
import ImgVoucher from "../../assets/images/image-dashboard-voucher.png";

function StorePromotions() {
    return (
        <div className="dashboard-block dashboard-store-promotions">
            <div className="block-title store-promotions-title">
                <p className="title">Chương Trình Ưu Đãi</p>
            </div>
            <Grid container className="store-promotions-list">
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                        <Grid item className="card-wrapper" xs={3}>
                            <Paper className="card" xs={12}>
                                <div className="card-top">
                                    <img
                                        src={ImgGift}
                                        className="promotion-item-img"
                                        alt="dashboard-img"
                                    />
                                </div>
                                <div className="card-bottom">
                                    <p className="promotion-item-title">Quà tặng T9/2021</p>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item className="card-wrapper" xs={3}>
                            <Paper className="card" xs={12}>
                                <div className="card-top">
                                    <img
                                        src={ImgVoucher}
                                        className="promotion-item-img"
                                        alt="dashboard-img"
                                    />
                                </div>
                                <div className="card-bottom">
                                    <p className="promotion-item-title">Voucher giảm giá 20%</p>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item className="card-wrapper" xs={3}>
                            <Paper className="card" xs={12}>
                                <div className="card-top">
                                    <img
                                        src={ImgBirthday}
                                        className="promotion-item-img"
                                        alt="dashboard-img"
                                    />
                                </div>
                                <div className="card-bottom">
                                    <p className="promotion-item-title">Quà tặng sinh nhật</p>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item className="card-wrapper" xs={3}>
                            <Paper className="card" xs={12}>
                                <div className="card-top">
                                    <img
                                        src={ImgDiscount}
                                        className="promotion-item-img"
                                        alt="dashboard-img"
                                    />
                                </div>
                                <div className="card-bottom">
                                    <p className="promotion-item-title">Giảm giá VIP 20%</p>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default StorePromotions;
