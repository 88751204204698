import actions from "./actions";

const initState = {
    sidebarLeft: {
        active: false,
    },
    loadingPage: false,
    storeName: '-',
};

export default function commonReducer(state = initState, action) {
    switch (action.type) {
        case actions.TOGGLE_SIDEBAR_LEFT:
            return { ...state, sidebarLeft: { active: action.payload } };
        case actions.TOGGLE_LOADING:
            return { ...state, loadingPage: action.payload };
        case actions.STORE_NAME:
            return { ...state, storeName: action.payload };
        default:
            return state;
    }
}
