import React from "react";
import { useDispatch, useSelector } from "react-redux";
import action from "../../redux/Common/actions";
import hambergerIc from "../../assets/images/ic-hambeger.svg";
import ButtonRipple from "../ButtonRipple";
import User from "../User";

const Header = () => {
    const visible = useSelector((state) => state.commonReducer.sidebarLeft.active);
    const dispatch = useDispatch();

    const toggleSidebar = () => {
        dispatch(action.toggleSidebarLeft(!visible));
    };

    return (
        <div className="header-main">
            <ButtonRipple onClick={() => toggleSidebar()} classes="hamberger-btn">
                <img src={hambergerIc} alt="hambergerIc" />
            </ButtonRipple>
            <div className="flex">
                <User />
            </div>
        </div>
    );
};

export default Header;
