import { combineReducers } from "redux";
import authReducer from "./Auth/reducer";
import commonReducer from "./Common/reducer";
import dashboardReducer from "./Dashboard/reducer";
import couponReducer from "./Coupon/reducer";

const rootReducer = combineReducers({
    commonReducer,
    authReducer,
    dashboardReducer,
    couponReducer,
});

export default rootReducer;
