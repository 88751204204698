import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import configureStore from "./redux/store";
import Routes from "./routes";
import "./styles.scss";

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
            <ToastContainer />
            <Routes />
        </I18nextProvider>
    </Provider>,
    document.getElementById("root")
);
