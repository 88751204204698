import { Button, CircularProgress } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import AvatarImg from "../../assets/images/avatar-example.png";
import dashboardActions from "../../redux/Dashboard/actions";

const { getNewMembers } = dashboardActions;

function StoreMembers() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { newMembers, loading } = useSelector((state) => state.dashboardReducer);

    const [search, setSearch] = useState({
        rowsPerPage: 10,
        page: 1,
    });

    useEffect(() => {
        handleGetNewMembers();
    }, [search.rowsPerPage]);

    function handleGetNewMembers() {
        let model = {
            page: search.page,
            take: search.rowsPerPage,
        };
        dispatch(getNewMembers(model));
    }

    const columns = [
        {
            name: "Id",
            label: "id",
            options: {
                display: "excluded",
            },
        },
        {
            name: "PhotoUrl",
            options: {
                filter: true,
                sort: false,
                empty: true,
                setCellProps: () => ({
                    align: "center",
                }),
                customHeadLabelRender: () => null,
                customBodyRender: (value) => {
                    return (
                        <div className="avatar">
                            <img src={`${value || AvatarImg}`} alt="avatar" />
                        </div>
                    );
                },
            },
        },
        {
            name: "MemberCode",
            label: "No.",
        },
        {
            label: "Họ",
            name: "LastName",
            options: {
                filter: true,
            },
        },
        {
            name: "FirstName",
            label: "Tên",
            options: {
                filter: false,
            },
        },
        {
            label: "Giới tính",
            name: "Gender",
            options: {
                filter: true,
            },
        },
        {
            label: "Năm sinh",
            name: "DOB",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return <span>{value || "-"}</span>;
                },
            },
        },
        {
            label: "Thành viên",
            name: "TierName",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: () => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{value || "-"}</span>;
                },
            },
        },
        {
            label: "Điểm",
            name: "Point",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: () => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{value || 0}</span>;
                },
            },
        },
        {
            label: "Ngày tham gia",
            name: "RegisterDate",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            label: "Số điện thoại",
            name: "PhoneNumber",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "view",
            options: {
                filter: true,
                sort: false,
                empty: true,
                setCellProps: () => ({
                    align: "center",
                }),
                customHeadLabelRender: () => null,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                history.push(`/member/${tableMeta.rowData[0]}`);
                            }}
                        >
                            XEM
                        </Button>
                    );
                },
            },
        },
    ];

    const options = {
        responsive: "standard",
        filter: true,
        filterType: "dropdown",
        serverSide: false,
        search: false,
        selectableRows: "none",
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: search.rowsPerPage,
        rowsPerPageOptions: [10, 20],
        pagination: "disabled",
        onChangeRowsPerPage: (numberOfRows) => handleChangeRowsPerPage(numberOfRows),
    };

    function handleChangeRowsPerPage(numberOfRows) {
        setSearch((prevSearch) => ({ ...prevSearch, rowsPerPage: numberOfRows }));
    }

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTable: {
                    overflowX: "auto",
                },
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                        color: "red",
                    },
                },
            },
        });

    return (
        <div className="dashboard-block dashboard-store-members">
            {loading && (
                <div className="loading-wrapper">
                    <CircularProgress size={24} />
                </div>
            )}
            <div className="block-title store-members-title">
                <p className="title">Thành Viên Mới</p>
                <Link to="/member/add" className="btn-primary-filled">
                    + Thêm Thành Viên
                </Link>
            </div>
            <div className="store-members-list">
                <MuiThemeProvider theme={customTheme()}>
                    <MUIDataTable data={newMembers} columns={columns} options={options} />
                </MuiThemeProvider>
            </div>
        </div>
    );
}

export default StoreMembers;
