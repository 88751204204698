import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import {
    FormControl,
    CircularProgress,
    NativeSelect,
    Breadcrumbs,
    Typography,
    Link,
    Divider,
    Button,
} from "@material-ui/core";
import commonAction from "../../redux/Common/actions";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as campaignService from "../../services/campaign";
import * as commonService from "../../services/common";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import Quantity from "../../components/Quantity";
import { validityPeriod } from "../../constants/app";
import DaterangePicker from "../../components/DaterangePicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import SendIcon from "@material-ui/icons/Send";

function Details(props) {
    const [loading, setLoading] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);

    const [pickerStatus, setPickerStatus] = useState(false);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const [data, setData] = useState();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(null);
    const [dataChannel, setDataChannel] = useState([]);
    const [dataTier, setDataTier] = useState([]);
    const [dataTag, setDataTag] = useState([]);
    const [dataGroup, setDataGroup] = useState([]);
    const [dataCoupon, setDataCoupon] = useState([]);
    const { id, stt } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
        getDataChannel();
        getDataCoupon();
        getDataTiers();
        getDataMemberTag();
        getDataMemberGroup();
    }, []);

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                    setValue("channel_id", res.data.data[0].Id);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataTiers = () => {
        commonService
            .getListTier()
            .then((res) => {
                if (res.data.data) {
                    setDataTier([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };
    const getDataMemberTag = () => {
        commonService
            .getListMembertag()
            .then((res) => {
                if (res.data.data) {
                    setDataTag([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataMemberGroup = () => {
        commonService
            .getListMemberGroup()
            .then((res) => {
                if (res.data.data) {
                    setDataGroup([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataCoupon = () => {
        campaignService
            .getCouponCampaign()
            .then((res) => {
                if (res.data.data) {
                    console.log(res);
                    setDataCoupon([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        campaignService
            .getDetailCampaign(id)
            .then((res) => {
                if (res.data.data) {
                    const resp = res.data.data;
                    setData(res.data.data);
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };

    const directBack = (e) => {
        e.preventDefault();
        history.push("/campaign/spot");
    };

    const handleChangeDaterange = (range) => {
        setValue("specific_date_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("specific_date_to", moment(range.endDate).format("YYYY-MM-DD"));
    };

    const handleSend = () => {
        setLoadingSend(true);
        campaignService
            .sendCampaign(id)
            .then((res) => {
                if (res.data.success) {
                    setLoadingSend(false);
                    toast.success(`Successful sent for ${res?.data.data} member`);
                } else {
                    if (res.data.message) {
                        toast.error(res.data.message);
                    }
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => {
                setLoadingSend(false);
            });
    };

    return (
        <div className="spot-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            Home
                        </Link>
                        <Link color="inherit" href="/#/campaign/spot">
                            Spot campaign
                        </Link>
                        <Typography color="textPrimary">Detail</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex justify-between items-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">Spot campaign</h1>
                            <div className="flex items-center justify-between">
                                {loadingSend ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ position: "relative", top: 0, right: 18 }}
                                    />
                                ) : (
                                    ""
                                )}
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    // endIcon={<SendIcon />}
                                    onClick={handleSend}
                                    disabled={loadingSend}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <div className="form-group my-2 mt-6">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input">
                                        Campaign name:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.CampaignName || "-"}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 8 }}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input ">Start day:</label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.StartDate
                                        ? moment(data?.StartDate).format("DD-MM-YYYY")
                                        : "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="title-spot" style={{ marginTop: 16 }}>
                            <h3>WHEN</h3>
                            <p>Select your time period</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={9}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex">
                                    <label className="text-semibold label-input mt-2">
                                        Validity period:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.ValidityPeriodType == 0
                                        ? "Relative date:"
                                        : "Specific date:"}
                                    <div className="mt-2">
                                        {data?.ValidityPeriodType == 0 ? (
                                            <div className="flex items-center h-full">
                                                {data?.StartFrom || ""}{" "}
                                                <span className="mx-2">-</span>
                                                {data?.EndTo || ""}
                                            </div>
                                        ) : (
                                            <div className="flex items-center h-full">
                                                {data?.EffectiveDateBegin || ""}{" "}
                                                <span className="mx-2">-</span>
                                                {data?.EffectiveDateEnd || ""}
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="title-spot">
                            <h3>WHO</h3>
                            <p>Define your member targets</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={9}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input"
                                        style={{ width: "100%" }}
                                    >
                                        Campaign member type:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="flex items-center h-full">
                                        {data?.MemberType == 1
                                            ? "All member"
                                            : data?.MemberType == 2
                                            ? "Tier"
                                            : data?.MemberType == 3
                                            ? "Group"
                                            : data?.MemberType == 4
                                            ? "Tag"
                                            : data?.MemberType == 5
                                            ? "Segment"
                                            : "-"}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={3} className="flex items-center">
                                <label
                                    className="text-semibold label-input"
                                    style={{ width: "100%" }}
                                >
                                    {data?.MemberType == 1
                                        ? "All member"
                                        : data?.MemberType == 2
                                        ? "Tier"
                                        : data?.MemberType == 3
                                        ? "Group"
                                        : data?.MemberType == 4
                                        ? "Tag"
                                        : data?.MemberType == 5
                                        ? "Segment"
                                        : "-"}
                                </label>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="flex items-center h-full">
                                    {data?.MemberRefName || "-"}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="title-spot">
                            <h3>WHAT</h3>
                            <p>Choose your reward type</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={9}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input"
                                        style={{ width: "100%" }}
                                    >
                                        Reward type:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.RewardType && data?.RewardType == 2
                                        ? "Coupon"
                                        : data?.RewardType == 3
                                        ? "Voucher"
                                        : data?.RewardType == 4
                                            ? "GiftCard"
                                        : "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input"
                                        style={{ width: "100%" }}
                                    >
                                        {data?.RewardType && data?.RewardType == 2
                                            ? "Coupon"
                                            : data?.RewardType == 3
                                                ? "Voucher"
                                                : data?.RewardType == 4
                                                    ? "GiftCard"
                                                    : "-"}
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.RewardRefName || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="title-spot">
                            <h3>HOW</h3>
                            <p>Select your channel</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={9}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input"
                                        style={{ width: "100%" }}
                                    >
                                        Channel:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.Channel ? data?.Channel : "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex mt-2">
                                    <label className="text-semibold label-input">
                                        Terms & conditions:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.TermAndConditions ? data?.TermAndConditions : "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={9}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex mt-2">
                                    <label className="text-semibold label-input ">Remark:</label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.Remark ? data?.Remark : "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Details;
