import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Button,
    Grid,
    Modal,
    Backdrop,
    Fade,
    FormControl,
    NativeSelect,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CurrencyInput from "react-currency-input-field";

const AddModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(null);
    const [pickerStatus, setPickerStatus] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            contact_number: "",
            discription: "",
        },
    });

    const watchAmount = watch("amount", false);

    useEffect(() => {
        setValue("point", Math.floor(watchAmount * props.earnRate));
    }, [watchAmount]);

    const handleDateChange = (e) => {
        setSelectedDate(e);
        setValue("transaction_date", e);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        reset();
        setOpen(false);
    };

    const onSubmit = (data) => {
        // console.log("DATA FORM", data);
        setLoading(true);
        // data.transaction_date = data.transaction_date.toISOString();
        history.push("/campaign/spot/edit/1");
        // memberService
        //     .transactionEarnPoint(data, id)
        //     .then((res) => {
        //         if (res.data.success) {
        //             toast.success("point redeemption successful!");
        //             setLoading(false);
        //             reset();
        //             setSelectedDate(null);
        //             setOpen(false);
        //             props.getDataList();
        //             props.getDataDetail();
        //         } else {
        //             toast.error(res.data.message);
        //             setLoading(false);
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setLoading(false);
        //     });
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="large"
                // startIcon={<CloseIcon />}
                className="btn-primary-filled ml-3 mr-3"
                onClick={handleOpen}
            >
                + Suggest a trigger
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex items-center justify-center"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="modal-custom">
                        <div className="modal-head flex justify-between items-center">
                            <h2 className="text-xl font-bold">Suggest a trigger</h2>
                            <button onClick={handleClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="modal-content flex justify-between items-center">
                            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                                <Grid container spacing={2} className="pt-6 pb-2 ">
                                    <Grid item xs={12}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                Select a category:{" "}
                                            </label>
                                            <Controller
                                                name="channel_id"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={value}
                                                            onChange={onChange}
                                                            name="channel_id"
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option value="1">Greating</option>
                                                            <option value="2">Reminder</option>
                                                            <option value="3">Notifications</option>
                                                            <option value="4">Orther</option>
                                                        </NativeSelect>
                                                    </FormControl>
                                                )}
                                            />
                                            {errors.channel_id && (
                                                <span className="text-error">
                                                    This field is required
                                                </span>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input input-require">
                                                Contact number:{" "}
                                            </label>
                                            <input
                                                className="p-2 w-full"
                                                {...register("contact_number", {
                                                    required: true,
                                                })}
                                            />
                                            {errors.contact_number && (
                                                <span className="text-error">
                                                    This field is required
                                                </span>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="form-group mb-2">
                                            <label className="text-semibold label-input">
                                                Trigger description:{" "}
                                            </label>
                                            <textarea
                                                id="w3review"
                                                name="w3review"
                                                rows="4"
                                                cols="50"
                                                {...register("description")}
                                            ></textarea>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="flex justify-end w-full">
                                    <div className="w-6/12 flex">
                                        {loading && (
                                            <CircularProgress
                                                size={24}
                                                style={{ position: "relative", top: 16, right: 18 }}
                                            />
                                        )}
                                        <button
                                            // type="submit"
                                            className="btn-primary-filled mt-2 text-center w-6/12"
                                            disabled={loading}
                                            onClick={handleClose}
                                        >
                                            {" "}
                                            Cancel
                                        </button>

                                        <input
                                            type="submit"
                                            className="btn-primary-filled mt-2 ml-3 w-6/12"
                                            disabled={loading}
                                            value="Save"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default AddModal;
