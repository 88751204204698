import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getPRBrandIdService = (id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_BRAND}/${id}`;
    return api.get(url);
};
export const addPRBrandService = (model) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_BRAND}`;
    return api.post(url, model);
};

export const deletePRBrandIdService = (model, id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_BRAND}/${id}/delete`;
    return api.post(url, model);
};