import React, { useEffect, useState } from "react";
import { CircularProgress, Typography, Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { api } from "../../../utils/axios.configs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as memberService from "../../../services/member";
import Pagination from "@material-ui/lab/Pagination";
import { PagesOutlined } from "@material-ui/icons";
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

const TransactionTable = (props) => {
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 5,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [["Loading Data..."]],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "Code",
                label: "Loại",
            },
            {
                name: "Code",
                label: "No.",
            },
            {
                name: "Code",
                label: "Ngày",
            },
            {
                name: "Code",
                label: "Transaction",
            },
            {
                name: "Code",
                label: "Tổng tiền",
            },
            {
                name: "Code",
                label: "Channels",
            },

            {
                label: "Store",
                name: "FirstName",
                options: {
                    filter: true,
                },
            },
            {
                name: "Campaign",
                label: "Campaign",
                options: {
                    filter: false,
                },
            },
        ],
        isLoading: false,
    });
    const history = useHistory();
    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
        });
    };

    useEffect(() => {
        getData();
    }, []);

    // const sort = (page, sortOrder) => {
    //     setState({ ...state, isLoading: true });
    //     xhrRequest(page, sortOrder).then((res) => {
    //         setState({
    //             ...state,
    //             data: res.data.data.data,
    //             page: res.page,
    //             sortOrder,
    //             isLoading: false,
    //             count: res.total,
    //         });
    //     });
    // };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        const model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };
        if (search) {
            model.keyword = search;
        }

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        }
        // setState({ ...state, page: page + 1 });
        // return memberService.getListMemberService(model);
        return {};
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    name: column,
                    direction: order,
                },
            });
        });
    };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;
    const options = {
        // responsive: "scrollMaxHeight",
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        selectableRows: "none",
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [5, 10, 20],
        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
            console.log("changedColumn: ", changedColumn, "direction: ", direction);
        },
        onChangeRowsPerPage: (numberOfRows) =>
            changeRowsPerPage(state.sortOrder, numberOfRows, state.search),
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(
                        tableState.page,
                        tableState.sortOrder,
                        state.rowsPerPage,
                        state.search
                    );
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                    break;
                // console.log("action not handled.");
            }
        },
        customFooter: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage
        ) => {
            return  state.rowsPerPage < state.count && <div className="flex"> <button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" ariant="contained"
                                                                                      size="medium"
                                                                                      color="primary"
                                                                                      style={{ width: "20%" }} onClick={Loadmore}>
                Load More
            </button>
            </div>;
        }
    };
    const Loadmore = () => {
        xhrRequest(0, state.sortOrder, state.rowsPerPage + 10, query).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: state.rowsPerPage + 10,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
        });}
    const search = () => {
        changePage(page, sortOrder, rowsPerPage, state.search);
    };

    const handleChangeSearch = (event) => {
        setState({ ...state, search: event.target.value });
    };

    const [pagePagination, setPagePagination] = React.useState(1);
    const handleChange = (event, value) => {
        // setPagePagination(value);
        console.log(value, "ajnknasjnxanxnansxnansxnakjxnajksnxnajksnxa");
        xhrRequest(value, state.sortOrder, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                page: res.data.data.current_page,
                data: res.data.data.data,
            });
        });
    };

    const myTheme = createTheme({
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                },
            },
        },
    });

    return (
        <div>
            <MUIDataTable data={[]} columns={state.columns} options={options} theme={myTheme} />
            {/* <Typography>Page: {state.page + 1}</Typography>
            <Pagination count={lastPage} page={state.page + 1} onChange={handleChange} /> */}
        </div>
    );
};

export default TransactionTable;
