import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
function handleClick(event) {
    event.preventDefault();
}

export default function RouterBreadcrumbs() {
    const { t } = useTranslation();
    return (
        <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
            <Link color="inherit" href="/" onClick={handleClick}>
                {t("MGP_SIDEBAR_LABEL_HOME")}
            </Link>
            {/* <Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>
                Member
            </Link> */}
            <Typography color="textPrimary">{t("MGP_SIDEBAR_LABEL_MEMBER")}</Typography>
        </Breadcrumbs>
    );
}
