import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import { Breadcrumbs, Typography, Link } from "@material-ui/core";
import * as giftService from "../../services/gift";
import * as commonService from "../../services/common";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import commonAction from "../../redux/Common/actions";
import { useParams } from "react-router-dom";

function Detail(props) {
    const [loading, setLoading] = useState(false);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const history = useHistory();
    const [images, setImages] = useState([]);
    const maxNumber = 1;
    const dispatch = useDispatch();
    const { id } = useParams();
    const [data, setData] = useState();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            // applicable_stores: "",
            voucher_code_auto: "",
            amount: 0,
            start_from: 0,
            channel_id: 1,
            validity_period_type: 1,
            coupon_type: 1,
            redemption_conditions: 0,
        },
    });
    const [codeGenerate, setCodeGenerate] = useState(false);

    const [address, setAddress] = useState({
        province: "",
        district: "",
    });

    const fileInput = useRef(null);

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const [dataChannel, setDataChannel] = useState([]);

    useEffect(() => {
        getDataChannel();
    }, []);

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const onSubmit = (data) => {
        setLoading(true);
        // if (dataStoreSelected.length > 0) {
        //     data.applicable_stores = dataStoreSelected.map((obj) => obj.Id);
        // }

        if (data.gift_code_auto && data.gift_code == "") {
            data.gift_code = data.gift_code_auto;
            delete data.gift_code_auto;
        } else {
            delete data.gift_code_auto;
        }

        console.log("onSubmitFn:", data, "  imageFile: ", fileInput.current.files[0].name);
        const fd = new FormData();
        for (var key in data) {
            fd.append(key, data[key]); // formdata doesn't take objects
        }

        fd.append("image", fileInput.current.files[0], fileInput.current.files[0].name);

        console.log("DATA FORM", data);
        giftService
            .addGiftService(data)
            .then((res) => {
                toast.success("Add gift successful!");
                setLoading(false);
                history.push("/campaign/coupon");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleGenerateCode = (e) => {
        setCodeGenerate(e.target.checked);
        if (e.target.checked == true) {
            // couponService
            //     .getGenerateCode()
            //     .then((res) => setValue("voucher_code_auto", res.data.data))
            //     .catch((err) => console.log(err));
            setValue("coupon_code", "");
        } else {
            setValue("coupon_code", "");
        }
    };

    const directBack = (e) => {
        e.preventDefault();
        history.push("/campaign/coupon");
    };

    const handleChangeAddress = (event) => {
        const name = event.target.name;

        if (name == "province") {
            setValue("province_id", event.target.value);
            setAddress({
                [name]: event.target.value,
                district: "",
            });
            setDDataistrict([]);
            setValue("district", "");
        } else {
            setValue("district_id", event.target.value);
            setAddress({
                ...address,
                [name]: event.target.value,
            });
        }
    };
    const handleChangeJobTitle = (event) => {
        // const name = event.target.name;
        setValue("jobTitle_id", event.target.value);
        setJobTitle(event.target.value);
    };

    const isExistCode = (code) => !code && !getValues("voucher_code_auto");

    const handleChangeDaterange = (range) => {
        setValue("specific_date_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("specific_date_to", moment(range.endDate).format("YYYY-MM-DD"));
    };

    useEffect(() => {
        if (watch("validity_period_type") == 1) {
            setValue("start_from", 0);
            setValue("end_to", undefined);
        } else {
            setValue("specific_date_from", undefined);
            setValue("specific_date_to", undefined);
        }
    }, [watch("validity_period_type")]);

    useEffect(() => {
        setValue("time_from", "");
        setValue("time_to", "");
    }, [watch("minimum_timming")]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        giftService
            .detailGiftService(id)
            .then((res) => {
                if (res.data.data) {
                    setData(res.data.data);
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };

    return (
        <div className="member-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            Home
                        </Link>
                        <Link color="inherit" href="/#/rewards/gift">
                            Gift
                        </Link>
                        <Typography color="textPrimary">Detail gift</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">
                                Detail loyalty gift
                            </h1>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input ">
                                        Gift code:{" "}
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.GiftCode || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input ">
                                        Gift name:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.GiftName || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input "
                                        style={{ width: "100%" }}
                                    >
                                        Gift category:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.CategoryName || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input "
                                        style={{ width: "100%" }}
                                    >
                                        Gift type:
                                    </label>
                                </Grid>
                                <Grid item xs={6} className="flex items-center">
                                    {data?.GiftType == 0 ? "Cash" : "Amount"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input ">
                                        {data?.GiftType == 0 ? "Cash amount" : "Discount rate"}
                                    </label>
                                </Grid>
                                <Grid item xs={6} className="flex items-center">
                                    {data?.GiftType == 0 ? data?.Amount : `${data?.Discount} %`}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input "
                                        style={{ width: "100%" }}
                                    >
                                        Channel:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.ChannelName || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input ">
                                        Available stock:{" "}
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.AvailableStock || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex">
                                    <label className="text-semibold label-input">
                                        Validity period:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.ValidityPeriodType == 0
                                        ? "Relative date:"
                                        : "Specific date:"}
                                    <div className="mt-2 w-full">
                                        {data?.ValidityPeriodType == 0 ? (
                                            <div className="flex items-center h-full">
                                                {`${data?.AfterEffectiveDay} day(s) after reception` ||
                                                    ""}{" "}
                                                <span className="mx-2">-</span>
                                                {`${data?.AfterReceptionDay} day(s) after activation` ||
                                                    ""}
                                            </div>
                                        ) : (
                                            <div className="flex items-center h-full">
                                                {data?.EffectiveDateBegin || ""}{" "}
                                                <span className="mx-2">-</span>
                                                {data?.EffectiveDateEnd || ""}
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex">
                                    <label className="text-semibold label-input ">
                                        Redemption conditions:
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                    <div className="form-group mb-4 flex items-center">
                                        <div className="form-group h-full">
                                            <label>Minimum purchase of:</label>
                                        </div>
                                        <span className="ml-2">
                                            {data?.RedemptionValidity
                                                ? `${data?.redemption_validity} ${
                                                      process.env.REACT_APP_CURRENCY || "$"
                                                  }`
                                                : "-"}
                                        </span>
                                    </div>
                                    <div className="form-group mb-4 flex items-center">
                                        <div className="form-group  h-full">
                                            <label>Minimum number of items purchased:</label>
                                        </div>
                                        <span className="ml-2">
                                            {data?.MinimumItemPurchase
                                                ? `${data?.MinimumItemPurchase} time(s)`
                                                : "-"}
                                        </span>
                                    </div>

                                    <div className="form-group mb-4 flex items-center">
                                        <div className="form-group  h-full">
                                            <label>Redemption validity timing:</label>
                                        </div>
                                        <span className="ml-2">
                                            {data?.RedemptionValidityBegin
                                                ? data?.RedemptionValidityBegin
                                                : "-"}
                                            <span className="mx-3">-</span>
                                            {data?.RedemptionValidityEnd
                                                ? data?.RedemptionValidityEnd
                                                : "-"}
                                        </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex">
                                    <label className="text-semibold label-input">
                                        terms & conditions:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.TermAndConditions || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                {/* </form> */}
            </div>
        </div>
    );
}

export default Detail;
