import React, { useEffect, useState } from "react";
import { Grid, Breadcrumbs, Link, Typography } from "@material-ui/core";
// import * as commoneService from "../../services/common";
// import CloseIcon from "@material-ui/icons/Close";
// import MUIDataTable from "mui-datatables";
// import SearchIcon from "@material-ui/icons/Search";
// import { useForm } from "react-hook-form";
import * as couponService from "../../services/coupon";
// import { useDispatch, useSelector } from "react-redux";
import commonAction from "../../redux/Common/actions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const DetailCoupon = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        dispatch(commonAction.toggleLoading(true));
        couponService
            .detailCoupon(id)
            .then((res) => {
                if (res.data.data) {
                    setData(res.data.data);
                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };
    return (
        <div className="member-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" to="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Coupon detail</Typography>
                        <Typography color="textPrimary">{data?.CouponCode || "-"}</Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">Coupon Detail</h1>
                            <p>{data?.CouponName || "-"}</p>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                <Grid container spacing={0}>
                    <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input">
                                        Coupon code:{" "}
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.CouponCode || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input">
                                        Coupon name:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.CouponName || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input"
                                        style={{ width: "100%" }}
                                    >
                                        Channel:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.ChannelName || "-"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label
                                        className="text-semibold label-input"
                                        style={{ width: "100%" }}
                                    >
                                        Coupon type:
                                    </label>
                                </Grid>
                                <Grid item xs={6} className="flex items-center">
                                    {data?.CouponType == 0 ? "Cash" : "Amount"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input">
                                        {data?.CouponType == 0 ? "Cash amount:" : "Discount rate"}
                                    </label>
                                </Grid>
                                <Grid item xs={6} className="flex items-center">
                                    {data?.CouponType == 0
                                        ? (Math.round(data?.Amount * 100) / 100).toFixed(2)
                                        : data?.Discount}
                                    {data?.CouponType == 0 ? "" : "%"}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {/* <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex">
                                    <label className="text-semibold label-input mt-2">
                                        Applicable to specific store(s):
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                    <div>
                                        <StoreAddFormTable />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid> */}
                    <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input">
                                        Redemption conditions:
                                    </label>
                                </Grid>
                                <Grid item xs={6} className="flex items-center">
                                    <label className="mr-4 whitespace-nowrap ">
                                        Minimum purchase of {data?.MinimumPurchase || "-"}
                                    </label>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <label className="text-semibold label-input">
                                        Validity period:
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    {data?.ValidityPeriodType == 0
                                        ? "Relative date:"
                                        : "Specific date:"}
                                    <div className="mt-2 w-full">
                                        {data?.ValidityPeriodType == 0 ? (
                                            <div className="flex items-center h-full">
                                                {`${data?.StartFrom} day(s) after reception` ||
                                                    ""}{" "}
                                                <span className="mx-2">-</span>
                                                {`${data?.EndTo} day(s) after activation` ||
                                                    ""}
                                            </div>
                                        ) : (
                                            <div className="flex items-center h-full">
                                                {data?.EffectiveDateBegin || ""}{" "}
                                                <span className="mx-2">-</span>
                                                {data?.EffectiveDateEnd || ""}
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="form-group my-2 mt-4">
                            <Grid container spacing={2}>
                                <Grid item xs={3} className="flex items-center">
                                    <div className="form-group my-2 mt-4">
                                        <label className="text-semibold label-input">
                                            terms & conditions:
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item xs={6} className="flex items-center">
                                    <label className="mr-4 whitespace-nowrap ">
                                        {data?.TermAndConditions || "-"}
                                    </label>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default DetailCoupon;
