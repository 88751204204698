import React, { Component, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CakeIcon from "@material-ui/icons/Cake";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import MemoryIcon from "@material-ui/icons/Memory";
import ReceiptIcon from "@material-ui/icons/Receipt";
import RedeemIcon from "@material-ui/icons/Redeem";
import SettingsInputSvideoIcon from "@material-ui/icons/SettingsInputSvideo";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ShareIcon from "@material-ui/icons/Share";

// import AddModal from "./AddModal";

const LifecycleCampaign = () => {
    return (
        <div className="trigger-campaign-page">
            <div className="header-content py-2">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" to="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Lifecycle campaign</Typography>
                    </Breadcrumbs>
                </div>
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-semibold uppercase">Lifecycle campaign</h1>
                        <p>
                            Set automated campaigns to boost loyalty & engagement through member's
                            lifecycle
                        </p>
                    </div>
                    <div className="flex">{/* <AddModal /> */}</div>
                </div>
            </div>
            <div className="wrapper-paper" style={{ padding: "24px 16px", overflow: "hidden" }}>
                {/*---- Greetings ----*/}
                <Grid container spacing={2}>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <AccountBoxIcon />
                            </div>
                            <div className="inf">
                                <h3>New prospect</h3>
                                <p>
                                    Members who registered within the last [aa] months, but never
                                    made a transaction.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Details</Link>
                                </div>
                            </div>
                            <div className="stt active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <CakeIcon />
                            </div>
                            <div className="inf">
                                <h3>Sleeping prospect</h3>
                                <p>
                                    Members who registered more than [aa] months ago, but never made
                                    a transaction.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <FavoriteIcon />
                            </div>
                            <div className="inf">
                                <h3>First purchaser</h3>
                                <p>
                                    Members who recorded their first transaction in the last [bb]
                                    months.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <CardMembershipIcon />
                            </div>
                            <div className="inf">
                                <h3>Repeat purchaser</h3>
                                <p>
                                    Members with 2 to [x]-1 transactions recorded within the past
                                    [bb] months.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <CardMembershipIcon />
                            </div>
                            <div className="inf">
                                <h3>Re-purchaser</h3>
                                <p>
                                    Members who recorded 1 transaction in the last [bb] months &
                                    whose last transaction was recorded more than
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <CardMembershipIcon />
                            </div>
                            <div className="inf">
                                <h3>Loyal purchaser</h3>
                                <p>
                                    Members with x or more transactions recorded over the past [bb]
                                    months.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <CardMembershipIcon />
                            </div>
                            <div className="inf">
                                <h3>Sleeping purchaser</h3>
                                <p>
                                    Members with at least one transaction recorded but without any
                                    transaction recorded for more than [bb] rolling mo
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{ padding: 8 }}>
                        <div className="wrapper-campaign-box">
                            <div className="ic-box">
                                <CardMembershipIcon />
                            </div>
                            <div className="inf">
                                <h3>Lapsed purchaser</h3>
                                <p>
                                    Members with at least one transaction recorded but without any
                                    transaction recorded for [cc] months or more.
                                </p>
                                <div className="flex justify-end">
                                    <Link to="/">Inactive</Link>
                                </div>
                            </div>
                            <div className="stt in-active">Active</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default LifecycleCampaign;
