import DashboardIcon from "@material-ui/icons/Dashboard";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import PeopleIcon from "@material-ui/icons/People";
import RedeemIcon from "@material-ui/icons/Redeem";
import TelegramIcon from "@material-ui/icons/Telegram";
import React, { useEffect } from "react";
import {
    Menu,
    MenuItem,
    ProSidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SubMenu,
} from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logoText from "../../assets/images/logo-text.png";
import logo from "../../assets/images/logo.png";
import "../../assets/scss/object/component/sidebar/styles.scss";
import authActions from "../../redux/Auth/actions";
import { useTranslation } from "react-i18next";

const SidebarRight = () => {
    const { t } = useTranslation();
    const visible = useSelector((state) => state.commonReducer.sidebarLeft.active);
    const storeName = useSelector((state) => state.commonReducer.storeName);
    
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    useEffect(() => {
        getDataUser();
    }, []);

    const getDataUser = () => {
        dispatch(authActions.getUserProfile(1));
    };


    return (
        <>
            <ProSidebar collapsed={visible}>
                <SidebarHeader>
                    {!visible ? (
                        <div className="py-2">
                            <h3 className="text-2xl text-center">
                                <img
                                    src={logoText}
                                    className="object-contain px-2 py-1 mx-auto"
                                    style={{ height: 42 }}
                                ></img>
                            </h3>
                            <p className="text-base text-center">{storeName} </p>
                        </div>
                    ) : (
                        <div className="py-2 flex items-center" style={{ height: 72 }}>
                            <img src={logo} alt="logo" style={{ height: 40 }} className="mx-auto" />
                        </div>
                    )}
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <MenuItem
                            icon={<DashboardIcon style={{ color: "white", fontSize: 24 }} />}
                            active={splitLocation[1] === "dashboard"}
                            className="text-lg my-2"
                        >
                            {t("MGP_COMMON_LABEL_DASHBOARD")}
                            <Link to="/dashboard" />
                        </MenuItem>
                        <MenuItem
                            icon={<PeopleIcon style={{ color: "white", fontSize: 24 }} />}
                            active={splitLocation[1] === "member"}
                            className="text-lg my-2"
                        >
                            {t("MGP_SIDEBAR_LABEL_MEMBER")}
                            <Link to="/member" />
                        </MenuItem>
                        <SubMenu
                            title="Loyalty"
                            icon={<LoyaltyIcon style={{ color: "white", fontSize: 24 }} />}
                            className={`${splitLocation[1] === "loyalty" ? "active-parent" : ""}`}
                        >
                            <MenuItem
                                className={`${
                                    splitLocation[2] === "point-rule" ? "active-child" : ""
                                }`}
                            >
                                <Link to="/loyalty/point-rule" />
                                {t("MGP_SIDEBAR_LABEL_POINT_RULE")}
                            </MenuItem>
                            <MenuItem
                                className={`${
                                    splitLocation[2] === "tier-rule" ? "active-child" : ""
                                }`}
                            >
                                <Link to="/loyalty/tier-rule" />
                                {t("MGP_SIDEBAR_LABEL_TIER_RULE")}
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            title={t("MGP_SIDEBAR_LABEL_REWARD")}
                            icon={<RedeemIcon style={{ color: "white", fontSize: 24 }} />}
                            className={`${splitLocation[1] === "rewards" ? "active-parent" : ""}`}
                        >
                            <MenuItem
                                className={`${splitLocation[2] === "centre" ? "active-child" : ""}`}
                            >
                                <Link to="/rewards/centre" />
                                Centre
                            </MenuItem>
                            <MenuItem
                                className={`${
                                    splitLocation[2] === "redeem-coupon" ? "active-child" : ""
                                }`}
                            >
                                <Link to="/rewards/redeem-coupon" />
                                Redeem Coupon
                            </MenuItem>
                            <MenuItem
                                className={`${
                                    splitLocation[2] === "redeem-point" ? "active-child" : ""
                                }`}
                            >
                                <Link to="/rewards/redeem-point" />
                                Redeem Points
                            </MenuItem>
                            <MenuItem
                                className={`${
                                    splitLocation[2] === "voucher" ? "active-child" : ""
                                }`}
                            >
                                <Link to="/rewards/voucher" />
                                Vouchers
                            </MenuItem>
                            <MenuItem
                                className={`${splitLocation[2] === "gift" ? "active-child" : ""}`}
                            >
                                <Link to="/rewards/gift" />
                                Gifts
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            title={t("MGP_SIDEBAR_LABEL_CAMPAIGN")}
                            icon={<TelegramIcon style={{ color: "white", fontSize: 24 }} />}
                            className={`${splitLocation[1] === "campaign" ? "active-parent" : ""}`}
                        >
                            <MenuItem
                                className={`${splitLocation[2] === "spot" ? "active-child" : ""}`}
                            >
                                <Link to="/campaign/spot" />
                                Spot Campaign
                            </MenuItem>
                            <MenuItem
                                className={`${
                                    splitLocation[2] === "recurring" ? "active-child" : ""
                                }`}
                            >
                                <Link to="/campaign/recurring" />
                                Recurring Campaign
                            </MenuItem>
                            <MenuItem
                                className={`${
                                    splitLocation[2] === "trigger" ? "active-child" : ""
                                }`}
                            >
                                <Link to="/campaign/trigger" />
                                Trigger campaign
                            </MenuItem>
                            <MenuItem
                                className={`${
                                    splitLocation[2] === "lifecycle" ? "active-child" : ""
                                }`}
                            >
                                <Link to="/campaign/lifecycle" />
                                Lifecycle campaign
                            </MenuItem>
                            <MenuItem
                                className={`${splitLocation[2] === "coupon" ? "active-child" : ""}`}
                            >
                                <Link to="/campaign/coupon" />
                                Coupon
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <p className="text-sm text-center py-2">Setting</p>
                </SidebarFooter>
            </ProSidebar>
        </>
    );
};

export default SidebarRight;
