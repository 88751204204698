import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import TablePagination from "@material-ui/core/TablePagination";
import * as couponService from "../../../services/coupon";
import CouponCard from "./CouponCard";
import { CircularProgress, Fade } from "@material-ui/core";

const GridCoupon = forwardRef((props, ref) => {
    const history = useHistory();
    const [rowSelected, setRowSelected] = useState([]);
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 5,
        data: [],
        isLoading: false,
    });
    const directAdd = () => {
        history.push("/campaign/coupon/add");
    };

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setState({
            ...state,
            isLoading: true,
            data: [],
        });
        setPage(newPage);
        console.log(newPage);
        let model = {
            page: newPage + 1,
            take: state.rowsPerPage,
        };
        couponService
            .getListCouponService(model)
            .then((res) => {
                if (res.data.data) {
                    setState({
                        ...state,
                        page: newPage,
                        data: res.data.data.data,
                        isLoading: false,
                        count: res.data.data.total,
                        lastPage: res.data.data.last_page,
                    });
                }
            })
            .catch((err) => console.log(err))
            .finally(() =>
                setState({
                    ...state,
                    isLoading: false,
                })
            );
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getData = (page, numberOfRows) => {
        setState({
            ...state,
            isLoading: true,
        });
        let model = {
            page: state.page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        couponService
            .getListCouponService(model)
            .then((res) => {
                if (res.data.data) {
                    setState({
                        ...state,
                        data: res.data.data.data,
                        isLoading: false,
                        count: res.data.data.total,
                        lastPage: res.data.data.last_page,
                    });
                }
            })
            .catch((err) => console.log(err))
            .finally(() =>
                setState({
                    ...state,
                    isLoading: false,
                })
            );
    };

    useImperativeHandle(ref, () => ({
        deleteMultiCoupon() {
            setState({
                ...state,
                data: [],
                isLoading: true,
            });
            if (rowSelected.length > 0) {
                const arrId = rowSelected.map((item) => item.Id);
                const model = {
                    list_of_id: arrId,
                };
                couponService.deleteCoupon(model).then((res) =>
                    setState({
                        ...state,
                        data: res.data.data.data,
                        isLoading: false,
                        count: res.data.data.total,
                        lastPage: res.data.data.last_page,
                    })
                );
            }

            // getData();
        },
    }));

    const handleSetRowSelected = (dt) => {
        const idx = rowSelected
            .map(function (item) {
                return item.Id;
            })
            .indexOf(dt.Id);

        console.log(idx);

        const cloneArr = [...rowSelected];
        if (idx >= 0) {
            cloneArr.splice(idx, 1);
        } else {
            cloneArr.push(dt);
        }
        setRowSelected(cloneArr);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="wrapper-grid" style={{ padding: "24px 24px 16" }}>
            <div className="list-item-coupon grid grid-cols-3 gap-6">
                <div
                    className="items item-add-coupon flex justify-center items-center"
                    onClick={directAdd}
                >
                    <AddCircleOutlineIcon color="primary" />
                </div>
                {state.isLoading && (
                    <div className="flex justify-center items-center">
                        <CircularProgress size={35} style={{ position: "relative", top: 0 }} />
                    </div>
                )}
                {state.data &&
                    state.data.map((item) => (
                        <Fade in={Boolean(item)} timeout={1000}>
                            <div>
                                <CouponCard
                                    data={item}
                                    handleSetRowSelected={handleSetRowSelected}
                                />
                            </div>
                        </Fade>
                    ))}
            </div>
            <TablePagination
                component="div"
                count={state.count}
                page={state.page}
                onPageChange={handleChangePage}
                rowsPerPage={state.rowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20]}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
});

export default GridCoupon;
