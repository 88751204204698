import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as memberService from "../../../services/member";
import AvatarImg from "../../../assets/images/avatar-example.png";
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import { useQueryParams, StringParam } from "use-query-params";
import SearchIcon from "@material-ui/icons/Search";
import { useForm, Controller } from "react-hook-form";
import CachedIcon from "@material-ui/icons/Cached";
import * as commonService from "../../../services/common";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {
    Button,
    Grid,
    CircularProgress,
    FormLabel,
    Select,
    FormControl,
    FormControlLabel,
    NativeSelect,
    FormHelperText,
    RadioGroup,
    Radio,
    Checkbox,
    IconButton,
} from "@material-ui/core";
import DaterangePicker from "../../../components/DaterangePicker";
import moment from "moment";
import { DayPicker, MonthPicker } from "react-dropdown-date";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";

const MemberTable = (props) => {
    const { t } = useTranslation();
    const [pickerStatus, setPickerStatus] = useState({
        registerDateFrom: false,
        registerDateTo: false,
    });
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "PhotoUrl",
                options: {
                    filter: true,
                    sort: false,
                    empty: true,
                    setCellProps: () => ({
                        align: "center",
                    }),
                    customHeadLabelRender: () => null,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="avatar">
                                <img src={`${value || AvatarImg}`} alt="avatar" />
                            </div>
                        );
                    },
                },
            },
            {
                name: "MemberCode",
                label: t("MGP_MEMBER_TABLE_COLUMN_NO"),
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_LAST_NAME"),
                name: "LastName",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                name: "FirstName",
                label: t("MGP_MEMBER_TABLE_COLUMN_FIRST_NAME"),
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_GENDER"),
                name: "Gender",
                options: {
                    filter: true,
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_BIRTH_YEAR"),
                name: "DOB",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value || "-"}</span>;
                    },
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_MEMBER"),
                name: "TierName",
                options: {
                    setCellProps: () => ({
                        align: "center",
                    }),
                    setCellHeaderProps: (value) => ({
                        className: "centeredHeaderCell",
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value || "-"}</span>;
                    },
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_POINT"),
                name: "Point",
                options: {
                    filter: true,
                    sort: true,

                    setCellProps: () => ({
                        align: "center",
                    }),
                    setCellHeaderProps: (value) => ({
                        className: "centeredHeaderCell",
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>;
                    },
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_REGISTER_DATE"),
                name: "RegisterDate",
                options: {
                    filter: true,
                    sort: true,
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_PHONE_NUMBER"),
                name: "PhoneNumber",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span className="whitespace-nowrap">{value || "-"}</span>;
                    },
                },
            },
            {
                label: t("MGP_MEMBER_TABLE_COLUMN_EMAIL"),
                name: "Email",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value || "-"}</span>;
                    },
                },
            },
            {
                name: "Delete",
                options: {
                    filter: true,
                    sort: false,
                    empty: true,
                    setCellProps: () => ({
                        align: "center",
                    }),
                    // setCellHeaderProps: (value) => ({
                    //     className: "centeredHeaderCell",
                    // }),
                    customHeadLabelRender: () => null,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(`/member/${tableMeta.rowData[0]}`);
                                }}
                            >
                                {t("MGP_COMMON_TABLE_ACTION_VIEW")}
                            </Button>
                        );
                    },
                },
            },
        ],
        isLoading: false,
    });
    const [visibleAdvanceSearch, setVisibleAdvanceSearch] = useState(false);

    const [query, setQuery] = useQueryParams({
        keyword: StringParam,
        first_name: StringParam,
        member_code: StringParam,
        tier: StringParam,
        birthday_from: StringParam,
        birthday_to: StringParam,
        point_from: StringParam,
        point_to: StringParam,
        province_id: StringParam,
        register_date_from: StringParam,
        register_date_to: StringParam,
        year_from: StringParam,
        year_to: StringParam,
        day_from: StringParam,
        day_to: StringParam,
        month_from: StringParam,
        month_to: StringParam,
        phone: StringParam,
        email: StringParam,
    });
    const [dataProvince, setDataProvince] = useState([]);

    const getDataProvince = () => {
        commonService
            .getListProvince()
            .then((res) => {
                if (res.data.data) {
                    setDataProvince([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            keyword: query.keyword,
            first_name: query.first_name,
            member_code: query.member_code,
            birthday_from: query.birthday_from,
            birthday_to: query.birthday_to,
            register_date_from: query.register_date_from,
            register_date_to: query.register_date_to,
            tier: query.tier,
            point_from: query.point_from,
            point_to: query.point_to,
            province_id: query.province_id,
            year_from: query.year_from,
            year_to: query.year_to,
            day_from: query.day_from,
            day_to: query.day_to,
            month_from: query.month_from ? (parseInt(query.month_from) - 1).toString() : "",
            month_to: query.month_to ? (parseInt(query.month_to) - 1).toString() : "",
            phone: query.phone,
            email: query.email,
        },
    });

    // const { x: num, q: searchQuery, filters } = query;

    const onSubmit = (data) => {
        // setQuery({ foo: 123, bar: 'zzz' }, 'push');
        console.log(data);
        setQuery(
            {
                keyword: data.keyword || undefined,
                first_name: data.first_name || undefined,
                member_code: data.member_code || undefined,
                tier: data.tier || undefined,
                birthday_from: data.birthday_from || undefined,
                birthday_to: data.birthday_to || undefined,
                point_from: data.point_from || undefined,
                point_to: data.point_to || undefined,
                province_id: data.province_id || undefined,
                register_date_from: data.register_date_from || undefined,
                register_date_to: data.register_date_to || undefined,
                year_from: data.year_from || undefined,
                year_to: data.year_to || undefined,
                day_from: data.day_from && data.day_from != "day" ? data.day_from : undefined,
                day_to: data.day_to && data.day_to != "day" ? data.day_to : undefined,
                month_from:
                    data.month_from && data.month_from != "NaN" && data.month_from != "month"
                        ? (parseInt(data.month_from) + 1).toString()
                        : undefined,
                month_to:
                    data.month_to && data.month_to != "NaN" && data.month_to != "month"
                        ? (parseInt(data.month_to) + 1).toString()
                        : undefined,
                phone: data.phone || undefined,
                email: data.email || undefined,
            },
            "push"
        );
    };

    useEffect(() => {
        getDataProvince();
    }, []);
    useEffect(() => {
        if (query) {
            // changeRowsPerPage(state.sortOrder, state.numberOfRows, query);
            setState({ ...state, isLoading: true });
            xhrRequest(0, state.sortOrder, state.numberOfRows, query).then((res) => {
                setState({
                    ...state,
                    isLoading: false,
                    sortOrder,
                    rowsPerPage: state.rowsPerPage,
                    data: res.data.data.data,
                    search: state.search,
                    lastPage: res.data.data.last_page,
                    count: res.data.data.total,
                });
                props.handelSetTotal(res.data.data.total);
            });
        } else {
            getData();
        }
    }, [query]);

    const history = useHistory();
    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        setState({
            ...state,
            data: res.data.data.data,
            isLoading: false,
            count: res.data.data.total,
            lastPage: res.data.data.last_page,
        });
        props.handelSetTotal(res.data.data.total);
    };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }

        Object.keys(search).forEach(
            (key) => search[key] === undefined || (search[key] === "" && delete search[key])
        );

        if (search) {
            model = { ...model, ...search };
        } else {
            model = { ...model, ...getValues() };
        }
        // setState({ ...state, page: page + 1 });
        return memberService.getListMemberService(model);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                page: page,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(state.page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
            props.handelSetTotal(res.data.data.total);
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, query).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage } = state;
    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        selectableRows: "none",
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],

        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(sortOrder, numberOfRows, query),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, state.sortOrder, state.rowsPerPage, query);
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
    };
    const search = () => {
        setQuery(
            {
                keyword: state.search || undefined,
            },
            "push"
        );
    };

    const handleChangeSearch = (event) => {
        setState({ ...state, search: event.target.value });
    };

    const customTheme = () =>
        createTheme({
            overrides: {
                MUIDataTableHeadCell: {
                    data: {
                        whiteSpace: "no-wrap",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                    },
                },
                MuiTableCell: {
                    head: {
                        backgroundColor: "#3f51b512 !important",
                        borderBottom: "1px solid #3f51b540",
                        marginTop: 24,
                        color: "red",
                    },
                },
            },
        });

    const handleClickCheckBoxTier = (event) => {
        if (event.target.value === watch("tier")) {
            setValue("tier", "");
        } else {
            setValue("tier", event.target.value);
        }
    };

    const handleChangeDaterange = (range) => {
        setValue("birthday_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("birthday_to", moment(range.endDate).format("YYYY-MM-DD"));

        if (type == "registerDay") {
            setValue("register_date_from", moment(range.startDate).format("YYYY-MM-DD"));
            setValue("register_date_to", moment(range.endDate).format("YYYY-MM-DD"));
        }
    };

    const handleChangeRegisterDaterange = (range) => {
        setValue("register_date_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("register_date_to", moment(range.endDate).format("YYYY-MM-DD"));
    };

    const resetValue = () => {
        setValue("keyword", "");
        setValue("first_name", "");
        setValue("member_code", "");
        setValue("tier", "");
        setValue("birthday_from", "");
        setValue("birthday_to", "");
        setValue("register_date_from", "");
        setValue("register_date_to", "");
        setValue("point_from", "");
        setValue("point_to", "");
        setValue("province_id", "");
        setValue("year_from", "");
        setValue("year_to", "");
        setValue("day_from", "");
        setValue("day_to", "");
        setValue("month_from", "");
        setValue("month_to", "");
        setValue("phone", "");
        setValue("email", "");
        setQuery({
            keyword: undefined,
            first_name: undefined,
            member_code: undefined,
            tier: undefined,
            birthday_from: undefined,
            birthday_to: undefined,
            register_date_from: undefined,
            register_date_to: undefined,
            point_from: undefined,
            point_to: undefined,
            province_id: undefined,
            year_from: undefined,
            year_to: undefined,
            day_from: undefined,
            day_to: undefined,
            month_from: undefined,
            month_to: undefined,
            phone: undefined,
            email: undefined,
        });
    };

    const handleDateChange = (e, key) => {
        setValue(key, e.format("YYYY-MM-DD"));
    };

    return (
        <div className="wrapper-table">
            <div className="wrapper-advance-search">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="search-table flex items-center">
                        <input
                            className="p-2 w-5/12 mr-3"
                            placeholder={`${t("MGP_COMMON_LABEL_SEARCH")}...`}
                            {...register("keyword")}
                        />
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            style={{ minHeight: 42 }}
                            startIcon={<SearchIcon />}
                            disabled={visibleAdvanceSearch}
                            type="submit"
                            value="Search"
                        >
                            {t("MGP_COMMON_LABEL_SEARCH")}
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            style={{ minHeight: 42, marginLeft: 8 }}
                            startIcon={<SearchIcon />}
                            onClick={(e) => {
                                e.stopPropagation();
                                setVisibleAdvanceSearch(!visibleAdvanceSearch);
                                // resetValue();
                            }}
                        >
                            {t("MGP_COMMON_LABEL_SEARCH_ADVANCE")}
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                style={{ marginLeft: 15, position: "relative", top: 4 }}
                            />
                        )}
                    </div>
                    {visibleAdvanceSearch && (
                        <div className="advance-search-box pb-8">
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <div className="form-group mt-4">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    {t("MGP_MEMBER_TABLE_COLUMN_NO")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <input
                                                    className="p-2 w-full"
                                                    {...register("member_code")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group mt-4">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input flex items-center text-right">
                                                    {t("MGP_MEMBER_TABLE_COLUMN_FIRST_NAME")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <input
                                                    className="p-2 w-full"
                                                    {...register("first_name")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input flex items-center text-right">
                                                    {t("MGP_MEMBER_TABLE_COLUMN_PHONE_NUMBER")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <input
                                                    className="p-2 w-full"
                                                    {...register("phone")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input flex items-center text-right">
                                                    {t("MGP_MEMBER_TABLE_COLUMN_EMAIL")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <input
                                                    className="p-2 w-full"
                                                    type="email"
                                                    {...register("email")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    Birthday:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <DaterangePicker
                                                    onChange={handleChangeDaterange}
                                                    defaultValue={{
                                                        date: {
                                                            specific_date_from:
                                                                watch("birthday_from"),
                                                            specific_date_to: watch("birthday_to"),
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid> */}
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    {t("MGP_MEMBER_ADVANCE_SEARCH_DAY_OF_BIRTH")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <div className="flex justify-between items-center">
                                                    <div className="custom-date-picker-group grid gap-3 grid-cols-2 w-full">
                                                        <Controller
                                                            name="day_from"
                                                            control={control}
                                                            render={({
                                                                field: { onChange, value, ref },
                                                            }) => (
                                                                <DayPicker
                                                                    defaultValue={"day"}
                                                                    year="1999" // mandatory
                                                                    month="3" // mandatory
                                                                    endYearGiven
                                                                    value={value} // mandatory
                                                                    onChange={onChange}
                                                                    id={"day"}
                                                                    name={"day"}
                                                                    classes={"classes"}
                                                                    optionClasses={"option classes"}
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            name="month_from"
                                                            control={control}
                                                            render={({
                                                                field: { onChange, value, ref },
                                                            }) => (
                                                                <MonthPicker
                                                                    defaultValue={"month"}
                                                                    numeric // to get months as numbers
                                                                    short // default is full name
                                                                    caps // default is Titlecase
                                                                    endYearGiven // mandatory if end={} is given in YearPicker
                                                                    year={watch("year")}
                                                                    value={value} // mandatory
                                                                    onChange={onChange}
                                                                    id={"month"}
                                                                    name={"month"}
                                                                    classes={"classes"}
                                                                    optionClasses={"option classes"}
                                                                />
                                                            )}
                                                        />

                                                        {/* <DatePickerGroupSelect handleDateChange={handleDateChange} /> */}
                                                    </div>
                                                    <ArrowRightAltIcon
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                    <div className="custom-date-picker-group grid gap-3 grid-cols-2 w-full">
                                                        <Controller
                                                            name="day_to"
                                                            control={control}
                                                            render={({
                                                                field: { onChange, value, ref },
                                                            }) => (
                                                                <DayPicker
                                                                    defaultValue={t(
                                                                        "MGP_COMMON_LABEL_DAY"
                                                                    )}
                                                                    year="1999" // mandatory
                                                                    month="3" // mandatory
                                                                    endYearGiven
                                                                    value={value} // mandatory
                                                                    onChange={onChange}
                                                                    id={"day"}
                                                                    name={"day"}
                                                                    classes={"classes"}
                                                                    optionClasses={"option classes"}
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            name="month_to"
                                                            control={control}
                                                            render={({
                                                                field: { onChange, value, ref },
                                                            }) => (
                                                                <MonthPicker
                                                                    defaultValue={t(
                                                                        "MGP_COMMON_LABEL_MONTH"
                                                                    )}
                                                                    numeric // to get months as numbers
                                                                    short // default is full name
                                                                    caps // default is Titlecase
                                                                    endYearGiven // mandatory if end={} is given in YearPicker
                                                                    year={watch("year")}
                                                                    value={value} // mandatory
                                                                    onChange={onChange}
                                                                    id={"month"}
                                                                    name={"month"}
                                                                    classes={"classes"}
                                                                    optionClasses={"option classes"}
                                                                />
                                                            )}
                                                        />

                                                        {/* <DatePickerGroupSelect handleDateChange={handleDateChange} /> */}
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    {t("MGP_MEMBER_TABLE_COLUMN_BIRTH_YEAR")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <div className="flex justify-between items-center">
                                                    <input
                                                        className="p-2 w-full mr-3"
                                                        type="number"
                                                        min={0}
                                                        maxLength={4}
                                                        pattern="[+-]?\d+(?:[.,]\d+)?"
                                                        {...register("year_from")}
                                                    />
                                                    <ArrowRightAltIcon />
                                                    <input
                                                        className="p-2 w-full ml-3"
                                                        type="number"
                                                        min={0}
                                                        maxLength={4}
                                                        pattern="[+-]?\d+(?:[.,]\d+)?"
                                                        {...register("year_to")}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    {t("MGP_MEMBER_TABLE_COLUMN_MEMBER")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Controller
                                                    name="tier"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, ref },
                                                    }) => (
                                                        <RadioGroup
                                                            aria-label="gender"
                                                            name="gender1"
                                                            className="flex"
                                                            style={{ flexDirection: "row" }}
                                                        >
                                                            <FormControlLabel
                                                                value="1"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={watch("tier") == 1}
                                                                        onClick={
                                                                            handleClickCheckBoxTier
                                                                        }
                                                                    />
                                                                }
                                                                label="Silver"
                                                            />
                                                            <FormControlLabel
                                                                value="2"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={watch("tier") == 2}
                                                                        onClick={
                                                                            handleClickCheckBoxTier
                                                                        }
                                                                    />
                                                                }
                                                                label="Gold"
                                                            />
                                                            <FormControlLabel
                                                                value="3"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={watch("tier") == 3}
                                                                        onClick={
                                                                            handleClickCheckBoxTier
                                                                        }
                                                                    />
                                                                }
                                                                label="Diamond"
                                                            />
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    {t("MGP_MEMBER_TABLE_COLUMN_POINT")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <div className="flex justify-between items-center">
                                                    <input
                                                        className="p-2 w-full mr-3"
                                                        type="number"
                                                        {...register("point_from")}
                                                    />
                                                    <ArrowRightAltIcon />
                                                    <input
                                                        className="p-2 w-full ml-3"
                                                        type="number"
                                                        {...register("point_to")}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    {t("MGP_MEMBER_TABLE_COLUMN_REGISTER_DATE")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <div className="flex items-center">
                                                    <div className="custom-date-picker">
                                                        <MuiPickersUtilsProvider
                                                            utils={MomentUtils}
                                                        >
                                                            <KeyboardDatePicker
                                                                inputProps={{ readOnly: true }}
                                                                margin="normal"
                                                                inputVariant="outlined"
                                                                id="date-picker-dialog"
                                                                format="DD/MM/yyyy"
                                                                value={
                                                                    watch("register_date_from")
                                                                        ? watch(
                                                                              "register_date_from"
                                                                          )
                                                                        : null
                                                                }
                                                                InputAdornmentProps={{
                                                                    position: "start",
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setValue(
                                                                                    "register_date_from",
                                                                                    null
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    ),
                                                                }}
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "register_date_from"
                                                                    )
                                                                }
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                onClick={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        registerDateFrom: true,
                                                                    })
                                                                }
                                                                onClose={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        registerDateFrom: false,
                                                                    })
                                                                }
                                                                open={pickerStatus.registerDateFrom}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <ArrowRightAltIcon
                                                        style={{ margin: "0 8px" }}
                                                    />
                                                    <div className="custom-date-picker">
                                                        <MuiPickersUtilsProvider
                                                            utils={MomentUtils}
                                                        >
                                                            <KeyboardDatePicker
                                                                inputProps={{ readOnly: true }}
                                                                margin="normal"
                                                                inputVariant="outlined"
                                                                id="date-picker-dialog"
                                                                format="DD/MM/yyyy"
                                                                value={
                                                                    watch("register_date_to")
                                                                        ? watch("register_date_to")
                                                                        : null
                                                                }
                                                                InputAdornmentProps={{
                                                                    position: "start",
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setValue(
                                                                                    "register_date_to",
                                                                                    null
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    ),
                                                                }}
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "register_date_to"
                                                                    )
                                                                }
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                onClick={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        registerDateTo: true,
                                                                    })
                                                                }
                                                                onClose={() =>
                                                                    setPickerStatus({
                                                                        ...pickerStatus,
                                                                        registerDateTo: false,
                                                                    })
                                                                }
                                                                open={pickerStatus.registerDateTo}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-group">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={3}
                                                className="flex items-center justify-end"
                                            >
                                                <label className="text-semibold label-input text-right">
                                                    {t("MGP_MEMBER_ADVANCE_SEARCH_PROVINCE")}:{" "}
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Controller
                                                    name="province_id"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, ref },
                                                    }) => (
                                                        <FormControl style={{ width: "100%" }}>
                                                            <NativeSelect
                                                                value={value}
                                                                onChange={onChange}
                                                                name="province_id"
                                                                inputRef={ref}
                                                                variant="outlined"
                                                                className="custom-select"
                                                            >
                                                                <option
                                                                    aria-label="None"
                                                                    value=""
                                                                />
                                                                {dataProvince.map((item, index) => (
                                                                    <option
                                                                        value={item.Id}
                                                                        key={index}
                                                                    >
                                                                        {item.Name}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </FormControl>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>

                            <div className="flex justify-end">
                                <Button
                                    style={{ marginTop: 8, textTransform: "capitalize" }}
                                    variant="contained"
                                    size="medium"
                                    className="btn-primary-filled mt-2 text-center"
                                    // disabled={loading}
                                    // onClick={() => setVisibleAdvanceSearch(false)}
                                    startIcon={<CachedIcon />}
                                    onClick={() => resetValue()}
                                >
                                    Reset
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{
                                        marginTop: 8,
                                        marginLeft: 12,
                                        textTransform: "capitalize",
                                    }}
                                    startIcon={<SearchIcon />}
                                    type="submit"
                                    value="Search"
                                >
                                    {t("MGP_COMMON_LABEL_SEARCH")}
                                </Button>
                                {/* {loading && (
                                        <CircularProgress
                                            size={24}
                                            style={{ position: "relative", top: 16 }}
                                        />
                                    )} */}
                            </div>
                        </div>
                    )}
                </form>
            </div>
            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable data={data} columns={state.columns} options={options} />
            </MuiThemeProvider>

            {/* <Typography>Page: {state.page + 1}</Typography>
            <Pagination count={lastPage} page={state.page + 1} onChange={handleChange} /> */}
        </div>
    );
};

export default MemberTable;
