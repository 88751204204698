import actions from "./actions";

const initState = {
    auth: {
        token: null,
        isAuthenticated: false,
        userProfile: {},
    },
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                auth: { ...state.auth, token: action.payload, isAuthenticated: true },
            };
        case actions.LOGIN_ERROR: {
            return { ...state, auth: { ...state.auth, token: null, isAuthenticated: false } };
        }
        case actions.GET_USER_PROFILE_SUCCESS: {
            return { ...state, auth: { ...state.auth, userProfile: action.payload } };
        }
        case actions.GET_USER_PROFILE_ERROR: {
            return { ...state, auth: { ...state.auth, userProfile: {} } };
        }
        default:
            return state;
    }
}
