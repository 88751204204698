import * as authService from "../../services/auth";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import commonActions from "../Common/actions";
import { clearToken, setToken, getToken, setUserID } from "../../utils/actionUtil";
import * as commonService from "../../services/common";
import appConstant from "../../constants/app";
import { ControlCameraOutlined } from "@material-ui/icons";

export function* loginSaga() {
    yield takeEvery(actions.LOGIN, function* ({ payload }) {
        try {
            const response = yield call(authService.loginService, payload);
            console.log(response.data);
            if (response.data.token || response.data.user_id) {
                if (response.data.token) {
                    setToken(response.data.token);
                    yield put({
                        type: actions.LOGIN_SUCCESS,
                        payload: response.data.token,
                    });
                    window.location.reload();
                } else if (response.data.user_id) {
                    setUserID(response.data.user_id);
                    window.location = "#/select-store";
                }
                // window.location.href = appConstant.ROOT_URL + "dashboard";
            }
        } catch (e) {
            yield put({ type: actions.LOGIN_ERROR });
        }
    });
}

export function* loginStoreSaga() {
    yield takeEvery(actions.LOGIN_STORE, function* ({ payload }) {
        try {
            const response = yield call(authService.loginStoreService, payload);
            if (response.data.token) {
                setToken(response.data.token);
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    payload: response.data.token,
                });
                window.location.reload();
                // window.location.href = appConstant.ROOT_URL + "dashboard";
            }
        } catch (e) {
            yield put({ type: actions.LOGIN_ERROR });
        }
    });
}

export function* userProfileSaga() {
    yield takeEvery(actions.GET_USER_PROFILE, function* ({ payload }) {
        try {
            const storeName = yield call(commonService.getStoreName);
            // localStorage.setItem("storeName", storeName.data.data.StoreName);
            const response = yield call(authService.userProfileService, payload);
            if (response.data) {
                yield put({
                    type: actions.GET_USER_PROFILE_SUCCESS,
                    payload: response.data.data,
                });
                yield put({
                    type: commonActions.STORE_NAME,
                    payload: storeName.data.data.StoreName,
                });
            }
        } catch (e) {
            yield put({ type: actions.GET_USER_PROFILE_ERROR });
        }
    });
}

export default function* rootSaga() {
    yield all([fork(loginSaga), fork(userProfileSaga), fork(loginStoreSaga)]);
}
