import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as memberService from "../../services/member";
import actions from "./actions";

export function* getBrandsListSaga() {
    yield takeEvery(actions.GET_NEW_MEMBERS, function* ({ payload }) {
        const { model } = payload;
        try {
            const response = yield call(memberService.getListMemberService, model);
            if (response.data.success) {
                const { data, total } = response.data.data;
                yield put({
                    type: actions.GET_NEW_MEMBERS_SUCCESS,
                    payload: { data, total },
                });
            }
        } catch (e) {
            yield put({
                type: actions.GET_NEW_MEMBERS_ERROR,
            });
        }
    });
}

export default function* rootSaga() {
    yield all([fork(getBrandsListSaga)]);
}
