import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import InfoIcon from "@material-ui/icons/Info";
import {
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress,
    Select,
    NativeSelect,
    FormHelperText,
    Button,
    Breadcrumbs,
    Typography,
    Link,
    TextField,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ImageIcon from "@material-ui/icons/Image";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import * as memberService from "../../services/member";
import * as voucherService from "../../services/voucher";
import * as commonService from "../../services/common";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import Quantity from "../../components/Quantity";
import BrandTable from "./Table/BrandTable";
import CategoryTable from "./Table/CategoryTable";
import SubcategoryTable from "./Table/SubcategoryTable";
import ProductTable from "./Table/ProductTable";
import StoreTable from "./Table/StoreTable";

import BrandModal from "./Modal/BrandModal";
import CategoryModal from "./Modal/CategoryModal";
import SubCategoryModal from "./Modal/SubCategoryModal";
import ProductModal from "./Modal/ProductModal";
import StoreModal from "./Modal/StoreModal";
import { couponType, validityPeriod } from "../../constants/app";
import DaterangePicker from "../../components/DaterangePicker";
import { useSelector } from "react-redux";
import moment from "moment";
import SubCategoryTable from "./Table/SubcategoryTable";
import ImageUploading from "react-images-uploading";
import VoucherCategoryModal from "./Modal/VoucherCategoryModal";
import { useTranslation } from "react-i18next";

function Add(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const history = useHistory();
    const [images, setImages] = useState([]);
    const maxNumber = 1;

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            // applicable_stores: "",
            voucher_code_auto: "",
            amount: 0,
            voucher_category: "",
            start_from: 0,
            end_to: 0,
            channel_id: 1,
            template: 1,
            validity_period_type: 0,
            voucher_type: 0,
            redemption_conditions: 0,
            minimum_timming: false,
            minimum_purchase: false,
            minimum_number: false,
            redemption_validity_begin: 0,
            redemption_validity_end: 0,
        },
    });
    const [codeGenerate, setCodeGenerate] = useState(false);
    const [availableStockNoLimit, setAvailableStockNoLimit] = useState(false);
    const [dataVoucherCat, setDataVoucherCat] = useState([]);

    const [address, setAddress] = useState({
        province: "",
        district: "",
    });

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    const [dataChannel, setDataChannel] = useState([]);

    useEffect(() => {
        getDataChannel();
        getAllVoucherCategory();
    }, []);

    const getAllVoucherCategory = () => {
        voucherService
            .getAllVoucherCategoryService()
            .then((res) => {
                if (res.data.data) {
                    setDataVoucherCat([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const getDataChannel = () => {
        commonService
            .getListChannel()
            .then((res) => {
                if (res.data.data) {
                    setDataChannel([...res.data.data]);
                }
            })
            .catch((err) => console.log(err));
    };

    const onSubmit = (data) => {
        setLoading(true);
        // if (dataStoreSelected.length > 0) {
        //     data.applicable_stores = dataStoreSelected.map((obj) => obj.Id);
        // }

        if (data.voucher_code_auto && data.voucher_code == "") {
            data.voucher_code = data.voucher_code_auto;
            delete data.voucher_code_auto;
        } else {
            delete data.voucher_code_auto;
        }

        if (data.voucher_type == 0) {
            delete data.discount;
        } else {
            delete data.amount;
        }

        // TODO: change available_stock to nolimit
        if (availableStockNoLimit) {
            data.available_stock = 1;
        }
        const fd = new FormData();
        for (var key in data) {
            fd.append(key, data[key]); // formdata doesn't take objects
        }

        if (images && images.length > 0) {
            fd.append("template_banner", images[0].file, images[0].file.name);
        }

        voucherService
            .addVoucherService(fd)
            .then((res) => {
                toast.success("Add Voucher successful!");
                setLoading(false);
                history.push("/rewards/voucher");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleGenerateCode = (e) => {
        setCodeGenerate(e.target.checked);
        if (e.target.checked == true) {
            // couponService
            //     .getGenerateCode()
            //     .then((res) => setValue("voucher_code_auto", res.data.data))
            //     .catch((err) => console.log(err));
            setValue("voucher_code", "");
        } else {
            setValue("voucher_code", "");
        }
    };

    const handleSetAvailableStockNoLimit = (e) => {
        setAvailableStockNoLimit(e.target.checked);
        if (e.target.checked == true) {
            // couponService
            //     .getGenerateCode()
            //     .then((res) => setValue("voucher_code_auto", res.data.data))
            //     .catch((err) => console.log(err));
            setValue("available_stock", "");
        } else {
            setValue("available_stock", "");
        }
    };

    const directBack = (e) => {
        e.preventDefault();
        history.push("/campaign/coupon");
    };

    const handleChangeAddress = (event) => {
        const name = event.target.name;

        if (name == "province") {
            setValue("province_id", event.target.value);
            setAddress({
                [name]: event.target.value,
                district: "",
            });
            setDDataistrict([]);
            setValue("district", "");
        } else {
            setValue("district_id", event.target.value);
            setAddress({
                ...address,
                [name]: event.target.value,
            });
        }
    };
    const handleChangeJobTitle = (event) => {
        // const name = event.target.name;
        setValue("jobTitle_id", event.target.value);
        setJobTitle(event.target.value);
    };

    const isExistCode = (code) => !code && !getValues("voucher_code_auto");

    const handleChangeDaterange = (range) => {
        setValue("specific_date_from", moment(range.startDate).format("YYYY-MM-DD"));
        setValue("specific_date_to", moment(range.endDate).format("YYYY-MM-DD"));
    };

    useEffect(() => {
        if (watch("validity_period_type") == 1) {
            setValue("start_from", 0);
            setValue("end_to", 0);
        } else {
            setValue("specific_date_from", undefined);
            setValue("specific_date_to", undefined);
        }
    }, [watch("validity_period_type")]);

    useEffect(() => {
        setValue("redemption_validity_begin", "");
        setValue("redemption_validity_end", "");
    }, [watch("minimum_timming")]);

    useEffect(() => {
        setValue("redemption_validity", "");
    }, [watch("minimum_purchase")]);

    useEffect(() => {
        setValue("minimum_item_purchase", "");
    }, [watch("minimum_number")]);

    return (
        <div className="member-page">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            {t("MGP_SIDEBAR_LABEL_HOME")}
                        </Link>
                        <Link color="inherit" href="/#/rewards/voucher">
                            {t("MGP_VOUCHERS_TITLE")}
                        </Link>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">
                                {t("MGP_VOUCHERS_DETAIL_BUTTON_ADD")}
                            </h1>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            {t("MGP_VOUCHERS_LABEL_VOUCHER_TEMPLATE")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="template"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="template"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        <option value="1">Text voucher</option>
                                                        <option value="2">QR code voucher</option>
                                                        <option value="3">Barcode voucher</option>
                                                        <option value="4">Both barcode and QR code</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            {t("MGP_VOUCHERS_TABLE_COLUMN_VOUCHER_CODE")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("voucher_code")}
                                            disabled={codeGenerate}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="form-group  h-full">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={codeGenerate}
                                                        onChange={handleGenerateCode}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label={t(
                                                    "MGP_VOUCHERS_LABEL_VOUCHER_AUTO_GENERATE_CODE"
                                                )}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            {t("MGP_VOUCHERS_TABLE_COLUMN_VOUCHER_NAME")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("voucher_name", { required: true })}
                                        />
                                        {errors.coupon_name && (
                                            <span className="text-error">
                                                This field is required
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            {t("MGP_VOUCHERS_LABEL_VOUCHER_CATEGORY")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="flex items-center">
                                            <Controller
                                                name="voucher_category"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => (
                                                    <FormControl style={{ width: "100%" }}>
                                                        <NativeSelect
                                                            value={value}
                                                            onChange={onChange}
                                                            name="voucher_category"
                                                            inputRef={ref}
                                                            variant="outlined"
                                                            className="custom-select"
                                                        >
                                                            <option value=""></option>
                                                            {dataVoucherCat
                                                                ? dataVoucherCat.map(
                                                                      (item, index) => (
                                                                          <option
                                                                              value={item?.Id}
                                                                              key={index}
                                                                          >
                                                                              {item?.VoucherName}
                                                                          </option>
                                                                      )
                                                                  )
                                                                : ""}
                                                        </NativeSelect>
                                                    </FormControl>
                                                )}
                                            />
                                            <VoucherCategoryModal
                                                handleGetAllVoucherCategory={getAllVoucherCategory}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            {t("MGP_VOUCHERS_TABLE_COLUMN_VOUCHER_TYPE")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} className="flex items-center">
                                        <Controller
                                            name="voucher_type"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="jobTitle_id"
                                                        inputProps={{ "aria-label": "province" }}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {couponType.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            {watch("voucher_type") == 0
                                                ? "Cash amount:"
                                                : "Discount rate"}
                                        </label>
                                    </Grid>
                                    <Grid item xs={6} className="flex items-center">
                                        {watch("voucher_type") == 0 && (
                                            <Controller
                                                name="amount"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => {
                                                    return (
                                                        <Quantity
                                                            type="currency"
                                                            onChange={onChange}
                                                            value={value}
                                                            name="amount"
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                        {watch("voucher_type") == 1 && (
                                            <Controller
                                                name="discount"
                                                control={control}
                                                render={({ field: { onChange, value, ref } }) => {
                                                    return (
                                                        <Quantity
                                                            onChange={onChange}
                                                            value={value}
                                                            name="discount"
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <p className="flex items-center ml-1">
                                        {watch("voucher_type") == 0 ? "" : "%"}
                                    </p>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            {t("MGP_VOUCHERS_TABLE_COLUMN_CHANNEL")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="channel_id"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="channel_id"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {dataChannel.map((item, index) => (
                                                            <option value={item.Id} key={index}>
                                                                {item.ChannelName}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            {t("MGP_VOUCHERS_LABEL_VOUCHER_FACE_VALUE")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="wrapper-input-des">
                                            <input
                                                className="p-2 w-full"
                                                type="number"
                                                {...register("face_value")}
                                            />
                                            <span>{process.env.REACT_APP_CURRENCY || "$"}</span>
                                        </div>
                                        {errors.amount && (
                                            <span className="text-error">
                                                This field is required
                                            </span>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex">
                                        <label
                                            className="text-semibold label-input input-require"
                                            style={{ width: "100%" }}
                                        >
                                            {t("MGP_VOUCHERS_LABEL_VOUCHER_TEMPLATE_BANNER")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ImageUploading
                                            value={images}
                                            onChange={onChange}
                                            maxNumber={maxNumber}
                                            dataURLKey="data_url"
                                        >
                                            {({
                                                imageList,
                                                onImageUpload,
                                                onImageRemoveAll,
                                                onImageUpdate,
                                                onImageRemove,
                                                isDragging,
                                                dragProps,
                                            }) => (
                                                // write your building UI
                                                <div className="upload__image-wrapper">
                                                    {images && images.length == 0 ? (
                                                        <div className="wrapper-image-upload">
                                                            <button
                                                                style={
                                                                    isDragging
                                                                        ? { color: "red" }
                                                                        : undefined
                                                                }
                                                                className="btn-uploadimage"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    onImageUpload();
                                                                }}
                                                                {...dragProps}
                                                            >
                                                                <ImageIcon />
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {imageList && imageList.length > 0 ? (
                                                        <div className="wrapper-image-upload">
                                                            {imageList.map((image, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="image-item w-full h-full"
                                                                >
                                                                    <img
                                                                        src={image["data_url"]}
                                                                        alt=""
                                                                        width="100"
                                                                        className="w-full h-full object-contain"
                                                                    />
                                                                    <div className="image-item__btn-wrapper">
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                onImageRemove(index)
                                                                            }
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            )}
                                        </ImageUploading>
                                        <div className="form-group my-2 flex flex-center">
                                            <InfoIcon className="icon-info" />
                                            <label className="label-input w-full color-blue">
                                                {t(
                                                    "MGP_VOUCHERS_LABEL_VOUCHER_TEMPLATE_BANNER_INFO"
                                                )}
                                                :
                                            </label>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            {t("MGP_VOUCHERS_TABLE_COLUMN_AVAILABLE_STOCK")}:{" "}
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="p-2 w-full"
                                            {...register("available_stock")}
                                            disabled={availableStockNoLimit}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="form-group  h-full">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={availableStockNoLimit}
                                                        onChange={handleSetAvailableStockNoLimit}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="No limit"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex">
                                        <label className="text-semibold label-input mt-2">
                                            {t("MGP_VOUCHERS_LABEL_VOUCHER_VALIDITY_PERIOD")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name="validity_period_type"
                                            control={control}
                                            defaultValue={true}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControl style={{ width: "100%" }}>
                                                    <NativeSelect
                                                        value={value}
                                                        onChange={onChange}
                                                        name="jobTitle_id"
                                                        inputProps={{ "aria-label": "province" }}
                                                        variant="outlined"
                                                        className="custom-select"
                                                    >
                                                        {validityPeriod.map((item, index) => (
                                                            <option value={item.value} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </FormControl>
                                            )}
                                        />
                                        {watch("validity_period_type") == 0 ? (
                                            <div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            Start from:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="start_from"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after reception
                                                    </label>
                                                </div>
                                                <div className="mt-4 flex items-center">
                                                    <Grid item xs={6}>
                                                        <label className="mr-4 whitespace-nowrap ">
                                                            End to:
                                                        </label>
                                                    </Grid>
                                                    <Controller
                                                        name="end_to"
                                                        control={control}
                                                        render={({
                                                            field: { onChange, value, ref },
                                                        }) => {
                                                            return (
                                                                <Quantity
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="start_from"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    <label className="ml-4 whitespace-nowrap ">
                                                        day(s) after effective
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-4">
                                                <DaterangePicker onChange={handleChangeDaterange} />
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={10}>
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex">
                                        <label className="text-semibold label-input mt-2">
                                            {t("MGP_VOUCHERS_LABEL_VOUCHER_REDEMPTION_CONDITIONS")}:
                                        </label>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className="form-group mb-4 flex items-center">
                                            <div className="form-group h-full">
                                                <Controller
                                                    name="minimum_purchase"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, ref },
                                                    }) => {
                                                        return (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onChange={onChange}
                                                                        name="checkedB"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={t(
                                                                    "MGP_VOUCHERS_LABEL_VOUCHER_MINIMUM_PURCHASE"
                                                                )}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="wrapper-input-des">
                                                <input
                                                    className="p-2 w-full"
                                                    type="number"
                                                    {...register("redemption_validity")}
                                                    disabled={!watch("minimum_purchase")}
                                                />
                                                <span>{process.env.REACT_APP_CURRENCY || "$"}</span>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4 flex items-center">
                                            <div className="form-group  h-full">
                                                <Controller
                                                    name="minimum_number"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, ref },
                                                    }) => {
                                                        return (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onChange={onChange}
                                                                        name="checkedB"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={t(
                                                                    "MGP_VOUCHERS_LABEL_VOUCHER_MINIMUM_NUMBER"
                                                                )}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="wrapper-input-des">
                                                <input
                                                    className="p-2 w-full"
                                                    type="number"
                                                    {...register("minimum_item_purchase")}
                                                    disabled={!watch("minimum_number")}
                                                />
                                                <span>time(s)</span>
                                            </div>
                                        </div>

                                        <div className="form-group mb-4 flex items-center">
                                            <div className="form-group  h-full">
                                                <Controller
                                                    name="minimum_timming"
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, ref },
                                                    }) => {
                                                        return (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onChange={onChange}
                                                                        name="checkedB"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={t(
                                                                    "MGP_VOUCHERS_LABEL_VOUCHER_TIMING"
                                                                )}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="wrapper-time-range-picker">
                                                <Controller
                                                    name={"redemption_validity_begin"}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="time-from"
                                                            type="time"
                                                            onChange={onChange}
                                                            disabled={!watch("minimum_timming")}
                                                            value={value}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                step: 300, // 5 min
                                                            }}
                                                        />
                                                    )}
                                                />

                                                <span>-</span>
                                                <Controller
                                                    name={"redemption_validity_end"}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="time-to"
                                                            type="time"
                                                            disabled={!watch("minimum_timming")}
                                                            onChange={onChange}
                                                            value={value}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                step: 300, // 5 min
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <IconButton
                                                    aria-label="delete"
                                                    disabled={watch("minimum_timming")}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setValue("redemption_validity_begin", "");
                                                        setValue("redemption_validity_end", "");
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <div className="form-group mb-4 flex items-center">
                                                <div className="form-group h-full">
                                                    <Controller
                                                        control={control}
                                                        name="specific_brand"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name,
                                                                ref,
                                                            },
                                                            fieldState: {
                                                                invalid,
                                                                isTouched,
                                                                isDirty,
                                                                error,
                                                            },
                                                            formState,
                                                        }) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onBlur={onBlur}
                                                                        onChange={onChange}
                                                                        checked={value}
                                                                        inputRef={ref}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Applicable to specific brand(s): "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <BrandModal disabled={!watch("specific_brand")} />
                                            </div>
                                            <div className="form-group my-2">
                                                <BrandTable />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group mb-4 flex items-center">
                                                <div className="form-group  h-full">
                                                    <Controller
                                                        control={control}
                                                        name="specific_category"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name,
                                                                ref,
                                                            },
                                                            fieldState: {
                                                                invalid,
                                                                isTouched,
                                                                isDirty,
                                                                error,
                                                            },
                                                            formState,
                                                        }) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onBlur={onBlur}
                                                                        onChange={onChange}
                                                                        checked={value}
                                                                        inputRef={ref}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Applicable to specific category: "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <CategoryModal
                                                    disabled={!watch("specific_category")}
                                                />
                                            </div>
                                            <div className="form-group my-2">
                                                <CategoryTable />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group mb-4 flex items-center">
                                                <div className="form-group  h-full">
                                                    <Controller
                                                        control={control}
                                                        name="specific_sub_category"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name,
                                                                ref,
                                                            },
                                                            fieldState: {
                                                                invalid,
                                                                isTouched,
                                                                isDirty,
                                                                error,
                                                            },
                                                            formState,
                                                        }) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onBlur={onBlur}
                                                                        onChange={onChange}
                                                                        checked={value}
                                                                        inputRef={ref}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Applicable to specific sub-category: "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <SubCategoryModal
                                                    disabled={!watch("specific_sub_category")}
                                                />
                                            </div>
                                            <div className="form-group my-2">
                                                <SubCategoryTable />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group mb-4 flex items-center">
                                                <div className="form-group h-full">
                                                    <Controller
                                                        control={control}
                                                        name="specific_product"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name,
                                                                ref,
                                                            },
                                                            fieldState: {
                                                                invalid,
                                                                isTouched,
                                                                isDirty,
                                                                error,
                                                            },
                                                            formState,
                                                        }) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onBlur={onBlur}
                                                                        onChange={onChange}
                                                                        checked={value}
                                                                        inputRef={ref}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Applicable to specific product(s): "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <ProductModal
                                                    disabled={!watch("specific_product")}
                                                />
                                            </div>
                                            <div className="form-group my-2">
                                                <ProductTable />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group mb-4 flex items-center">
                                                <div className="form-group  h-full">
                                                    <Controller
                                                        control={control}
                                                        name="specific_store"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                                name,
                                                                ref,
                                                            },
                                                            fieldState: {
                                                                invalid,
                                                                isTouched,
                                                                isDirty,
                                                                error,
                                                            },
                                                            formState,
                                                        }) => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value}
                                                                        onBlur={onBlur}
                                                                        onChange={onChange}
                                                                        checked={value}
                                                                        inputRef={ref}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Applicable to specific store(s): "
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <StoreModal disabled={!watch("specific_store")} />
                                            </div>
                                            <div className="form-group my-2">
                                                <StoreTable />
                                            </div>
                                        </div> */}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={9}>
                            <div className="form-group my-2 mt-4">
                                <label className="text-semibold label-input">
                                    {t("MGP_VOUCHERS_LABEL_VOUCHER_TERMS")}:
                                </label>
                                <textarea
                                    id="w3review"
                                    name="w3review"
                                    rows="4"
                                    cols="50"
                                    {...register("term_and_conditions")}
                                ></textarea>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="pb-4">
                        <Grid item xs={3}>
                            <button
                                // type="submit"
                                className="btn-primary-filled w-full mt-2 text-center"
                                disabled={loading}
                                onClick={directBack}
                            >
                                {t("MGP_COMMON_LABEL_CANCEL")}
                            </button>
                        </Grid>
                        <Grid item xs={3}>
                            <input
                                type="submit"
                                className="btn-primary-filled w-full mt-2"
                                disabled={loading}
                                value={t("MGP_COMMON_LABEL_SAVE")}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    style={{ position: "relative", top: 16 }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        </div>
    );
}

export default Add;
