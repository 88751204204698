import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getPRCategoryIdService = (id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_CATEGORY}/${id}`;
    return api.get(url);
};
export const addPRCategoryService = (model) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_CATEGORY}`;
    return api.post(url, model);
};
export const deletePRCategoryService = (model, id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_CATEGORY}/${id}/delete`;
    return api.post(url, model);
};