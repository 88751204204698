import React, { useEffect, useState } from "react";
import {
    CircularProgress,
    Button,
    Grid,
    Modal,
    Backdrop,
    Fade,
    IconButton,
} from "@material-ui/core";
import * as commoneService from "../../../services/common";
import * as voucherService from "../../../services/voucher";
import CloseIcon from "@material-ui/icons/Close";
import MUIDataTable from "mui-datatables";
import SearchIcon from "@material-ui/icons/Search";
import { useForm } from "react-hook-form";
import actionCoupon from "../../../redux/Coupon/actions";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

const VoucherCategoryModal = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [open, setOpen] = useState(false);
    const [rowSelected, setRowSelected] = useState([]);
    const dataStoreSelected = useSelector((state) => state.couponReducer.coupon);
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, setValue, reset } = useForm({
        defaultValues: {},
    });
    const [isEdit, setIsEdit] = useState();
    const [rowEditId, setRowEditId] = useState(null);
    const [state, setState] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
        sortOrder: {},
        lastPage: 1,
        search: "",
        data: [],
        selectedRow: [],
        columns: [
            {
                name: "Id",
                label: "id",
                options: {
                    display: "excluded",
                },
            },
            {
                name: "VoucherName",
                label: "Voucher category",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value || "-"}</span>;
                    },
                },
            },
            {
                name: "operation",
                label: "Operation",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "auto", marginRight: 16 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // history.push(`/campaign/coupon/${tableMeta.rowData[0]}`);
                                        setIsEdit(true);
                                        setRowEditId(tableMeta.rowData[0]);
                                        setValue("category_name", tableMeta.rowData[1]);
                                    }}
                                >
                                    Update
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    style={{ width: "auto" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteCategory(tableMeta.rowData[0]);
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        );
                    },
                },
            },
        ],
        isLoading: false,
    });

    const deleteCategory = (id) => {
        voucherService.deleteVoucherCategoryService(id).then((res) =>
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            })
        );
    };

    const [search, setSearch] = useState("");

    const handleOpen = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
    };

    const handleClose = (e) => {
        e.preventDefault();
        props.handleGetAllVoucherCategory();
        setOpen(false);
    };

    useEffect(() => {
        getData(0);
    }, []);

    const getData = async (page) => {
        setState({ ...state, isLoading: true, page: page });
        const res = await xhrRequest(0);
        const dataFiltered = dataStoreSelected.map((item) =>
            res.data.data.data.findIndex((x) => x.Id === item.Id)
        );
        setState({
            ...state,
            data: res?.data?.data?.data,
            isLoading: false,
            count: res.data?.data.total,
            lastPage: res.data.data.last_page,
            selectedRow: dataFiltered,
            page: 0,
        });
    };

    // mock async function
    const xhrRequest = (page, sortOrder = {}, numberOfRows, search) => {
        let model = {
            page: page + 1,
            take: numberOfRows || state.rowsPerPage,
        };

        if (sortOrder) {
            model.order_by = sortOrder.column;
            model.order = sortOrder.order == "desc" ? 1 : 0;
        } else {
            if (state.sortOrder.column) {
                model.order_by = state.sortOrder.column;
                model.order = state.sortOrder.order == "desc" ? 1 : 0;
            }
        }

        if (search) {
            model.keyword = search;
        }
        // setState({ ...state, page: page + 1 });
        return voucherService.getVoucherCategoryService(model);
    };

    const changePage = (page, sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(page, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: search,
                lastPage: res.data.data.last_page,
                // count: res.total,
            });
        });
    };

    const changeRowsPerPage = (sortOrder, numberOfRows, search) => {
        setState({ ...state, isLoading: true });
        xhrRequest(0, sortOrder, numberOfRows, search).then((res) => {
            setState({
                ...state,
                isLoading: false,
                sortOrder,
                rowsPerPage: numberOfRows || state.rowsPerPage,
                data: res.data.data.data,
                search: state.search,
                lastPage: res.data.data.last_page,
                count: res.data.data.total,
            });
        });
    };

    const sort = (column, order) => {
        let temp = {};
        temp.column = column;
        temp.order = order;

        xhrRequest(state.page, temp, state.rowsPerPage, state.search).then((res) => {
            setState({
                ...state,
                data: res.data.data.data,
                sortOrder: {
                    column: column,
                    order: order,
                },
            });
        });
    };

    const { data, page, count, isLoading, rowsPerPage, sortOrder, lastPage, selectedRow } = state;
    const handleRowSelected = (currentRowsSelected, allRowsSelected) => {
        const filteredData = allRowsSelected.map((item) => data[item.dataIndex]);
        setRowSelected(filteredData);
        const arrIndex = allRowsSelected.map((item) => item.dataIndex);
        setState({ ...state, selectedRow: arrIndex });
    };

    const options = {
        filter: true,
        filterType: "dropdown",
        serverSide: true,
        count: count,
        search: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [],
        selectableRows: "none",
        rowsSelected: selectedRow,
        customToolbarSelect: () => {},

        onColumnSortChange: (changedColumn, direction) => {
            let order = "desc";
            if (direction === "asc") {
                order = "asc";
            }

            sort(changedColumn, order);
        },
        // onRowSelectionChange: handleRowSelected,
        onChangeRowsPerPage: (numberOfRows) => changeRowsPerPage(state.sortOrder, numberOfRows, ""),
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case "changePage":
                    changePage(tableState.page, tableState.sortOrder, state.rowsPerPage, "");
                    break;
                // case "sort":
                //     sort(tableState.page, tableState.sortOrder);
                //     break;
                default:
                // console.log("action not handled.");
            }
        },
    };

    const onSubmit = (data) => {
        console.log(data, "data form");
        setLoadingAdd(true);
        if (isEdit) {
            voucherService
                .updateVoucherCategoryService(data, rowEditId)
                .then((res) => {
                    if (res.data.success) {
                        getData(0);
                    }
                    setRowEditId(null);
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    setLoadingAdd(false);
                    setIsEdit(false);
                });
        } else {
            voucherService
                .addVoucherCategoryService(data)
                .then((res) => {
                    if (res.data.success) {
                        getData(0);
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => setLoadingAdd(false));
        }
    };

    const handleSave = (e) => {
        dispatch(actionCoupon.setDataStore(rowSelected));
        handleClose(e);
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                // startIcon={<CloseIcon />}
                onClick={handleOpen}
                style={{ marginLeft: 16 }}
                disabled={props.disabled || false}
            >
                {t("MGP_COMMON_LABEL_ADD")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex items-center justify-center"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="modal-custom" style={{ width: "54%" }}>
                        <div className="modal-head flex justify-between items-center">
                            <h2 className="text-xl font-bold">Add new category</h2>
                            <button onClick={handleClose}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="modal-content">
                            <div
                                className="wrapper-table w-full"
                                style={{ boxShadow: "none", padding: 8 }}
                            >
                                <form onSubmit={handleSubmit(onSubmit)} className="w-full mb-2">
                                    <div className="flex items-center w-6/12">
                                        <input
                                            className="p-2 w-full mr-4"
                                            style={{ height: 42 }}
                                            {...register("category_name", {
                                                required: true,
                                            })}
                                            placeholder="Enter voucher category"
                                        />
                                        <input
                                            type="submit"
                                            className={`btn-primary-filled w-6/12 ${
                                                loadingAdd ? "opacity-80" : ""
                                            }`}
                                            disabled={loadingAdd}
                                            value={isEdit ? "Update" : "Add"}
                                        />
                                        {isEdit && (
                                            <IconButton
                                                onClick={() => {
                                                    setIsEdit(false);
                                                    setRowEditId(null);
                                                    reset();
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                        {loadingAdd && (
                                            <CircularProgress
                                                size={24}
                                                style={{
                                                    position: "relative",
                                                    top: 0,
                                                    right: "-16px",
                                                }}
                                            />
                                        )}
                                    </div>
                                </form>
                                {data ? (
                                    <MUIDataTable
                                        data={data}
                                        columns={state.columns}
                                        options={options}
                                    />
                                ) : (
                                    "Loading"
                                )}
                            </div>
                            <div className="flex justify-end w-full">
                                <div className="w-6/12 flex mt-4 justify-end">
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            style={{ position: "relative", top: 16, right: 18 }}
                                        />
                                    )}
                                    <button
                                        // type="submit"
                                        className="btn-primary-filled mt-2 text-center w-5/12"
                                        disabled={loading}
                                        onClick={handleClose}
                                    >
                                        {" "}
                                        Cancel
                                    </button>
                                    {/* 
                                    <button
                                        className="btn-primary-filled mt-2 ml-3 w-5/12"
                                        disabled={loading}
                                        onClick={handleSave}
                                    >
                                        Save
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default VoucherCategoryModal;
