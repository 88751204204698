import React, { Component, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import RouterBreadcrumbs from "../../components/RouterBreadcrumbs";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Grid, Typography } from "@material-ui/core";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CouponTable from "./Table";
import GridCoupon from "./Grid";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";

const CouponCampaign = () => {
    const [isGridView, setIsGridView] = useState(false);
    const [total, setTotal] = useState();
    const childRef = useRef();

    const handleSetTotal = (totalNums) => {
        setTotal(totalNums);
    };

    const updateView = () => {
        setIsGridView(!isGridView);
    };

    const handleDelSelectedRow = () => {
        childRef.current.deleteMultiCoupon();
    };

    return (
        <div className="coupon-page">
            <div className="header-content py-2">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" to="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Coupon</Typography>
                    </Breadcrumbs>
                </div>
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-semibold uppercase">Coupon</h1>
                        <p>Total {total} coupon</p>
                    </div>
                    <div className="flex">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={handleDelSelectedRow}
                        >
                            Delete
                        </Button>
                        <Link to="/campaign/coupon/add" className="btn-primary-filled ml-3 mr-3">
                            + Add coupon
                        </Link>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={updateView}
                        >
                            <ViewCompactIcon />
                        </Button>
                    </div>
                </div>
            </div>
            {isGridView ? (
                <div className="wrapper-paper">
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ padding: 8 }}>
                            <GridCoupon ref={childRef} />
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <div className="wrapper-paper" style={{ padding: 0, overflow: "hidden" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ padding: 8 }}>
                            <CouponTable handleSetTotal={handleSetTotal} ref={childRef} />
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default CouponCampaign;
