const actions = {
    TOGGLE_SIDEBAR_LEFT: "TOGGLE_SIDEBAR_LEFT",
    TOGGLE_LOADING: "TOGGLE_LOADING",
    STORE_NAME: "STORE_NAME",

    toggleSidebarLeft: (payload) => ({
        type: actions.TOGGLE_SIDEBAR_LEFT,
        payload,
    }),

    toggleLoading: (payload) => ({
        type: actions.TOGGLE_LOADING,
        payload,
    }),

    storeName: (payload) => ({
        type: actions.STORE_NAME,
        payload,
    }),
};
export default actions;
