import React, { Component, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import RouterBreadcrumbs from "../../components/RouterBreadcrumbs";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Grid, Typography } from "@material-ui/core";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import DraftTable from "./Table/Draft";
import PausedTable from "./Table/Paused";
import OngoingTable from "./Table/Ongoing";
import PastTable from "./Table/Past";

const RecurringCampaign = () => {
    return (
        <div className="spot-page">
            <div className="header-content py-2">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" to="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Recurring campaign</Typography>
                    </Breadcrumbs>
                </div>
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-semibold uppercase">Recurring campaign</h1>
                        <p>
                            Highly targeted recurring campaigns with a variety of rewards used to
                            repeat purchase
                        </p>
                    </div>
                    <div className="flex">
                        {/* <AddModal /> */}
                        <Link to="/campaign/recurring/add" className="btn-primary-filled ml-3 mr-3">
                            + Add recurring campaign
                        </Link>
                    </div>
                </div>
            </div>
            <div className="wrapper-paper" style={{ padding: 0, overflow: "hidden" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ padding: 8 }}>
                        <DraftTable />
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper mt-8" style={{ padding: 0, overflow: "hidden" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ padding: 8 }}>
                        <OngoingTable />
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper mt-8" style={{ padding: 0, overflow: "hidden" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ padding: 8 }}>
                        <PausedTable />
                    </Grid>
                </Grid>
            </div>
            <div className="wrapper-paper mt-8" style={{ padding: 0, overflow: "hidden" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ padding: 8 }}>
                        <PastTable />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default RecurringCampaign;
