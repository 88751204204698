import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getPRProductIdService = (id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_PRODUCT}/${id}`;
    return api.get(url);
};
export const addPRProductService = (model) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_PRODUCT}`;
    return api.post(url, model);
};
export const deletePRProductIdService = (model, id) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_PRODUCT}/${id}/delete`;
    return api.post(url, model);
};