import React from "react";

function HeaderPage(props) {
    return (
        <div className={`header-page ${props?.extraClass ? props.extraClass : ""}`}>
            {props.children}
        </div>
    );
}

export default HeaderPage;
