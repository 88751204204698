import { ApiRouters } from "../utils/apiRouters";
import { api } from "../utils/axios.configs";

export const getPointRule = () => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE}`;
    return api.get(url);
};

export const getPointRuleCategory = () => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_CATEGORY}`;
    return api.get(url);
};

export const getTierRule = () => {
    var url = `${ApiRouters.LOYALTY_TIER_RULE}`;
    return api.get(url);
};

export const addPointRuleCategory = (model) => {
    var url = `${ApiRouters.LOYALTY_POINT_RULE_CATEGORY}`;
    return api.post(url, model);
};

export const addPointRule = (model) => {
    var url = `${ApiRouters.POINT_RULE}`;
    return api.post(url, model);
};

export const getLoyatyPointRule = (model) => {
    var url = `${ApiRouters.POINT_RULE}`;
    return api.get(url, { params: model });
};

export const getDetailLoyatyPointRule = (id) => {
    var url = `${ApiRouters.POINT_RULE}/${id}`;
    return api.get(url);
};