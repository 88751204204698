import React, { Component, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import RouterBreadcrumbs from "../../../../components/RouterBreadcrumbs";
import {
    Avatar,
    Button,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress,
    NativeSelect,
    FormHelperText,
    Breadcrumbs,
    Typography,
    Link,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import { useForm, Controller } from "react-hook-form";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import AccumulateTable from "./Accumulate";
import AccumulateProduct from "./Accumulate/product";
import AccumulateStore from "./Accumulate/store";
import AccumulateBrand from "./Accumulate/brand";
import AccumulateChannel from "./Accumulate/channel";;
import commonAction from "../../../../redux/Common/actions";
import * as productCategoryService from "../../../../services/productCategory";
import {toast} from "react-toastify";
import * as loyaltyService from "../../../../services/loyalty";

function Detail(props) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            code: "",
            store_id: 1,
            channel_id: 1,
            phone_number: "",
            gender: 1,
            dob: null,
            birth_year: "",
            birth_month: "",
            birth_day: "",
        },
    });
    const [infoMember, setInfoMember] = useState({});
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [data, setData] = useState()
    const { id_url } = useParams();
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        loyaltyService
            .getDetailLoyatyPointRule(id_url)
            .then((res) => {
                if (res.data.data) {
                    const resp = res.data.data;
                    setData(res.data.data);
                    setValue("rule_name", resp?.RuleName || "");
                    setValue("earn_spend_one_sgd_get", resp?.EarningRate || "");
                    setValue("redemption_spend_one_sgd_get", resp?.RedemptionRate || "");
                    setValue("currency", resp?.currency || "VND");
                    setValue("points", resp?.RuleName || "Point");

                }
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again!");
            })
            .finally(() => dispatch(commonAction.toggleLoading(false)));
    };
    return (
        <div className="member-page member-detail">
            <div className="header-content py-2 header-member-detail">
                <div className="mb-2">
                    <Breadcrumbs aria-label="breadcrumb" className="mb-4 mt-2">
                        <Link color="inherit" href="/#">
                            {t("MGP_SIDEBAR_LABEL_HOME")}
                        </Link>
                        <Link color="inherit" href="/#/loyalty/point-rule">
                            Custom Rule
                        </Link>
                        <Typography color="textPrimary">
                            Detail Rule
                        </Typography>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="h-full flex flex-col justify-center">
                            <h1 className="text-2xl font-semibold uppercase mb-1">
                                Detail Rule
                            </h1>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="loyalty-point-rule-page">
            <div className="point-rule-tabs wrapper-paper">
                <div className="point-rule-settings">
                    <form spacing={2}>
                        <Grid item xs={12} className="setting-item">
                            <div className="form-group my-2 mt-4">
                                <Grid container spacing={2}>
                                    <Grid item xs={3} className="flex items-center">
                                        <label className="text-semibold label-input input-require">
                                            Rule Name:
                                        </label>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {data?.RuleName || "-"}
                                    </Grid>
                                </Grid>
                            </div>

                        </Grid>

                        <Grid container spacing={2} className="setting-item">
                            <Grid item xs={3}>
                                <div className="form-group my-2">
                                    <label className="label-input w-full input-require">
                                        {t("MGP_POINT_RULE_DETAIL_POINT_EARN_RULE")}:
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                                {/* Decimal places */}
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <div className="form-group my-2">
                                            <label className="label-input w-full">
                                                {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PLACES")}:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl style={{ width: "100%" }}>
                                            <NativeSelect
                                                value={0}
                                                name="earn_decimal_places"
                                                variant="outlined"
                                                className="custom-select"
                                            >
                                                <option value={0}>No decimal</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* Decimal precision method */}
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <div className="form-group my-2">
                                            <label className="label-input w-full">
                                                {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PRECISION_METHOD")}:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl style={{ width: "100%" }}>
                                            <NativeSelect
                                                value={0}
                                                name="earn_decimal_precision_method"
                                                variant="outlined"
                                                className="custom-select"
                                            >
                                                <option value={0}>Round down</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* Spend 1 SGD get */}
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <div className="form-group my-2">
                                            <label className="label-input w-full">
                                                {t("MGP_POINT_RULE_TABLE_COLUMN_SPEND")}
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <input
                                            type="number"
                                            className="p-2 w-full"
                                            disabled={true}
                                            {...register("earn_spend_one_sgd_get", {
                                                required: true,
                                            })}
                                        />
                                    </Grid>
                                    <Grid item sm={7}>
                                        <div className="flex">
                                            <div className="addon" style={{ height: "46px", width: "30%" }}>
                                                {watch("currency")}
                                            </div>
                                            <div className="form-group my-2 mx-4">
                                                <label className="label-input w-full">
                                                    get [1] {watch("points")} ({watch("earning_rate")}{" "}
                                                    {watch("currency")} = 1 {watch("points") || ""})
                                                </label>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Point redemption rule */}
                        <Grid container spacing={2} className="setting-item">
                            <Grid item xs={3}>
                                <div className="form-group my-2">
                                    <label className="label-input w-full input-require">
                                        {t("MGP_POINT_RULE_DETAIL_POINT_REDEMPTION_RULE")}:
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                                {/* Decimal places */}
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <div className="form-group my-2">
                                            <label className="label-input w-full">
                                                {" "}
                                                {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PLACES")}:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl style={{ width: "100%" }}>
                                            <NativeSelect
                                                value={0}
                                                name="redemption_decimal_places"
                                                variant="outlined"
                                                className="custom-select"
                                            >
                                                <option value={0}>No decimal</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* Decimal precision method */}
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <div className="form-group my-2">
                                            <label className="label-input w-full">
                                                {t("MGP_POINT_RULE_TABLE_COLUMN_DECIMAL_PRECISION_METHOD")}:
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl style={{ width: "100%" }}>
                                            <NativeSelect
                                                value={0}
                                                name="redemption_decimal_precision_method"
                                                variant="outlined"
                                                className="custom-select"
                                            >
                                                <option value={0}>Round down</option>
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* Spend 1 SGD get */}
                                <Grid container spacing={2}>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={3}>
                                        <input
                                            type="number"
                                            className="p-2 w-full"
                                            disabled={true}
                                            {...register("redemption_spend_one_sgd_get", {
                                                required: true,
                                            })}
                                        />
                                    </Grid>
                                    <Grid item sm={7}>
                                        <div className="flex">
                                            <div className="addon" style={{ height: "46px", width: "30%" }}>
                                                {watch("points")} (s)
                                            </div>
                                            <div className="form-group my-2 mx-4">
                                                <label className="label-input w-full">
                                                    for {watch("point_redemption")}{" "}
                                                    {watch("currency") || ""} (1 {watch("points")} ={" "}
                                                    {watch("point_redemption")}{" "}
                                                    {watch("currency") || ""})
                                                </label>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>

                    <div className="info mt-3">
                        <AccumulateTable/>
                    </div>
                    <div className="info mt-3">
                        <AccumulateProduct
                        />
                    </div>
                    <div className="info mt-3">
                        <AccumulateStore />
                    </div>
                    <div className="info mt-3">
                        <AccumulateBrand />
                    </div>
                    <div className="info mt-3">
                        <AccumulateChannel />
                    </div>
                </div>
            </div>
        </div>
        </div>


    )
}
export default Detail;