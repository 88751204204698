import React, { useEffect } from "react";
import { getToken } from "../utils/actionUtil";
import Header from "../components/Header";
import SidebarRight from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import actionAuth from "../redux/Auth/actions";
import authActions from "../redux/Auth/actions";
// import Footer from './common/footer/footer.component';

const App = (props) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.commonReducer.loadingPage);

    useEffect(() => {
        dispatch(actionAuth.verifyToken());
    });

    return getToken() ? (
        <div className="wrapper flex">
            <SidebarRight />
            <div className="flex-grow" style={{ width: "23%" }}>
                <Header />
                <div className="p-3 app-content-wrapper">
                    {props.children}
                    {loading ? (
                        <div className="loading-page style-2">
                            <div className="loading-wheel"></div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    ) : (
        props.children
    );
};

export default App;
